<template>
  <div class="customerInformationInputBox" v-loading="loading">
    <headerTitle title="客户信息录入"></headerTitle>
    <div class="customerInformationInput">
      <!-- 搜索框 -->
      <customerInformationSearch :allocationList="allocationList" :resourceStatus="resourceStatus" :searchObj="searchObj" @searchClick="pageclick(1)" @addClick="addClick" @allocationClick="allocationClick" @importClick="importClick"></customerInformationSearch>
      <!-- 表格 -->
      <el-table :row-key="getRowKey" ref="multipleTable" @selection-change="handleSelectionChange" :data="dataList" stripe style="width: 100%">
        <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
        <el-table-column type="index" :index="indexMethod" label="序号" align="center"></el-table-column>
        <el-table-column prop="name" label="客户名称" align="center" width="100"></el-table-column>
        <el-table-column prop="" label="手机号" align="center" width="200">
          <template slot-scope="scope">
            <span v-if="scope.row && scope.row.phone">
              {{ $utils.mobileFilter(scope.row.phone) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="是否分配" align="center" width="100">
          <template slot-scope="scope">
            <span>
              {{ scope.row.teacherName == null || scope.row.teacherName == '' ? '否' : '是' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="teacherName" label="分配老师" align="center" width="100"></el-table-column>
        <el-table-column prop="allocTime" label="分配时间" align="center" width="100"></el-table-column>
        <el-table-column prop="customType" label="资源状态" align="center" width="100"></el-table-column>
        <el-table-column prop="sourceChannel" label="来源渠道" align="center" width="200"></el-table-column>
        <el-table-column prop="channelDetail" label="渠道明细" align="center" width="200"></el-table-column>
        <el-table-column prop="dataProvider" label="数据提供方" align="center" width="200"></el-table-column>
        <el-table-column fixed="right" prop="address" label="操作" align="center" width="300">
          <template slot-scope="scope">
            <el-button type="text" @click="allocationClick('onlyOne', scope.row)">分配老师</el-button>
            <el-button type="text" @click="addClick('detail', scope.row)">查看详情</el-button>
            <el-button type="text" @click="addClick('edit', scope.row)">编辑</el-button>
            <el-button type="text" @click="delClick(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <paging :pagesize="pageSize" :pageall="pageall" :pagenum="pagenum" @pageclick="pageclick"></paging>
      <!-- 分配老师弹窗 -->
      <el-dialog title="分配老师" :visible.sync="allotDialogVisible" :show-close="false" center width="30%">
        <div class="flexs-alignCenter">
          <div class="label">分配老师：</div>
          <div class="selectBox">
            <el-select value-key="id" multiple @change="teacherChange" v-model="allocTeacher" filterable remote :loading="teacherSearchLoading" :remote-method="remoteMethod" clearable placeholder="请输入老师姓名/手机号码">
              <el-option v-for="item in allocationTeacherList" :key="item.id" :label="item.name" :value="item"></el-option>
            </el-select>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="allotDialogVisible = false" round style="width: 46%; border-radius: 25px">取消</el-button>
          <el-button type="primary" @click="sureAllot" round style="width: 46%; border-radius: 25px">确定</el-button>
        </span>
      </el-dialog>
      <!-- 删除老师弹窗 -->
      <el-dialog title="删除" :visible.sync="delDialogVisible" :show-close="false" center width="410px">
        <span style="display: block; text-align: center">确认删除客户信息吗？</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="delDialogVisible = false" round style="width: 46%; border-radius: 25px">取消</el-button>
          <el-button type="primary" @click="sureDelete" round style="width: 46%; border-radius: 25px">确定</el-button>
        </span>
      </el-dialog>
      <!-- 批量导入弹窗 -->
      <el-dialog title="上传目录" :visible.sync="importVisible" width="500px" :close-on-click-modal="false" center>
        <div>
          <el-upload class="upload-demo" drag :multiple="false" :show-file-list="false" :http-request="toChannelRequest" accept=".xls, .xlsx">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text" v-if="!toImportName">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
            <div class="el-upload__text" v-else>{{ toImportName }}</div>
            <div class="el-upload__tip" slot="tip">
              <div style="margin-top: 8px; color: #ff4141; display: flex; justify-content: space-between; align-items: center">
                仅支持Excel文件
                <a style="color: #2797ff" href="https://file.chaogexitongban.net/others/app/8fdfec6b480ce771a9d15322b98d2d83.xlsx" download="客户信息录入导入模板">下载模板</a>
              </div>
            </div>
          </el-upload>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button round @click="toImportClose" style="width: 48%; border-radius: 25px">取 消</el-button>
          <el-button round style="width: 48%; border-radius: 25px" type="primary" :disabled="toImportLoading" @click="sureImport">
            {{ toImportLoading ? '请稍候...' : '确 定' }}
          </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import dictionary from '@/utils/dictionary.js';
import { getCustomerManagementList, editCustomerDetail, batchAlloc, uploadExcel, customDelete } from 'api/customerManagement.js';
import { getAccountManagementList } from 'api/systemManagement.js';
import paging from '../../../components/paging.vue';
import headerTitle from '../../../components/title.vue';
import customerInformationSearch from './component/customerInformationSearch.vue';
export default {
  name: 'CustomerInformation',
  components: {
    headerTitle,
    paging,
    customerInformationSearch
  },
  data() {
    return {
      searchObj: {
        customType: this.$route.query.customType || '',
        teacherId: this.$route.query.teacherId || '',
        createId: this.$route.query.createId || '',
        startDate: this.$route.query.startDate || '',
        endDate: this.$route.query.endDate || '',
      },
      pageall: 0,
      pagenum: 1,
      pageSize: 10,
      resourceStatus: dictionary.resourceStatus,
      allocationList: dictionary.allocationList,
      dataList: [],
      loading: false,
      delDialogVisible: false,
      allotDialogVisible: false,
      multipleSelection: [],
      operateObj: {},
      allocationTeacherList: [],
      // allocationTeacher: [],
      // allocationTeacherId: [],
      allocationType: null, // 分配方式：1为单独分配，2为批量分配
      teacherSearchLoading: false,
      importVisible: false,
      toImportLoading: false,
      formData: '',
      toImportName: '',
      allocTeacher: []
    };
  },
  watch: {},
  computed: {},
  mounted() {
    this.pageclick(1);
  },
  methods: {
    // 批量导入
    importClick() {
      this.importVisible = true;
    },
    // 关闭导入弹窗
    toImportClose() {
      this.toImportName = '';
      this.formData = '';
      this.importVisible = false;
    },
    // 导入
    toChannelRequest(files) {
      const file = files.file;
      this.toImportName = file.name;
      this.formData = new FormData();
      this.formData.set('file', file);
    },
    sureImport() {
      if (!this.formData) {
        this.$message({
          type: 'warning',
          message: '请选择导入文件'
        });
        return;
      }
      this.$set(this, 'toImportLoading', true);
      uploadExcel(this.formData)
        .then(res => {
          this.$set(this, 'toImportLoading', false);
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: '操作成功！'
            });
            this.pageclick(1);
            this.importVisible = false;
            this.toImportClose();
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(err => {
          console.log(err);
          this.$set(this, 'toImportLoading', false);
        });
    },
    // 分配
    sureAllot() {
      if (!this.allocTeacher.length) {
        this.$message({
          type: 'error',
          message: '请先选择老师！'
        });
        return false;
      }
      this.allotDialogVisible = false;
      if (this.allocationType == 1) {
        // 单独分配
        const data = {
          id: this.operateObj.id,
          name: this.operateObj.name,
          phone: this.operateObj.phone,
          wxNumber: this.operateObj.wxNumber,
          channelDetail: this.operateObj.channelDetail,
          sourceChannel: this.operateObj.sourceChannel,
          allocTeacher: this.allocTeacher
        };
        this.loading = true;
        editCustomerDetail(data).then(res => {
          this.loading = false;
          this.delDialogVisible = false;
          if (res.code == '2000') {
            this.$message({
              type: 'success',
              message: '分配成功！'
            });
            this.pageclick(1);
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        });
      } else {
        // 批量分配;
        const arrId = [];
        this.multipleSelection.forEach(item => {
          arrId.push(item.id);
        });
        const data = {
          customId: arrId,
          allocTeacher: this.allocTeacher
        };
        this.loading = true;
        batchAlloc(data).then(res => {
          this.loading = false;
          this.delDialogVisible = false;
          if (res.code == '2000') {
            this.$message({
              type: 'success',
              message: '分配成功！'
            });
            this.$refs.multipleTable.clearSelection();
            this.pageclick(1);
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        });
      }
    },
    allocationClick(type, item) {
      this.allocationTeacherList = [];
      this.allocTeacher = [];
      if (type == 'onlyOne') {
        this.operateObj = item;
        this.allocationType = 1;
      } else {
        this.allocationType = 2;
        if (!this.multipleSelection.length) {
          this.$message({
            type: 'error',
            message: '请先选中客户！'
          });
          return false;
        }
      }
      this.allotDialogVisible = true;
    },
    remoteMethod(query) {
      if (query !== '') {
        this.teacherSearchLoading = true;
        getAccountManagementList({ ifPage: false, roleId: 2, phone: query }).then(res => {
          this.teacherSearchLoading = false;
          if (res.code == '2000') {
            this.allocationTeacherList = res.data.list;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        });
      }
    },
    teacherChange(val) {
      // const arr = [];
      // val.forEach(item => {
      //   this.allocationTeacherList.forEach(item1 => {
      //     if (item == item1.id) {
      //       arr.push(item1.name);
      //     }
      //   });
      // });
      // this.allocationTeacher = arr;
    },
    // 删除
    sureDelete() {
      const data = {
        id: this.operateObj.id
      };
      this.loading = true;
      customDelete(data).then(res => {
        this.loading = false;
        this.delDialogVisible = false;
        if (res.code == '2000') {
          this.$message({
            type: 'success',
            message: '删除成功！'
          });
          this.pageclick(1);
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    delClick(item) {
      this.operateObj = item;
      this.delDialogVisible = true;
    },
    // 新增、编辑、详情
    addClick(type, item) {
      const dialogType = type ? type : 'add';
      const customId = item ? item.id + '' : '';
      let query = {};
      if (dialogType != 'add') {
        query = {
          type: dialogType,
          customId: customId
        };
      } else {
        query = {
          type: dialogType
        };
      }
      this.$router.push({
        path: '/customerInformationDetail',
        query: query
      });
    },

    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    pageclick(val) {
      this.pagenum = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      const data = {
        ifPage: true,
        pageSize: this.pageSize,
        pageNum: this.pagenum,
        ...this.searchObj
      };
      getCustomerManagementList(data).then(res => {
        this.loading = false;
        if (res.code == '2000') {
          this.pageall = res.data.total || 0;
          this.dataList = res.data.list || [];
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    indexMethod(index) {
      return index + 1 + (this.pagenum - 1) * this.pageSize;
    },
    getRowKey(row) {
      return row.id;
    }
  }
};
</script>

<style lang="stylus" scoped>
.customerInformationInput {
  padding: 20px;
  box-sizing: border-box;
  background: #ffffff;
}

>>> .el-table__header th, >>> .el-table__header tr {
  background: #EBF5FF !important;
  font-size: 14px !important;
  color: #202224 !important;
}

>>> .el-table__body th, >>> .el-table__body tr {
  background: #fff !important;
  font-size: 14px !important;
  color: #202224 !important;
}

>>> .el-table__body tr .el-table__cell {
  padding: 8px 0 !important;
}

>>> .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #F5FAFF !important;
}

>>>.el-dialog {
  border-radius: 16px;
}

>>>.el-upload, >>>.el-upload-dragger {
  width: 100%;
}
</style>