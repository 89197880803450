<template>
  <div class="CMSDataStatisticsBigBox">
    <el-form :inline="true" :model="searchObj" class="demo-form-inline">
      <el-form-item label="时间段">
        <el-date-picker
          v-model="searchObj.time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="getData"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <!-- 用户增长趋势 -->
    <div class="contentBigBox">
      <div class="contentTitleBox">用户增长趋势</div>
      <div class="contentBox">
        <div class="W100v H300" ref="chart"></div>
      </div>
    </div>
    <!-- 用户资源情况 -->
    <div class="contentBigBox">
      <div class="contentTitleBox">用户资源情况</div>
      <div class="contentBox">
        <!-- <div class="W32v H378" ref="chart"></div> -->
        <!-- <div class="W32v H378" ref="chart"></div> -->
        <div class="W32v H378" ref="chartBin3"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { getDataStatisticsData } from 'api/CMSDataStatistics.js';
import { formatDate } from '@utils/smallTools/index.js';
import { Chart } from '@antv/g2';
export default {
  name: 'CMSDataStatistics',
  data() {
    return {
      searchObj: {},
      loading: false,
      chart: '',
      chartBin3: '', // 饼图3
      list: [], // 用户增长趋势
      binList3: [] // 饼图3
    };
  },
  methods: {
    getData() {
      console.log(this.searchObj.time);
      if (!this.searchObj.time || !this.searchObj.time.length) {
        this.$set(this.searchObj, 'time', [
          formatDate(
            new Date().getTime() - 1000 * 60 * 60 * 24 * 7,
            'yyyy-MM-dd'
          ),
          formatDate(new Date(), 'yyyy-MM-dd')
        ]);
      }
      this.loading = true;
      getDataStatisticsData({
        startDate:
          this.searchObj.time && this.searchObj.time[0]
            ? this.searchObj.time[0]
            : '',
        endDate:
          this.searchObj.time && this.searchObj.time[1]
            ? this.searchObj.time[1]
            : ''
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 2000) {
            console.log(res.data);
            this.list = res.data.one;
            this.binList3 = res.data.two;
            this.$nextTick(() => {
              this.chartInit();
              this.chartBin3Init();
            });
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 用户增长趋势
    chartInit() {
      if (this.chart) {
        this.chart.changeData(this.list);
        return;
      }
      this.chart = new Chart({
        container: this.$refs.chart,
        autoFit: true
      });
      this.chart.data(this.list);
      this.chart
        .area()
        .encode('x', (d) => d.year)
        .encode('y', 'value')
        .encode('shape', 'area') // 'area', 'smooth', 'hvh', 'vh', 'hv'
        .style('opacity', 0.2)
        .axis('y', { title: false })
        .axis('x', { labelAutoHide: true, labelAutoRotate: false });
      this.chart
        .line()
        .encode('x', 'year')
        .encode('y', 'value')
        .encode('shape', 'line'); // 'line', 'smooth', 'vh', 'hv', 'hvh'

      this.chart.render();
    },
    // 饼图3
    chartBin3Init() {
      if (this.chartBin3) {
        this.chartBin3.changeData(this.binList3);
        return;
      }
      this.chartBin3 = new Chart({
        container: this.$refs.chartBin3,
        autoFit: true
      });

      this.chartBin3.coordinate({ type: 'theta', outerRadius: 0.8 });

      this.chartBin3
        .interval()
        .data(this.binList3)
        .transform({ type: 'stackY' })
        .encode('y', 'percent')
        .encode('color', 'item')
        .legend(false)
        .label({
          position: 'outside',
          text: (data) => `${data.item}: ${data.percent * 100}%`,
          transform: [
            {
              type: 'overlapDodgeY'
            }
          ]
        })
        .tooltip((data) => ({
          name: data.item,
          value: `${data.percent * 100}%`
        }));

      this.chartBin3.render();
    }
  },
  created() {
    this.$set(this.searchObj, 'time', [
      formatDate(new Date().getTime() - 1000 * 60 * 60 * 24 * 7, 'yyyy-MM-dd'),
      formatDate(new Date(), 'yyyy-MM-dd')
    ]);
    this.getData();
  }
};
</script>
<style lang="stylus" scoped>
.CMSDataStatisticsBigBox {
  padding: 24px;
  box-sizing: border-box;
  background: #ffffff;

  .contentBigBox {
    margin-bottom: 24px;
    box-sizing: border-box;

    .contentTitleBox {
      margin-bottom: 24px;
      font-weight: bold;
    }

    .contentBox {
      width: 100%;
      display: flex;
    }

    .W100v {
      width: 100%;
    }

    .W32v {
      width: 32%;
      margin-right: 1%;
    }

    .W32v:last-child {
      margin-right: 0;
    }

    .H300 {
      height: 300px;
    }

    .H378 {
      height: 378px;
    }
  }
}
</style>