<template>
  <div>
    <!-- header -->
    <div class="addschoolHeaderBox">
      <div class="addschoolHeader">新增学校排名</div>
      <div class="addschoolHeaderBottomBox">
        <el-button
          type="primary"
          size="small"
          style="background-color: #25c484; border: none"
          @click="submitVerify"
          :disabled="submitLoading"
        >
          {{ submitLoading ? '请稍候...' : '保存' }}
        </el-button>
        <el-button type="primary" size="small" plain @click="toBack">
          返回
        </el-button>
      </div>
    </div>
    <!--  -->
    <el-form :inline="true" :model="dataobj" class="demo-form-inline">
      <el-form-item label="平台名称">
        <el-select v-model="dataobj.rankPlatform" placeholder="请选择平台名称">
          <el-option
            :label="item.name"
            :value="item.code"
            v-for="(item, index) in platformNameList"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="年份">
        <el-select v-model="dataobj.rankYear" placeholder="请选择年份">
          <el-option
            :label="item"
            :value="item"
            v-for="item in yearList"
            :key="item"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <!-- list -->
    <el-table :data="dataobj.rankList" stripe style="width: 100%">
      <el-table-column type="index" label="序号" width="55" align="center">
      </el-table-column>
      <el-table-column prop="schoolId" label="学校名称" align="center">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.schoolId"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="
              (id) => {
                schoolChange(scope.row, id);
              }
            "
          >
            <el-option
              v-for="item in schoolList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="rankNo" label="排名" align="center">
        <template slot-scope="scope">
          <el-input
            type="number"
            v-model="scope.row.rankNo"
            placeholder="请输入排名"
            :min="1"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="area" label="国家" align="center">
        <template slot-scope="scope">
          {{ scope.row.area || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="specialtyNumber" label="收录专业数" align="center">
        <template slot-scope="scope">
          {{ scope.row.specialtyNumber || '-' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="70">
        <template slot-scope="scope">
          <i
            class="el-icon-remove-outline"
            style="color: #f56c6c"
            @click="deleteClick(scope.row, scope.$index)"
          ></i>
        </template>
      </el-table-column>
    </el-table>
    <div class="buttonBox">
      <el-button type="primary" size="small" plain @click="addData">
        新增一条
      </el-button>
    </div>
  </div>
</template>
<script>
import { getSchoolList } from 'api/schoolCollegeManagement.js';
import { submitSchoolRanking } from 'api/rankingManagement';
export default {
  name: 'Addschool',
  props: {
    yearList: Array,
    platformNameList: Array
  },
  data() {
    return {
      dataobj: { rankList: [], rankType: 0 },
      loading: false,
      schoolList: [],
      submitLoading: false
    };
  },
  methods: {
    toBack(isRefresh = false) {
      this.dataobj = { rankList: [], rankType: 0 };
      this.$emit('toBack', isRefresh);
    },
    // 搜索学校
    remoteMethod(name) {
      if (name) {
        this.loading = true;
        getSchoolList({
          name: name
        })
          .then((res) => {
            console.log(res);
            this.loading = false;
            if (res.code == 2000) {
              this.schoolList = res.data.list || [];
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.schoolList = [];
      }
    },
    // 新增一条数据
    addData() {
      this.dataobj.rankList.push({});
    },
    // 选中学校
    schoolChange(item, id) {
      for (let i = 0; i < this.schoolList.length; i++) {
        if (id == this.schoolList[i].id) {
          item.area = this.schoolList[i].area;
          item.schoolName = this.schoolList[i].name;
          item.specialtyNumber = this.schoolList[i].countMajor;
          break;
        }
      }
    },
    // 删除一条
    deleteClick(item, index) {
      this.$delete(this.dataobj.rankList, index);
    },
    // 校验数据并提交
    submitVerify() {
      if (!this.dataobj.rankPlatform) {
        this.$message({
          type: 'warning',
          message: '请选择平台'
        });
        return false;
      }
      if (!this.dataobj.rankYear) {
        this.$message({
          type: 'warning',
          message: '请选择年份'
        });
        return false;
      }
      let flag = true;
      let text = '';
      const list = this.dataobj.rankList;
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (!item.schoolId || !item.rankNo) {
          flag = false;
          text = `请将第${i + 1}条数据补充完整!!!`;
          break;
        }
      }
      if (flag) {
        this.submitClick();
      } else {
        this.$message({
          type: 'warning',
          message: text
        });
      }
    },
    // 提交数据
    submitClick() {
      this.submitLoading = true;
      submitSchoolRanking(this.dataobj)
        .then((res) => {
          this.submitLoading = false;
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.toBack(true);
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.submitLoading = false;
        });
    }
  }
};
</script>
<style lang="stylus" scoped>
.addschoolHeaderBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.buttonBox {
  display: flex;
  justify-content: flex-end;
  padding: 12px;
  box-sizing: border-box;
}
</style>