<template>
  <div id="poster" v-loading="loading">
    <el-form class="login-container">
      <div class="logn_l">
        <img src="../assets/logo.png" alt="" />
      </div>
      <div class="logn_r">
        <div class="button_list">
          <!-- <span :class="[step == 1 ? 'active' : '']" @click="changeStep(1)">验证码登录</span> -->
          <!-- <span :class="[step == 2 ? 'active' : '']" @click="changeStep(2)">密码登录</span> -->
          <p style="font-size: 24px; color: #333">密码登录</p>
        </div>
        <!-- <div class="login_info" v-if="step == 1">
          <el-form :model="userInformation" :rules="rules" ref="ruleForm">
            <el-form-item prop="username" class="login_input">
              <el-input type="text" style="width: 100%" v-model="userInformation.username" placeholder="手机号码" class="codeStyle">
                <i slot="prefix" class="icons"><img src="../assets/phone.png" alt="" /></i>
              </el-input>
            </el-form-item>
            <div class="VerCode">
              <el-form-item prop="password" class="login_input">
                <el-input style="width: 100%" v-model="userInformation.password" placeholder="验证码">
                  <i slot="prefix" class="icons"><img src="../assets/right.png" alt="" /></i>
                </el-input>
              </el-form-item>
              <el-button type="primary" class="btns" plain @click="getCode" :disabled="disabled">{{ text }}</el-button>
            </div>
          </el-form>
          <el-button type="primary" class="login_btn" @click="loginByTel">登录</el-button>
        </div> -->
        <div class="login_info" v-if="step == 2">
          <el-form :model="userInformations" :rules="rules1" ref="ruleForms">
            <el-form-item prop="username" class="login_input">
              <el-input
                type="text"
                style="width: 100%"
                v-model="userInformations.username"
                placeholder="手机号码"
              >
                <i slot="prefix" class="icons">
                  <img src="../assets/phone.png" alt="" />
                </i>
              </el-input>
            </el-form-item>
            <el-form-item prop="password" class="login_input">
              <el-input
                type="password"
                style="width: 100%; margin-top: 33px"
                v-model="userInformations.password"
                placeholder="密码"
              >
                <i slot="prefix" class="icons">
                  <img src="../assets/pass.png" alt="" />
                </i>
              </el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary" class="login_btn" @click="loginByPass">
            登录
          </el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import { login, getVerifyCode } from 'api/login.js';
import formValidation from '@/utils/validate.js';
import { setToken } from '@/utils/auth';
export default {
  data() {
    return {
      step: 2,
      text: '获取验证码',
      disabled: false,
      rules: {
        username: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          },
          {
            validator: formValidation.mobileRule,
            trigger: 'blur',
            message: '请输入正确手机号'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }
        ]
      },
      userInformation: {
        username: '',
        password: ''
      },
      rules1: {
        username: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          },
          {
            validator: formValidation.mobileRule,
            trigger: 'blur',
            message: '请输入正确手机号'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }
        ]
      },
      userInformations: {
        username: '',
        password: ''
      },
      loading: false
    };
  },
  created() {},
  methods: {
    // 密码登录
    loginByPass() {
      this.$refs.ruleForms.validate((valid) => {
        if (valid) {
          const params = {
            ...this.userInformations,
            loginType: 1
          };
          this.loading = true;
          login(params)
            .then((res) => {
              this.loading = false;
              if (res.code == 2000) {
                const patientMsg = res.data;
                setToken(patientMsg.token);
                sessionStorage.setItem(
                  'patientMsg',
                  JSON.stringify(patientMsg)
                );
                sessionStorage.setItem('token', patientMsg.token);
                // this.$router.push({
                //   path: '/'
                // });
                if (
                  patientMsg.roleName == '管理员' ||
                  patientMsg.roleName == '超级管理员'
                ) {
                  this.$router.push({
                    path: '/customerInformationInput'
                  });
                } else if (patientMsg.roleName == '销售') {
                  this.$router.push({
                    path: '/customerInformationInput'
                  });
                } else if (patientMsg.roleName == '客服') {
                  this.$router.push({
                    path: '/customerInformationInput'
                  });
                }
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                });
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    // 验证码登录
    loginByTel() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const params = {
            ...this.userInformation,
            loginType: 2
          };
          this.loading = true;
          login(params).then((res) => {
            this.loading = false;
            if (res.code == 2000) {
              console.log(res.data);
              const patientMsg = res.data;
              setToken(patientMsg.token);
              sessionStorage.setItem('patientMsg', JSON.stringify(patientMsg));
              sessionStorage.setItem('token', patientMsg.token);
              console.log(patientMsg);
              this.$router.push({
                path: '/headmapList'
              });
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          });
        }
      });
    },
    changeStep(step) {
      this.step = step;
      if (step == 1) {
        this.text = '获取验证码';
        this.disabled = false;
        this.$refs.ruleForms.resetFields();
      } else {
        this.$refs.ruleForm.resetFields();
      }
    },
    // 获取验证码
    getCode() {
      const reg2 = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg2.test(this.userInformation.username)) {
        this.$message({
          type: 'error',
          message: '手机号格式错误'
        });
        return false;
      }
      let num = 60;
      this.text = '重新获取' + '(' + num + 's)';
      this.disabled = true;
      const that = this;
      const timer = setInterval(() => {
        num--;
        that.text = '重新获取' + '(' + num + 's)';
        that.disabled = true;
        if (num <= 0) {
          that.text = '获取验证码';
          that.disabled = false;
          clearInterval(timer);
        }
      }, 1000);
      // 调获取验证码接口
      getVerifyCode({
        phone: this.userInformation.username
      }).then((res) => {
        if (res.code == '2000') {
          that.$message({
            type: 'success',
            message: '验证码发送成功'
          });
        } else {
          that.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    }
  }
};
</script>
  <style lang="stylus" scoped>
  #poster {
    background-image: url('../assets/banner2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    // background-size: cover;
    position: fixed;
  }

  body {
    margin: 0px;
  }

  .login-container {
    border-radius: 15px;
    background-clip: padding-box;
    // margin-top: 120px;
    width: 63%;
    box-sizing: border-box;
    // background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    overflow: hidden;
    transform: translate(-50%, -50%);
  }

  .login_title {
    margin: 0px auto 40px auto;
    text-align: center;
    color: #505458;
  }

  .logn_l {
    width: 46%;
    position: relative;
    height: 514px;
    float: left;
  }

  .logn_l>img {
    width: 170px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .logn_l::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.36) 0%, rgba(255, 255, 255, 0.11) 100%);
    backdrop-filter: blur(10px);
  }

  .logn_r {
    background-color: #fff;
    width: 54%;
    box-sizing: border-box;
    padding: 82px 57px 0;
    float: left;
    height: 514px;

    .button_list {
      span {
        width: 50%;
        float: left;
        height: 55px;
        background: #FFFFFF;
        box-sizing: border-box;
        border-radius: 12px 12px 12px 12px;
        opacity: 1;
        display: block;
        font-size: 20px;
        color: #A0A7B7;
        border: 1px solid #E2E8F2;
        text-align: center;
        line-height: 55px;
        cursor: pointer;
      }

      .active {
        border-color: #4A81D9;
        background-color: #4A81D9;
        color: #fff;
      }
    }

    .button_list::after {
      content: '';
      display: block;
      clear: both;
    }

    .login_info {
      margin-top: 44px;

      .icons {
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        left: 14px;
      }

      .icons img {
        display: block;
        width: 22px;
        height: 22px;
      }
    }
  }

  .VerCode {
    margin-top: 33px;
    display: flex;
    justify-content: space-between;
  }

  ::v-deep .el-input__inner {
    height: 55px !important;
    line-height: 55px !important;
    padding-left: 58px !important;
    background: #F2F6FE;
    font-size: 18px;
    border: none;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .btns {
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    border: 1px solid #4A81D9;
    background: #FFFFFF;
    color: #4A81D9;
  }

  .el-button--primary.is-plain {
    border: 1px solid #4A81D9;
    background: #FFFFFF;
    color: #4A81D9;
  }

  .el-button--primary.is-plain.is-disabled {
    border: 1px solid #4A81D9;
    background: #FFFFFF;
    color: #4A81D9;
  }

  .login_btn {
    width: 100%;
    height: 55px;
    background: #4A81D9;
    border-radius: 12px 12px 12px 12px;
    line-height: 55px;
    margin-top: 79px;
    padding: 0;
  }
</style>