<template>
  <div>
    <el-form :inline="true" :model="searchObj" class="demo-form-inline">
      <el-form-item label="学校名称">
        <el-input
          v-model="searchObj.name"
          placeholder="请输入学校名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="院校等级">
        <el-select v-model="searchObj.schoolType" placeholder="请选择院校等级">
          <el-option label="全部" :value="''"></el-option>
          <el-option
            :label="item.name"
            :value="item.code"
            v-for="(item, index) in levelList"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" round @click="pageclick(1)">
          查询
        </el-button>
        <el-button
          type="success"
          size="small"
          style="background-color: #25c484; border: none"
          @click="addButtonClick('add')"
          round
        >
          新增
        </el-button>
        <el-button
          type="primary"
          size="small"
          style="background-color: #25c484; border: none"
          @click="importShow = true"
          round
        >
          批量导入
        </el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" stripe style="width: 100%" v-loading="loading">
      <el-table-column type="index" label="序号" width="55" align="center">
      </el-table-column>
      <el-table-column prop="name" label="学校名称" align="center">
      </el-table-column>
      <el-table-column prop="schoolType" label="院校等级" align="center">
        <template slot-scope="scope">
          {{ getSchoolTypeName(scope.row.schoolType) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="addButtonClick('update', scope.row)">
            编辑
          </el-button>
          <el-button
            type="text"
            style="color: rgb(245, 108, 108)"
            @click="deleteClick(scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <paging
      :pageall="pageall"
      :pagenum="pagenum"
      @pageclick="pageclick"
    ></paging>
    <!--  -->
    <el-dialog
      :title="titleType == 'update' ? '编辑' : '新增'"
      :visible.sync="dialogVisible"
      :close-on-press-escape="false"
      :show-close="false"
      center
    >
      <el-form :inline="true" :model="dataobj" class="demo-form-inline">
        <el-form-item label="学校名称">
          <el-input
            v-model="dataobj.name"
            placeholder="请输入学校名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="院校等级">
          <el-select v-model="dataobj.schoolType" placeholder="请选择院校等级">
            <el-option
              :label="item.name"
              :value="item.code"
              v-for="(item, index) in levelList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="dialogButton">
        <el-button size="small" @click="dialogClose">返回</el-button>
        <el-button
          size="small"
          type="primary"
          @click="submitClick"
          :loading="submitLoading"
        >
          {{ submitLoading ? '请稍候...' : '提交' }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 导入 -->
    <comimport
      :show.sync="importShow"
      title="上传目录"
      templateName="大学导入模板"
      templateUrl="https://file.chaogexitongban.net/others/app/d034e25f7c5289b01b7d6dcbfb1f0ca2.xlsx"
      apiName="importUniversity"
      @confirm="importConfirm"
    ></comimport>
  </div>
</template>
<script>
import {
  getUniversityList,
  dicListByCode,
  getUniversityDetail,
  addUniversity,
  updateUniversity,
  deleteUniversity
} from 'api/index';
import paging from 'com/paging.vue';
import comimport from 'com/comimport/comimport.vue';
export default {
  name: 'UniversityManagement',
  data() {
    return {
      searchObj: {
        schoolType: ''
      },
      levelList: [],
      pagenum: 1,
      pageall: 0,
      loading: false,
      list: [],
      dialogVisible: false,
      titleType: '',
      dataobj: {},
      submitLoading: false,
      importShow: false
    };
  },
  methods: {
    // 点击分页
    pageclick(val) {
      this.pagenum = val;
      this.getList();
    },
    // 获取列表
    getList() {
      this.loading = true;
      getUniversityList({
        ifPage: true,
        pageNum: this.pagenum,
        pageSize: 10,
        ...this.searchObj
      })
        .then((res) => {
          this.loading = false;
          console.log(res);
          if (res.code == 2000) {
            this.list = res.data.list || [];
            this.pageall = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取字典
    getCodeList() {
      dicListByCode({
        code: 'LEVEL',
        status: 1
      })
        .then((res) => {
          if (res.code == '2000') {
            this.levelList = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {});
    },
    // 点击新增、编辑按钮
    addButtonClick(type, item) {
      this.titleType = type;
      switch (type) {
        case 'add':
          this.dialogVisible = true;
          break;
        case 'update':
          this.getDetails(item, () => {
            this.dialogVisible = true;
          });
          break;
      }
    },
    // 关闭弹窗
    dialogClose() {
      this.dialogVisible = false;
      this.titleType = '';
      this.dataobj = {};
    },
    // 获取详情
    getDetails(item, callback) {
      getUniversityDetail({
        id: item.id
      })
        .then((res) => {
          if (res.code == 2000) {
            this.dataobj = res.data;
            callback();
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {});
    },
    // 新增、编辑提交
    submitClick() {
      if (this.submitLoading) {
        return;
      }
      if (!this.dataobj.name) {
        this.$message({
          type: 'warning',
          message: '请输入学校名称!!!'
        });
        return;
      }
      if (!this.dataobj.schoolType) {
        this.$message({
          type: 'warning',
          message: '请选择院校等级!!!'
        });
        return;
      }
      let api = '';
      switch (this.titleType) {
        case 'add':
          api = addUniversity;
          break;
        case 'update':
          api = updateUniversity;
          break;
      }
      this.submitLoading = true;
      api(this.dataobj)
        .then((res) => {
          this.submitLoading = false;
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getList();
            this.dialogClose();
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.submitLoading = false;
        });
    },
    // 点击删除一条
    deleteClick(item) {
      this.$confirm(`是否确认删除 "${item.name}"?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteUniversity({ id: item.id })
            .then((res) => {
              if (res.code == 2000) {
                this.$message({
                  type: 'success',
                  message: '操作成功'
                });
                this.getList();
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                });
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    // 导入提交
    importConfirm(pro) {
      pro
        .then((res) => {
          console.log(res);
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: '导入成功'
            });
            this.importShow = false;
            this.getList();
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取院校等级名称
    getSchoolTypeName(code) {
      for (let i = 0; i < this.levelList.length; i++) {
        if (code == this.levelList[i].code) {
          return this.levelList[i].name;
        }
      }
    }
  },
  created() {
    this.getCodeList();
    this.getList();
  },
  components: {
    paging,
    comimport
  }
};
</script>
<style lang="stylus" scoped>
.dialogButton {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>