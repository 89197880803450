<template>
  <div class="schoolCollegeManagement">
    <div v-if="!pageType">
      <div class="searchBox">
        <el-form :inline="true" :model="searchObj" class="demo-form-inline">
          <el-form-item label="学校名称：">
            <el-input
              v-model="searchObj.name"
              placeholder="请输入学校名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="QS排名：">
            <el-input
              v-model="searchObj.qsRank"
              placeholder="请输入QS排名"
            ></el-input>
          </el-form-item>
          <el-form-item label="地区：">
            <el-input
              v-model="searchObj.area"
              placeholder="请输入地区"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="mini"
              round
              @click="pageclick(1)"
            >
              查询
            </el-button>
            <el-button
              type="primary"
              size="mini"
              round
              style="background-color: #25c484; border: none"
              @click="toDetails('', 'add')"
            >
              新增学校
            </el-button>
            <el-button
              type="primary"
              size="mini"
              style="background-color: #25c484; border: none"
              @click="importShow = true"
              round
            >
              批量导入
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="list" stripe style="width: 100%" v-loading="loading">
        <el-table-column type="index" label="序号" width="55" align="center">
        </el-table-column>
        <el-table-column prop="name" label="学校名称" align="center">
        </el-table-column>
        <el-table-column
          prop="qsRank"
          label="QS排名"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="area" label="地区" width="240" align="center">
        </el-table-column>
        <el-table-column
          prop="countCollege"
          label="院校数"
          width="190"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scoped">
            <el-button type="text" @click="toDetails(scoped.row, 'details')">
              查看详情
            </el-button>
            <el-button type="text" @click="toDetails(scoped.row, 'update')">
              编辑
            </el-button>
            <el-button type="text" @click="deleteData(scoped.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <paging
        :pageall="pageall"
        :pagenum="pagenum"
        @pageclick="pageclick"
      ></paging>
    </div>
    <schoolCollegeDetails
      :pageType="pageType"
      :detailId="detailId"
      @toBack="toBack"
      v-else
    ></schoolCollegeDetails>
    <!-- 导入 -->
    <comimport
      :show.sync="importShow"
      title="上传目录"
      templateName="学校导入模板"
      templateUrl="https://file.chaogexitongban.net/others/app/cf41f6eda09df5f56b4411fb382ed8b.xlsx"
      apiName="importSchool"
      @confirm="importConfirm"
    ></comimport>
  </div>
</template>
<script>
import paging from 'com/paging.vue';
import schoolCollegeDetails from './components/schoolCollegeDetails.vue';
import comimport from 'com/comimport/comimport.vue';
import {
  getSchoolList,
  deleteSchoolData
} from 'api/schoolCollegeManagement.js';
export default {
  name: 'SchoolCollegeManagementList',
  data() {
    return {
      searchObj: {},
      pagenum: 1,
      pageall: 0,
      loading: false,
      list: [],
      pageType: '',
      detailId: '',
      importShow: false
    };
  },
  methods: {
    // 点击分页
    pageclick(val) {
      this.pagenum = val;
      this.getList();
    },
    // 获取列表数据
    getList() {
      this.loading = true;
      getSchoolList({
        ifPage: true,
        pageSize: 10,
        pageNum: this.pagenum,
        ...this.searchObj
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 2000) {
            this.list = res.data.list;
            this.pageall = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
          console.log(res);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 跳转详情
    toDetails(item, type) {
      this.pageType = type;
      this.detailId = item ? item.id : '';
    },
    //
    toBack(refresh) {
      this.pageType = '';
      this.detailId = '';
      if (refresh) {
        this.getList();
      }
    },
    // 删除数据
    deleteData(item) {
      this.$confirm(`是否确认删除学校"${item.name}"?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      })
        .then(() => {
          this.loading = true;
          deleteSchoolData({
            id: item.id
          })
            .then((res) => {
              this.loading = false;
              if (res.code == 2000) {
                this.$message({
                  type: 'success',
                  message: '操作成功'
                });
                this.getList();
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                });
              }
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        })
        .catch(() => {});
    },
    // 导入提交
    importConfirm(pro) {
      pro
        .then((res) => {
          console.log(res);
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: '导入成功'
            });
            this.importShow = false;
            this.getList();
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  created() {
    this.getList();
  },
  components: {
    paging,
    schoolCollegeDetails,
    comimport
  }
};
</script>
<style lang="stylus" scoped></style>