<template>
  <div class="SchoolCollegeDetailsBigBox">
    <div class="buttonBox">
      <el-button type="text" @click="toBack(false)">返回上一页</el-button>
    </div>
    <div class="SchoolCollegeDetailsBox">
      <el-tabs v-model="activeName">
        <el-tab-pane label="学校" name="school">
          <school
            :pageType="pageType"
            :detailId="detailId"
            @toBack="toBack(true)"
          ></school>
        </el-tab-pane>
        <el-tab-pane label="学院" name="college" v-if="pageType != 'add'">
          <college :pageType="pageType" :detailId="detailId"></college>
        </el-tab-pane>
        <!-- el-tab-pane只有一个的时候选中状态展示会出问题 占位↓ -->
        <el-tab-pane label=""></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import school from './school';
import college from './college';
export default {
  name: 'SchoolCollegeDetails',
  props: {
    pageType: String,
    detailId: {
      type: [String, Number]
    }
  },
  data() {
    return {
      activeName: 'school'
    };
  },
  methods: {
    toBack(refresh = false) {
      this.$emit('toBack', refresh);
    }
  },
  components: {
    school,
    college
  }
};
</script>
<style lang="stylus" scoped>
.buttonBox {
  padding: 0 15px;
  box-sizing: border-box;
  margin-bottom: 16px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
}

.SchoolCollegeDetailsBox {
  background: #FFFFFF;

  >>> .el-tabs__item {
    padding: 0 24px !important;
    box-sizing: border-box;
  }
}
</style>