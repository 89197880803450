<template>
  <div class="box jyt-container">
    <div class="box_l">
      <!-- <div class="logo">超哥留学-交叉检索系统</div> -->
      <div class="flexs-alignCenter logoBox">
        <img
          src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/logo.png"
          alt=""
        />
        {{ getHeaderName }}
        <div class="systemSelectBox">
          <el-select
            :value="$store.state.aside.systemId"
            placeholder="请选择系统"
            @change="systemChange"
          >
            <el-option
              v-for="item in systemList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div style="line-height: 0px"></div>
    </div>
    <div v-if="this.$store.state.aside.systemId==1 && this.info>0" class="infobox" @click="toInfoList"><img style="margin-right:10px" src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/smallBell.png">您有{{info}}条新的客户记录待跟进！</div>
    <div class="box_r">
      <span style="margin-right: 20px">用户：{{ userName }}</span>
      <!-- <span class="out" @click="out">修改密码</span>
      <span style="margin: 0 15px;">|</span> -->
      <span class="out" @click="out">退出登录</span>
    </div>
  </div>
</template>

<script>
import { getSystemData } from 'api/index.js';
import { countByCheckFlagAndTeacherId } from 'api/customerManagement.js';
// import Cookies from 'js-cookie';
export default {
  name: '',
  data() {
    return {
      userName: '',
      selectData: [],
      show: false,
      hospitalList: [],
      keyWord: '',
      systemList: [],
      info: 0,
    };
  },
  created() {},
  mounted() {
    this.userName = sessionStorage.getItem('patientMsg')
      ? JSON.parse(sessionStorage.getItem('patientMsg')).name
      : '';
    this.getSystemList();
    this.getNotice();
  },
  methods: {
    showHos() {
      this.show = true;
    },
    onConfirm(e) {
      this.show = false;
      //   if (e != undefined) {
      //     Cookies.set("hospitalName", e);
      //     for (const item of this.selectData) {
      //       if (item.hospitalName == e) {
      //         Cookies.set("hospitalCode", item.hospitalCode, { expires: 7 });
      //         Cookies.set("hospitalType", item.hospitalType, { expires: 7 });
      //         Cookies.set("hospitalId", item.hospitalId, { expires: 7 });
      //       }
      //     }
      //     // 调用父组件中的方法
      //     this.$emit("save", "1");
      //     window.location.reload();
      // }
    },
    onCancel() {
      this.show = false;
    },
    // 退出登陆
    out() {
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push({
        path: '/login'
      });
      // location.href = './';
      // this.$store.commit("updateFn", {
      //   key: "islogin",
      //   data: false,
      // });
      // Dialog.confirm({
      //   title: '提示',
      //   message: '是否要退出',
      // })
      //   .then(() => {
      //     api(
      //       "auditRecipe/logout",
      //       "post",
      //       { token: Cookies.get("token") }
      //     ).then((res) => {
      //       console.log(res);
      //       if (res.data.code == 2000) {
      //         this.$message.success(res.data.msg);
      //         this.$router.push({ path: "/" });
      //         Cookies.remove('token')
      //         Cookies.remove('ptcz')
      //         Cookies.remove('hospitalId')
      //         Cookies.remove('hospitalCode')
      //         Cookies.remove('hospitalType')
      //         Cookies.remove('proxyUser')
      //         Cookies.remove('oaAccount')
      //         Cookies.remove('userType')
      //         Cookies.remove('hospitalName')
      //         Cookies.remove('userName')
      //         Cookies.remove('id')
      //         sessionStorage.clear();
      //       }
      //     });
      //   })
      //   .catch(() => {

      //   });
    },
    // 获取系统列表
    getSystemList() {
      getSystemData({})
        .then((res) => {
          if (res.code == 2000) {
            this.systemList = res.data.list || [];
            if (!this.$store.state.aside.systemId) {
              this.$store.dispatch('setSystemId', this.systemList[0].id);
            }
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {});
    },
    // 选中系统
    systemChange(event) {
      this.$store.dispatch('setSystemId', event);
    },

    getNotice() {
      countByCheckFlagAndTeacherId().then(res => {
        if (res.code == '2000') {
            this.info = res.data;
          }
        });
    },
    toInfoList() {
      this.$router
        .push({
          path: '/customerFollow', query: { code: '0' }
        });
    }
  },
  computed: {
    getHeaderName() {
      let name = '';
      for (let i = 0; i < this.systemList.length; i++) {
        if (this.$store.state.aside.systemId == this.systemList[i].id) {
          name = this.systemList[i].name;
        }
      }
      return name;
    }
  }
};
</script>

<style lang="stylus" scoped>
.box {
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 27px;
  height: 60px;
  line-height: 60px;
  box-sizing: border-box;
}

.box_r {
  color: #999;
  height: 60px;
  line-height: 60px;
}

div {
  span {
    cursor: pointer;
  }
}

.logo {
  font-size: 20px;
  color: #303243;
}

.box_l {
  display: flex;
  align-items: center;
}

.el-message-box {
  width: 100% !important;
}

.logoBox {
  font-size: 22px;

  img {
    width: 74px;
    margin-right: 15px;
  }
}

.systemSelectBox {
  margin-left: 24px;
}
.jyt-container{
  position: relative;
}
.infobox{
  position: absolute;
  right: 250px;
  cursor: pointer;
  top:50%;
  transform: translateY(-50%);

}
</style>