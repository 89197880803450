import request from '@utils/request';

// 获取GPA列表
export function getGPAList(data) {
  return request({
    url: '/pc/cmsCommonOrder/list',
    method: 'post',
    data
  });
}

// 获取GPA详情
export function getGPADetails(data) {
  return request({
    url: '/pc/cmsCommonOrder/detail',
    method: 'post',
    data
  });
}

// 提交GPA回复
export function submitGPAReply(data) {
  return request({
    url: '/pc/cmsCommonOrder/finishOrder',
    method: 'post',
    data
  });
}
