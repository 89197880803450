<template>
  <div class="searchBigBox">
    <div class="searchBox">
      <!-- 客户名称 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">客户名称：</div>
        <div class="searchInputBox">
          <el-input
            size="small"
            clearable
            v-model="searchObj.name"
            placeholder="请输入客户名称"
            @keydown.enter.native="searchClick"
          ></el-input>
        </div>
      </div>
      <!-- 手机号 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">手机号：</div>
        <div class="searchInputBox">
          <el-input
            size="small"
            clearable
            v-model="searchObj.phone"
            placeholder="请输入手机号"
            @keydown.enter.native="searchClick"
          ></el-input>
        </div>
      </div>
      <!-- 资源状态 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">资源状态：</div>
        <div class="searchInputBox">
          <el-select
            size="small"
            clearable
            v-model="searchObj.customType"
            placeholder="请选择资源状态"
            @change="searchClick"
          >
            <el-option
              v-for="(item, index) in resourceStatus"
              :key="index"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </div>
      </div>
      <!-- 是否分配 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">是否分配：</div>
        <div class="searchInputBox">
          <el-select
            size="small"
            clearable
            v-model="searchObj.teacherFlag"
            placeholder="请选择是否分配"
            @change="searchClick"
          >
            <el-option
              v-for="(item, index) in allocationList"
              :key="index"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </div>
      </div>
      <!-- 分配老师 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">分配老师：</div>
        <div class="searchInputBox">
          <el-input
            size="small"
            clearable
            v-model="searchObj.teacherName"
            placeholder="请输入分配老师"
            @keydown.enter.native="searchClick"
          ></el-input>
        </div>
      </div>
      <!-- 来源渠道 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">来源渠道：</div>
        <div class="searchInputBox">
          <el-select
            size="medium"
            @change="searchClick"
            clearable
            v-model="searchObj.sourceChannel"
            placeholder="请选择来源渠道"
          >
            <el-option
              v-for="(item, index) in sourceChannel"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </div>
      <!-- 渠道明细 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">渠道明细：</div>
        <div class="searchInputBox">
          <el-input
            size="small"
            clearable
            v-model="searchObj.channelDetail"
            placeholder="请输入渠道明细"
            @keydown.enter.native="searchClick"
          ></el-input>
        </div>
      </div>
      <!-- 数据提供方 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">数据提供方：</div>
        <div class="searchInputBox">
          <el-input
            size="small"
            clearable
            v-model="searchObj.dataProvider"
            placeholder="请输入数据提供方"
            @keydown.enter.native="searchClick"
          ></el-input>
        </div>
      </div>
      <!-- <div class="buttonBox">
        <div class="button">
          <el-button type="primary" size="small" @click="searchClick" round plain>查询</el-button>
        </div>
        <div class="button">
          <el-button type="primary" size="small" @click="addClick" round>新增客户</el-button>
        </div>
        <div class="button">
          <el-button type="primary" size="small" @click="allocationClick" round>分配老师</el-button>
        </div>
        <div class="button">
          <el-button type="primary" size="small" @click="importClick" round>批量导入</el-button>
        </div>
      </div> -->
    </div>
    <div class="buttonBox">
      <div class="button">
        <el-button type="primary" size="small" @click="searchClick" round>
          查询
        </el-button>
      </div>
      <div class="button">
        <el-button
          type="primary"
          size="small"
          style="background-color: #25c484; border: none"
          @click="addClick"
          round
        >
          新增客户
        </el-button>
      </div>
      <div class="button">
        <el-button
          type="primary"
          size="small"
          style="background-color: #25c484; border: none"
          @click="allocationClick"
          round
        >
          分配老师
        </el-button>
      </div>
      <div class="button">
        <el-button
          type="primary"
          size="small"
          style="background-color: #25c484; border: none"
          @click="importClick"
          round
        >
          批量导入
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import dictionary from '@/utils/dictionary.js';
export default {
  name: 'Search',
  data() {
    return {
      sourceChannel: dictionary.sourceChannel
    };
  },
  props: {
    searchObj: Object,
    resourceStatus: Array,
    allocationList: Array
  },
  methods: {
    searchClick() {
      this.$emit('searchClick');
    },
    addClick() {
      this.$emit('addClick');
    },
    allocationClick() {
      this.$emit('allocationClick', 'multiple');
    },
    importClick() {
      this.$emit('importClick');
    }
  }
};
</script>
<style lang="stylus" scoped>
.searchBigBox {
  // display: flex;
  // justify-content: space-between;
  padding-bottom: 20px;

  .searchBox {
    display: flex;
    flex-wrap: wrap;

    .searchInputBigBox {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-right: 20px;
      margin-bottom: 10px;

      .searchInputLabel {
        font-size: 14px;
        color: #333333;
      }

      .searchInputBox {
        >>> .el-input {
          width: auto;
        }

        >>> .el-input__inner {
          height: 36px;
          width: 150px;
        }
      }
    }
  }

  .buttonBox {
    display: flex;
    justify-content: flex-end;

    .button .el-button {
      width: 80px;
      height: 28px;
      line-height: 28px;
      padding: 0;
      margin-right: 11px;
    }
  }
}
</style>