<template>
  <div class="QAdetailsBigBox">
    <!-- header -->
    <div class="QAdetailsHeaderBox">
      <div class="QAdetailsHeader">
        Q&A
        <span v-if="pageType == 'detail'">详情</span>
        <span v-if="pageType == 'add'">新增</span>
        <span v-if="pageType == 'update'">编辑</span>
      </div>
      <div class="QAdetailsHeaderButtonBox">
        <el-button
          type="primary"
          size="small"
          @click="submitVerify"
          :disabled="submitLoading"
        >
          {{ submitLoading ? '请稍候...' : '保存' }}
        </el-button>
        <el-button type="primary" size="small" plain @click="toBack">
          返回
        </el-button>
      </div>
    </div>
    <!-- content -->
    <div class="QAdetailsContentBox" v-loading="loading">
      <el-form
        ref="form"
        :model="dataobj"
        label-width="80px"
        :disabled="pageType == 'detail'"
      >
        <el-form-item label="主类">
          <el-select v-model="dataobj.type" placeholder="请选择主类">
            <el-option
              :label="item.name"
              :value="item.code"
              v-for="(item, index) in typeList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="问题">
          <el-input
            type="textarea"
            :rows="3"
            resize="none"
            v-model="dataobj.question"
          ></el-input>
        </el-form-item>
        <el-form-item label="答案">
          <el-input
            type="textarea"
            :rows="3"
            resize="none"
            v-model="dataobj.answer"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { getQAData, addQAData, updateQAData } from 'api/QAmanagement.js';
export default {
  name: 'QAdetails',
  props: {
    detailObj: Object,
    pageType: String,
    typeList: Array
  },
  data() {
    return {
      dataobj: {},
      loading: false,
      submitLoading: false
    };
  },
  watch: {
    detailObj: {
      handler: function (newVal, oldVal) {
        if (!newVal.id) {
          this.dataobj = {};
        } else {
          this.getData();
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    toBack(isRefresh = false) {
      this.$emit('toBack', isRefresh);
    },
    // 获取详情
    getData() {
      this.loading = true;
      getQAData({
        id: this.detailObj.id
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 2000) {
            this.dataobj = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 提交校验
    submitVerify() {
      if (!this.dataobj.type) {
        this.$message({
          type: 'warning',
          message: '请选择主类'
        });
        return;
      }
      if (!this.dataobj.question) {
        this.$message({
          type: 'warning',
          message: '请输入问题'
        });
        return;
      }
      if (!this.dataobj.answer) {
        this.$message({
          type: 'warning',
          message: '请输入答案'
        });
        return;
      }
      this.submitClick();
    },
    // 提交
    submitClick() {
      let api = '';
      switch (this.pageType) {
        case 'add':
          api = addQAData;
          break;
        case 'update':
          api = updateQAData;
          break;
      }
      this.submitLoading = true;
      api(this.dataobj)
        .then((res) => {
          this.submitLoading = false;
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.toBack(true);
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.submitLoading = false;
        });
    }
  }
};
</script>
<style lang="stylus" scoped>
.QAdetailsBigBox {
  background: #ffffff;
}

.QAdetailsHeaderBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
  padding: 16px 24px;
  box-sizing: border-box;

  .QAdetailsHeader {
    display: flex;
    align-items: center;
  }
}

.QAdetailsContentBox {
  padding: 16px 24px;
  box-sizing: border-box;
}
</style>