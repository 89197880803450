<template>
  <div>
    <el-form :inline="true" :model="searchObj" class="demo-form-inline">
      <el-form-item label="角色名">
        <el-input
          v-model="searchObj.name"
          placeholder="请输入角色名"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" round @click="pageclick(1)">
          查询
        </el-button>
        <el-button
          type="primary"
          size="small"
          style="background-color: #25c484; border: none"
          @click="addRoleButtonClick"
          round
        >
          新增角色
        </el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" stripe style="width: 100%">
      <el-table-column type="index" label="序号" width="55" align="center">
      </el-table-column>
      <el-table-column prop="name" label="角色名" align="center">
      </el-table-column>
      <el-table-column label="操作" width="120" align="center">
        <template slot-scope="scoped">
          <el-button type="text" @click="updateButtonClick(scoped.row)">
            {{ scoped.row.loading ? '请稍候...' : '编辑' }}
          </el-button>
          <el-button type="text" @click="deleteButtonClick(scoped.row)">
            {{ scoped.row.deleteLoading ? '请稍候...' : '删除' }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <paging
      :pageall="pageall"
      :pagenum="pagenum"
      @pageclick="pageclick"
    ></paging>
    <!-- 新增、编辑菜单 -->
    <el-dialog
      :title="updateTitle"
      :visible.sync="updateShow"
      width="500px"
      :close-on-click-modal="false"
      center
    >
      <div>
        <el-form ref="form" :model="dataobj" label-width="80px">
          <el-form-item label="角色名">
            <el-input
              v-model="dataobj.name"
              placeholder="请输入角色名"
            ></el-input>
          </el-form-item>
          <el-form-item label="角色菜单">
            <el-tree
              :data="meunList"
              node-key="id"
              default-expand-all
              :expand-on-click-node="false"
              show-checkbox
              check-strictly
              :default-checked-keys="
                dataobj.menuValue ? dataobj.menuValue.split(',') : []
              "
              ref="menuTree"
            >
              <span class="custom-tree-node" slot-scope="{ data }">
                <span>{{ data.name }}</span>
              </span>
            </el-tree>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="submitClose">取 消</el-button>
        <el-button type="primary" @click="submitVerify">
          {{ submitLoading ? '请稍候...' : '确 定' }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import paging from 'com/paging.vue';
import {
  getRoleManagementData,
  getAllMenuData,
  addRoleData,
  updateRoleData,
  deleteRoleData,
  getRoleDetailsData
} from 'api/index.js';
export default {
  name: 'RoleManagement',
  data() {
    return {
      searchObj: {},
      pagenum: 1,
      pageall: 0,
      loading: false,
      list: [],
      updateTitle: '',
      updateType: '',
      updateShow: false,
      submitLoading: false,
      dataobj: {},
      meunList: []
    };
  },
  methods: {
    pageclick(val) {
      this.pagenum = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      getRoleManagementData({
        ifPage: true,
        pageSize: 10,
        pageNum: this.pagenum,
        ...this.searchObj
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 2000) {
            this.list = res.data.list;
            this.pageall = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    getMenuList() {
      getAllMenuData({
        ifPage: false
      })
        .then((res) => {
          if (res.code == 2000) {
            this.meunList = res.data.list || [];
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {});
    },
    addRoleButtonClick() {
      this.updateTitle = '新增角色';
      this.updateType = 'add';
      this.updateShow = true;
    },
    submitClose() {
      this.updateShow = false;
      this.updateTitle = '';
      this.updateType = '';
      this.dataobj = {};
    },
    submitVerify() {
      const treeRef = this.$refs.menuTree;
      const menuList = treeRef.getCheckedKeys();
      if (!this.dataobj.name) {
        this.$message({
          type: 'error',
          message: '请输入角色名称'
        });
        return false;
      }
      if (!menuList || !menuList.length) {
        this.$message({
          type: 'error',
          message: '请选择角色菜单'
        });
        return false;
      }
      this.dataobj.menuValue = menuList.join(',');
      this.submitClick();
    },
    submitClick() {
      let api = '';
      switch (this.updateType) {
        case 'add':
          api = addRoleData;
          break;
        case 'update':
          api = updateRoleData;
          break;
      }
      this.submitLoading = true;
      api(this.dataobj)
        .then((res) => {
          this.submitLoading = false;
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            const treeRef = this.$refs.menuTree;
            treeRef.setCheckedKeys([]);
            this.submitClose();
            this.getList();
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.submitLoading = false;
        });
    },
    updateButtonClick(item) {
      if (item.loading) {
        return false;
      }
      this.$set(item, 'loading', true);
      getRoleDetailsData({
        id: item.id
      })
        .then((res) => {
          this.$set(item, 'loading', false);
          if (res.code == 2000) {
            this.dataobj = res.data;
            this.updateTitle = '编辑角色';
            this.updateType = 'update';
            this.updateShow = true;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.$set(item, 'loading', false);
        });
    },
    deleteButtonClick(item) {
      if (item.deleteLoading) {
        return false;
      }
      this.$confirm(`是否确认删除角色 "${item.name}"?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$set(item, 'deleteLoading', true);
          deleteRoleData({
            id: item.id
          })
            .then((res) => {
              this.$set(item, 'deleteLoading', false);
              if (res.code == 2000) {
                this.$message({
                  type: 'success',
                  message: '操作成功'
                });
                this.getList();
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                });
              }
            })
            .catch(() => {
              this.$set(item, 'deleteLoading', false);
            });
        })
        .catch(() => {});
    }
  },
  created() {
    this.getList();
    this.getMenuList();
  },
  components: {
    paging
  }
};
</script>
<style lang="stylus" scoped></style>