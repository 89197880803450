<template>
  <div>
    <div v-if="!pageType">
      <div class="buttonBox">
        <el-form :inline="true" :model="searchObj" class="demo-form-inline">
          <el-form-item label="模块名称">
            <el-input
              v-model="searchObj.name"
              placeholder="请输入模块名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="展示名称">
            <el-input
              v-model="searchObj.showName"
              placeholder="请输入展示名称"
            ></el-input>
          </el-form-item>
        </el-form>
        <div>
          <el-button type="primary" size="small" round @click="pageclick(1)">
            查询
          </el-button>
          <el-button
            type="primary"
            size="small"
            style="background-color: #25c484; border: none"
            @click="adddicClick"
            round
          >
            新增模块
          </el-button>
        </div>
      </div>
      <el-table
        :data="list"
        stripe
        style="width: 100%"
        max-height="500"
        v-loading="loading"
      >
        <el-table-column type="index" label="序号" width="55" align="center">
        </el-table-column>
        <el-table-column prop="name" label="模块名称" align="center">
        </el-table-column>
        <el-table-column prop="showName" label="展示名称" align="center">
        </el-table-column>
        <el-table-column prop="code" label="模块code" align="center">
        </el-table-column>
        <el-table-column prop="isShow" label="是否展示" align="center">
          <template slot-scope="scope">
            {{ scope.row.isShow == 1 ? '展示' : '不展示' }}
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="toDetails(scope.row)">
              查看
            </el-button>
            <el-button type="text" @click="updateClick(scope.row)">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <paging
        :pageall="pageall"
        :pagenum="pagenum"
        @pageclick="pageclick"
      ></paging>
      <!--  -->
      <el-dialog
        :title="updateType == 'add' ? '新增模块' : '编辑模块'"
        :close-on-click-modal="false"
        :show-close="false"
        :visible.sync="dicShow"
        width="400px"
      >
        <el-form
          ref="ruleForm"
          :rules="rules"
          :model="dataobj"
          label-width="90px"
        >
          <el-form-item label="是否展示" prop="name">
            <el-switch
              v-model="dataobj.isShow"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="模块名称" prop="name">
            <el-input
              v-model="dataobj.name"
              placeholder="请输入模块名称"
              :disabled="updateType != 'add'"
            ></el-input>
          </el-form-item>
          <el-form-item label="展示名称" prop="name">
            <el-input
              v-model="dataobj.showName"
              placeholder="请输入展示名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="模块code" prop="code">
            <el-input
              v-model="dataobj.code"
              placeholder="请输入模块code"
              :disabled="updateType != 'add'"
            ></el-input>
          </el-form-item>
          <el-form-item label="排序值" prop="sort">
            <el-input
              v-model="dataobj.sort"
              placeholder="请输入排序值"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="dicDialogClose">取消</el-button>
            <el-button type="primary" @click="addSubmit">确定</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <dicDetails v-else :parentObj="parentObj" @toBack="toBack"></dicDetails>
  </div>
</template>
<script>
import dicDetails from './components/dicDetails.vue';
import paging from 'com/paging.vue';
import { modulList, modulAdd, modulUpdate, getModulDetail } from 'api/index.js';
export default {
  name: 'DictionaryManagement',
  data() {
    return {
      list: [],
      loading: false,
      searchObj: {},
      pagenum: 1,
      pageall: 0,
      pageType: '',
      dicShow: false,
      dataobj: {},
      rules: {
        name: [{ required: true, message: '请输入字典名', trigger: 'blur' }],
        code: [{ required: true, message: '请输入字典code', trigger: 'blur' }],
        sort: [{ required: true, message: '请输入排序值', trigger: 'blur' }]
      },
      updateType: '',
      parentObj: {}
    };
  },
  methods: {
    pageclick(val) {
      this.pagenum = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      modulList({
        pageNum: this.pagenum,
        pageSize: 10,
        ...this.searchObj
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 2000) {
            this.list = res.data.list || [];
            this.pageall = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    adddicClick() {
      this.dataobj = {
        isShow: 1
      };
      this.updateType = 'add';
      this.dicShow = true;
    },
    addSubmit() {
      if (this.addloading) {
        return false;
      }
      let api = '';
      let params = {};
      if (this.updateType == 'add') {
      }
      console.log(this.updateType);
      switch (this.updateType) {
        case 'add':
          api = modulAdd;
          params = {
            status: 1,
            parentId: 0,
            ...this.dataobj
          };
          break;
        case 'update':
          api = modulUpdate;
          params = this.dataobj;
          break;
      }
      this.addloading = true;
      api(params)
        .then((res) => {
          this.addloading = false;
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.dicDialogClose();
            this.getList();
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.addloading = false;
        });
    },
    updateClick(item) {
      getModulDetail({
        id: item.id
      })
        .then((res) => {
          console.log(res);
          if (res.code == 2000) {
            delete res.data.createId;
            delete res.data.createName;
            delete res.data.createTime;
            delete res.data.isDelete;
            delete res.data.updateTime;
            this.dataobj = res.data;
            this.updateType = 'update';
            this.dicShow = true;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {});
    },
    dicDialogClose() {
      this.dataobj = {};
      this.dicShow = false;
    },
    toDetails(item) {
      this.pageType = 'details';
      this.parentObj = item;
    },
    toBack() {
      this.pageType = '';
      this.parentObj = {};
    }
  },
  created() {
    this.getList();
  },
  components: {
    dicDetails,
    paging
  }
};
</script>
<style lang="stylus" scoped>
.buttonBox {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
</style>