import request from '@utils/request';
// 学校学院管理

// 获取学校列表
export function getSchoolList(data) {
  return request({
    url: 'pc/cmsSchool/list',
    method: 'post',
    data
  });
}

// 获取学校详情
export function getSchoolData(data) {
  return request({
    url: 'pc/cmsSchool/detail',
    method: 'post',
    data
  });
}

// 新增学校
export function addSchoolData(data) {
  return request({
    url: 'pc/cmsSchool/add',
    method: 'post',
    data
  });
}

// 修改学校数据
export function updateSchoolData(data) {
  return request({
    url: 'pc/cmsSchool/update',
    method: 'post',
    data
  });
}

// 删除学校数据
export function deleteSchoolData(data) {
  return request({
    url: 'pc/cmsSchool/delete',
    method: 'post',
    data
  });
}

// 获取学院列表
export function getCollegeList(data) {
  return request({
    url: 'pc/cmsCollege/list',
    method: 'post',
    data
  });
}

// 新增学院
export function addCollegeData(data) {
  return request({
    url: 'pc/cmsCollege/add',
    method: 'post',
    data
  });
}

// 修改学院
export function updateCollegeData(data) {
  return request({
    url: 'pc/cmsCollege/update',
    method: 'post',
    data
  });
}

// 删除学院
export function deleteCollegeData(data) {
  return request({
    url: 'pc/cmsCollege/delete',
    method: 'post',
    data
  });
}