<template>
  <div>
    <el-form :inline="true" :model="searchObj" class="demo-form-inline">
      <el-form-item label="用户名称">
        <el-input
          v-model="searchObj.name"
          placeholder="请输入用户名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="账号">
        <el-input
          v-model="searchObj.username"
          placeholder="请输入账号"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" round @click="pageclick(1)">
          查询
        </el-button>
        <el-button
          type="primary"
          size="small"
          style="background-color: #25c484; border: none"
          @click="deriveClick"
          :loading="deriveLoading"
          round
        >
          {{ deriveLoading ? '请稍候...' : '导出' }}
        </el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" stripe style="width: 100%">
      <el-table-column type="index" label="序号" width="55" align="center">
      </el-table-column>
      <el-table-column prop="name" label="用户名称" align="center">
      </el-table-column>
      <el-table-column prop="userType" label="用户身份" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.userType == 0">学生</span>
          <span v-if="scope.row.userType == 1">学生家长</span>
          <span v-if="scope.row.userType == 2">销售</span>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="账号" align="center">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center">
      </el-table-column>
    </el-table>
    <paging
      :pageall="pageall"
      :pagenum="pagenum"
      @pageclick="pageclick"
    ></paging>
  </div>
</template>
<script>
import paging from 'com/paging.vue';
import { getUserList, deriveStudentList } from 'api/index.js';
import { downloadFile } from '@utils/smallTools/index.js';
export default {
  name: 'UserManagement',
  data() {
    return {
      searchObj: {},
      pagenum: 1,
      pageall: 0,
      loading: false,
      list: [],
      deriveLoading: false
    };
  },
  methods: {
    // 点击分页
    pageclick(val) {
      this.pagenum = val;
      this.getList();
    },
    // 获取列表
    getList() {
      this.loading = true;
      getUserList({
        ifPage: true,
        pageNum: this.pagenum,
        pageSize: 10,
        ...this.searchObj
      })
        .then((res) => {
          this.loading = false;
          console.log(res);
          if (res.code == 2000) {
            this.list = res.data.list || [];
            this.pageall = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 点击导出
    deriveClick() {
      this.deriveLoading = true;
      deriveStudentList({
        ifPage: false,
        ...this.searchObj
      })
        .then((res) => {
          this.deriveLoading = false;
          console.log(typeof res);
          if (res.size && res.size > 0) {
            downloadFile(res, null, true);
          } else {
            this.$message({
              type: 'error',
              message: res.msg || '失败'
            });
          }
        })
        .catch(() => {
          this.deriveLoading = false;
        });
    }
  },
  created() {
    this.getList();
  },
  components: {
    paging
  }
};
</script>
<style lang="stylus" scoped></style>