
<template>
  <div class="headmap-list">
    <headerTitle title="示例库管理"></headerTitle>
    <div class="content">
      <div class="searchBigBox">
        <div class="searchBox" style="">
          <div class="inputBigBox">
            <div class="inputText">问题示例：</div>
            <el-input
              class="input"
              placeholder="请输入问题"
              v-model="searchObj.questionContent"
              @keyup.enter="searchClick"
            ></el-input>
          </div>
          <div class="inputBigBox">
            <div class="inputText">答案示例：</div>
            <el-input
              class="input"
              placeholder="请输入答案"
              v-model="searchObj.answerContent"
              @keyup.enter="searchClick"
            ></el-input>
          </div>
          <div class="inputBigBox">
            <div class="inputText">创建人：</div>
            <el-input
              class="input"
              placeholder="请输入创建人姓名"
              v-model="searchObj.createName"
              @keyup.enter="searchClick"
            ></el-input>
          </div>
        </div>
        <div class="searchButton">
          <el-button type="primary" size="mini" round @click="searchClick">
            查询
          </el-button>
          <el-button
            type="primary"
            size="mini"
            style="background-color: #25c484; border: none"
            round
            @click="toChannelClick"
          >
            导入
          </el-button>
        </div>
        <div class="searchBox">
          <div class="inputBigBox">
            <div class="inputText">分类：</div>
            <el-input
              class="input"
              readonly
              placeholder=""
              v-model="searchObj.questionTags"
              @click.native="showClass"
            ></el-input>
          </div>
          <div class="inputBigBox">
            <div class="inputText">创建时间：</div>
            <el-date-picker
              :picker-options="setDateRange"
              clearable
              @change="searchClick"
              v-model="searchObj.time"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <el-table
        :data="dataList"
        style="width: 100%"
        v-loading="loading"
        stripe
        border
      >
        <el-table-column
          label="序号"
          align="center"
          :index="indexMethod"
          type="index"
          width="55"
        ></el-table-column>
        <el-table-column
          prop="questionContent"
          label="问题示例"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="answerContent"
          label="答案示例"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="questionTags"
          label="问题标签"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="createName"
          label="创建人"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="text"
              @click="handleDetail(scope.row)"
            >
              查看详情
            </el-button>
            <el-button size="small" type="text" @click="handleEdit(scope.row)">
              编辑
            </el-button>
            <el-button
              size="small"
              type="text"
              @click="handleDelete(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
        ></el-pagination>
      </el-table>
      <paging
        :pageall="pageAll"
        :pagenum="pageNum"
        :pagesize="pageSize"
        @pageclick="pageclick"
      ></paging>
    </div>
    <!-- 删除 -->
    <el-dialog
      title="删除示例信息"
      :visible.sync="dialogVisible"
      :show-close="false"
      center
      width="410px"
    >
      <span style="display: block; text-align: center">
        请确认信息无误后操作，确定后不可取消
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="info"
          @click="dialogVisible = false"
          round
          style="width: 46%; border-radius: 25px"
        >
          取消
        </el-button>
        <el-button
          type="primary"
          @click="cancel"
          round
          style="width: 46%; border-radius: 25px"
        >
          确定
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="分类"
      :visible.sync="dialogVisibleClass"
      :show-close="false"
      center
      width="600px"
    >
      <div class="classification">
        <div
          v-for="(item, index) in typeList"
          :key="'type_' + index"
          v-show="item && item.childDict && item.childDict.length > 0"
        >
          <p class="typeName">{{ item.name }}</p>

          <el-checkbox-group
            v-model="checkedCities"
            @change="handleCheckedCitiesChange"
          >
            <el-checkbox
              border
              v-for="(childItem, childIndex) in item.childDict"
              :label="childItem.name"
              :key="'checkbox_' + index + '_' + childIndex"
            ></el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="info"
          @click="dialogVisibleClass = false"
          round
          style="width: 46%; border-radius: 25px"
        >
          取消
        </el-button>
        <el-button
          type="primary"
          @click="cancelType"
          round
          style="width: 46%; border-radius: 25px"
        >
          确定
        </el-button>
      </span>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog
      title="导入"
      :visible.sync="toChannelVisible"
      width="418px"
      :close-on-click-modal="false"
      center
    >
      <div>
        <el-upload
          class="upload-demo"
          action="uploadApi"
          drag
          :multiple="false"
          :show-file-list="false"
          :http-request="toChannelRequest"
          accept=".xls, .xlsx"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text" v-if="!toChannelName">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
          <div class="el-upload__text" v-else>{{ toChannelName }}</div>
          <div class="el-upload__tip" slot="tip">
            <div style="margin-top: 8px; color: #555555">
              温馨提示：仅允许导入“xls”或“xlsx”格式文件！
              <a
                style="color: #2797ff"
                href="https://files.jin1.com.cn/others/template/39e9c2a7ddd7e85160848b2678cf1500.xlsx"
                download="示例库管理导入模板"
              >
                下载模板
              </a>
            </div>
          </div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          round
          @click="toChannelClose"
          style="width: 48%; border-radius: 25px"
        >
          取 消
        </el-button>
        <el-button
          round
          style="width: 48%; border-radius: 25px"
          type="primary"
          :disabled="toChannelLoading"
          @click="toChannelSubmit"
        >
          {{ toChannelLoading ? '请稍候...' : '确 定' }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

  <script>
import headerTitle from '../title.vue';
import {
  documentInfoList,
  uploadExcel,
  getDictTreeByCode,
  documentInfodelete
} from 'api/index.js';
import paging from '../paging.vue';
export default {
  components: {
    headerTitle,
    paging
  },
  data() {
    return {
      setDateRange: {
        disabledDate: (time) => {
          // 禁用今天之后的日期【当前天可选】
          return time.getTime() > Date.now() + 86400000 - 5;
        }
      },
      dataList: [],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 10,
      pageNum: 1,
      pageAll: 0,
      searchObj: {
        questionContent: '',
        answerContent: '',
        time: '',
        createName: '',
        questionTags: ''
      },
      statusList: [
        {
          label: '全部'
        },
        {
          value: 0,
          label: '停用'
        },
        {
          value: 1,
          label: '启用'
        }
      ],
      dialogVisible: false,
      dialogVisibleClass: false,
      typeList: [],
      checkboxGroup1: [],
      checkboxGroup2: [],
      toChannelVisible: false,
      toChannelLoading: false,
      formData: '',
      toChannelName: '',
      uploadApi: '/documentInfo/uploadExcel',
      checkedCities: [],
      id: ''
    };
  },
  mounted() {
    this.getList();
    this.getTypeList();
  },
  methods: {
    getList() {
      const params = {
        ...this.searchObj
      };
      params.createTimeBegin =
        this.searchObj.time && this.searchObj.time[0]
          ? this.searchObj.time[0]
          : '';
      params.createTimeEnd =
        this.searchObj.time && this.searchObj.time[1]
          ? this.searchObj.time[1]
          : '';
      delete params.time;
      params.ifPage = true;
      params.pageSize = this.pageSize;
      params.pageNum = this.pageNum;
      params.status = 2;
      this.loading = true;
      documentInfoList(params).then((res) => {
        this.loading = false;
        if (res.code == '2000') {
          this.dataList = res.data.list;
          this.pageAll = res.data.total;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    // 点击分页
    pageclick(val) {
      this.pageNum = val;
      this.getList();
    },
    searchClick() {
      this.pageclick(1);
    },
    handleDelete(row) {
      this.dialogVisible = true;
      this.id = row.id;
    },
    handleDetail(row) {
      this.$router.push({
        path: 'SamplebaseDetail',
        query: {
          id: row.id,
          isDetail: '1'
        }
      });
    },
    handleEdit(row) {
      this.$router.push({
        path: 'SamplebaseDetail',
        query: {
          id: row.id,
          isDetail: '2'
        }
      });
    },
    indexMethod(index) {
      return index + 1 + (this.pageNum - 1) * this.pageSize;
    },
    cancel() {
      const data = {
        id: this.id
      };
      documentInfodelete(data).then((res) => {
        if (res.code == 2000) {
          this.$message({
            type: 'success',
            message: '操作成功'
          });
        }
        this.dialogVisible = false;
        this.pageclick(1);
      });
    },
    showClass() {
      this.dialogVisibleClass = true;
    },
    // cancelType() {
    //   let concatenatedLabels = '';
    //   if (Array.isArray(this.checkboxGroup1)) {
    //       this.checkboxGroup1.forEach(item => {
    //           concatenatedLabels += item + ',';
    //       });
    //   }
    //   if (Array.isArray(this.checkboxGroup2)) {
    //       this.checkboxGroup2.forEach(item => {
    //           concatenatedLabels += item + ',';
    //       });
    //   }
    //   // 如果需要去除最后的逗号
    //   this.searchObj.type = concatenatedLabels.slice(0, -1);
    //   this.dialogVisibleClass = false;
    // },
    // 关闭导入弹窗
    toChannelClose() {
      this.toChannelName = '';
      this.formData = '';
      this.toChannelVisible = false;
    },
    // 导入
    toChannelRequest(files) {
      const file = files.file;
      this.toChannelName = file.name;
      this.formData = new FormData();
      this.formData.append('file', file);
    },
    toChannelSubmit() {
      if (!this.formData) {
        this.$message({
          type: 'warning',
          message: '请选择导入文件'
        });
        return;
      }
      this.$set(this, 'toChannelLoading', true);
      uploadExcel(this.formData)
        .then((res) => {
          this.$set(this, 'toChannelLoading', false);

          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: res.msg
            });
            this.getList();
            this.toChannelVisible = false;
            this.toChannelClose();
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$set(this, 'toChannelLoading', false);
        });
    },
    // 点击导入
    toChannelClick() {
      this.toChannelVisible = true;
    },
    getTypeList() {
      getDictTreeByCode({ code: 'QUESTION_TAG' }).then((res) => {
        if (res.code == '2000') {
          this.typeList = res.data;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    cancelType() {
      this.dialogVisibleClass = false;
      this.searchObj.questionTags = this.checkedCities.join(',');
    },
    handleCheckedCitiesChange(value) {
      this.checkedCities = value;
    }
  }
};
</script>

  <style lang="stylus" scoped>
  .headmap-list {
  }

  >>> .el-table__header th, >>> .el-table__header tr {
    background: #EBF5FF !important;
    font-size: 14px !important;
    color: #202224 !important;
  }

  >>> .el-table__body th, >>> .el-table__body tr {
    background: #fff !important;
    font-size: 14px !important;
    color: #202224 !important;
  }

  >>> .el-table__body tr .el-table__cell {
    padding: 8px 0 !important;
  }

  >>> .el-table--striped .el-table__body tr.el-table__row--striped td {
    background: #F5FAFF !important;
  }

  .content {
    width: 100%;
    height: calc(85vh - 30px);
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
  }

  .searchBigBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 0 20px;

    .searchBox {
      display: flex;
      margin-bottom: 16px;
      align-items: center;

      .inputBigBox {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 30px;

        .input {
          width: 200px;
        }

        .inputText {
          font-size: 14px;
          color: #333;
          margin-right: 6px;
        }

        .inputBox {
          width: 166px;
        }

        .inputButton {
          width: 36px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #409EFF;
          font-size: 16px;
          color: #fff;
          border-radius: 0 8px 8px 0;
          cursor: pointer;
        }
      }
    }

    .searchButton {
      display: flex;

      .el-button {
        padding: 0 !important;
        width: 80px;
        height: 28px;
        line-height: 28px;
      }
    }
  }

  .el-checkbox {
    margin-right: 10px;
  }

  .typeName {
    margin-bottom: 10px;
  }
</style>