<template>
  <div
    :class="{ fullscreen: fullscreen }"
    class="tinymce-container"
    :style="{ width: containerWidth }"
  >
    <textarea :id="tinymceId" class="tinymce-textarea" />
  </div>
</template>

  <script>
/**
 * docs:
 * https://panjiachen.github.io/vue-element-admin-site/feature/component/rich-editor.html#tinymce
 */

import load from "./dynamicLoadScript";
import axios from "axios";
// why use this cdn, detail see https://github.com/PanJiaChen/tinymce-all-in-one
// http://cdn.jsdelivr.net无法访问了,将cdn.jsdelivr.net域名替换为fastly.jsdelivr.net或者gcore.jsdelivr.net
// const tinymceCDN = 'https://cdn.jsdelivr.net/npm/tinymce-all-in-one@4.9.3/tinymce.min.js'
const tinymceCDN =
  "https://jyt-new.oss-cn-beijing.aliyuncs.com/Chaoge/tinymce/tinymce.min.js";

export default {
  name: "Tinymce",
  props: {
    id: {
      type: String,
      default: function () {
        return (
          "vue-tinymce-" +
          +new Date() +
          ((Math.random() * 1000).toFixed(0) + "")
        );
      },
    },
    value: {
      type: String,
      default: "",
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    menubar: {
      type: String,
      default: "file edit insert view format table",
    },
    height: {
      type: [Number, String],
      required: false,
      default: 360,
    },
    width: {
      type: [Number, String],
      required: false,
      default: "auto",
    },
  },
  data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      languageTypeList: {
        en: "en",
        zh: "zh_CN",
        es: "es_MX",
        ja: "ja",
      },
    };
  },
  computed: {
    language() {
      return this.languageTypeList.zh;
    },
    containerWidth() {
      const width = this.width;
      if (/^[\d]+(\.[\d]+)?$/.test(width)) {
        // matches `100`, `'100'`
        return `${width}px`;
      }
      return width;
    },
  },
  watch: {
    value(val) {
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(() =>
          window.tinymce.get(this.tinymceId).setContent(val || "")
        );
      }
    },
    language() {
      // this.destroyTinymce()
      this.$nextTick(() => this.initTinymce());
    },
  },
  mounted() {
    this.init();
  },
  activated() {
    if (window.tinymce) {
      this.initTinymce();
    }
  },
  deactivated() {
    this.destroyTinymce();
  },
  destroyed() {
    this.destroyTinymce();
  },
  methods: {
    init() {
      // dynamic load tinymce from cdn
      load(tinymceCDN, (err) => {
        if (err) {
          this.$message.error(err.message);
          return;
        }
        this.initTinymce();
      });
    },
    initTinymce() {
      window.tinymce.init({
        language: this.language,
        selector: `#${this.tinymceId}`,
        plugins:
          " preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media  code codesample table charmap  pagebreak nonbreaking anchor insertdatetime advlist lists wordcount    emoticons autosave   autoresize  ",
        toolbar:
          "forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | table image media charmap emoticons hr pagebreak insertdatetime print preview | fullscreen |  indent2em lineheight formatpainter axupimgs",
        height: 650, // 编辑器高度
        min_height: 400,
        paste_data_images: false,
        statusbar: false,
        fontsize_formats: "12px 14px 16px 18px 24px 36px 48px 56px 72px",
        font_formats:
          "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",

        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        images_upload_url: "https://jin1.com.cn/jinyi-util/upload/uploadImage",
        images_upload_base_path:
          "https://jin1.com.cn/jinyi-util/upload/uploadImage",
        images_upload_handler: (blobInfo, progress) =>
          new Promise((resolve, reject) => {
            var formData = new FormData();
            var file = blobInfo.blob();
            formData.append("file", file, file.name);
            axios({
              method: "post",
              url: "https://jin1.com.cn/jinyi-util/upload/uploadImage",
              dataType: "json",
              headers: { "Content-Type": "multipart/form-data" },
              data: formData,
            })
              .then((result) => {
                if (result.status == 200) {
                  var imgUrl = result.data.result;
                  resolve(imgUrl);
                } else {
                  reject("Upload failed with status: " + result.status);
                }
              })
              .catch((error) => {
                reject("Upload error: " + error.message);
              });
          }),
        // images_upload_handler(blobInfo, success, failure) {
        //     var formData = new FormData();
        //     var file = blobInfo.blob();
        //     console.log(success());
        //     formData.append("file", file, file.name);
        //     axios({
        //         method: "post",
        //         url: "https://jin1.com.cn/jinyi-util/upload/uploadImage",
        //         dataType: "json",
        //         headers: { 'Content-Type': 'multipart/form-data' },
        //         data: formData,
        //     }).then((result) => {
        //         if (result.status == 200) {
        //         var imgUrl = result.data.result;
        //         console.log(imgUrl);
        //         success(imgUrl);
        //         window.tinymce.get(this.tinymceId).insertContent(`<img src="${imgUrl}">`);
        //         } else {
        //         failure("Upload failed with status: " + result.status);
        //         }
        //     }).catch((error) => {
        //         failure("Upload error: " + error.message);
        //     });
        // },
      });
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId);
      if (this.fullscreen) {
        tinymce.execCommand("mceFullScreen");
      }

      if (tinymce) {
        tinymce.destroy();
      }
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value);
    },
    getContent() {
      window.tinymce.get(this.tinymceId).getContent();
    },
    imageSuccessCBK(arr) {
      arr.forEach((v) =>
        window.tinymce
          .get(this.tinymceId)
          .insertContent(`<img class="wscnph" src="${v.url}" >`)
      );
    },
  },
};
</script>

  <style lang="stylus" scoped>
  .tinymce-container {
    position: relative;
    line-height: normal;

    /deep/ * {
      border-color: #efefef;
      white-space: pre-wrap;
    }
  }

  .tinymce-container {
    ::v-deep {
      .mce-fullscreen {
        z-index: 10000;
      }
    }
  }

  .tinymce-textarea {
    visibility: hidden;
    z-index: -1;
  }

  .editor-custom-btn-container {
    position: absolute;
    right: 4px;
    top: 4px;
    /* z-index: 2005; */
  }

  .fullscreen .editor-custom-btn-container {
    z-index: 10000;
    position: fixed;
  }

  .editor-upload-btn {
    display: inline-block;
  }
</style>
