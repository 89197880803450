<template>
  <div class="rankingManagementBigBox">
    <el-tabs v-model="activeName">
      <el-tab-pane label="学校" name="school">
        <school></school>
      </el-tab-pane>
      <el-tab-pane label="专业" name="specialty">
        <specialty></specialty>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import school from './components/school.vue';
import specialty from './components/specialty.vue';
export default {
  name: 'RankingManagement',
  data() {
    return {
      activeName: 'school'
    };
  },
  components: {
    school,
    specialty
  }
};
</script>
<style lang="stylus" scoped>
.rankingManagementBigBox {
  background: #ffffff;
  padding: 24px;
  box-sizing: border-box;
}
</style>