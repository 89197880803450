<template>
  <div>
    <div v-if="!pageType">
      <el-form :inline="true" :model="searchObj" class="demo-form-inline">
        <el-form-item label="订单编号">
          <el-input
            v-model="searchObj.orderNo"
            placeholder="请输入订单编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input
            v-model="searchObj.orderUserMobile"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="下单时间">
          <el-date-picker
            v-model="searchObj.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="是否完成">
          <el-select
            v-model="searchObj.orderStatus"
            placeholder="请选择是否完成"
          >
            <el-option label="全部" :value="''"></el-option>
            <el-option label="支付超时" :value="-1"></el-option>
            <el-option label="待支付" :value="0"></el-option>
            <el-option label="未完成" :value="1"></el-option>
            <el-option label="支付失败" :value="2"></el-option>
            <el-option label="已完成" :value="3"></el-option>
            <el-option label="已退款" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" round @click="pageclick(1)">
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <el-table :data="list" stripe style="width: 100%" v-loading="loading">
        <el-table-column type="index" label="序号" width="55" align="center">
        </el-table-column>
        <el-table-column prop="orderNo" label="订单编号" align="center">
        </el-table-column>
        <el-table-column prop="orderName" label="订单名称" align="center">
        </el-table-column>
        <el-table-column prop="orderUserName" label="用户昵称" align="center">
        </el-table-column>
        <el-table-column prop="identity" label="身份" align="center">
        </el-table-column>
        <el-table-column prop="orderUserMobile" label="手机号" align="center">
        </el-table-column>
        <el-table-column prop="orderStatusName" label="是否完成" align="center">
        </el-table-column>
        <el-table-column prop="orderAmount" label="订单金额" align="center">
        </el-table-column>
        <el-table-column prop="createTime" label="下单时间" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="toDetails(scope.row)">
              查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <paging
        :pageall="pageall"
        :pagenum="pagenum"
        @pageclick="pageclick"
      ></paging>
    </div>
    <GPADetails :detailObj="detailObj" @toBack="toBack" v-else></GPADetails>
  </div>
</template>
<script>
import paging from 'com/paging.vue';
import GPADetails from './components/GPADetails.vue';
import { getGPAList } from 'api/GPAManagement.js';
export default {
  name: 'GPAManagement',
  data() {
    return {
      pageType: '',
      searchObj: {
        orderStatus: ''
      },
      pagenum: 1,
      pageall: 0,
      loading: false,
      list: [],
      detailObj: {}
    };
  },
  methods: {
    pageclick(val) {
      this.pagenum = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      getGPAList({
        startDate:
          this.searchObj.time && this.searchObj.time[0]
            ? this.searchObj.time[0]
            : '',
        endDate:
          this.searchObj.time && this.searchObj.time[1]
            ? this.searchObj.time[1]
            : '',
        pageSize: 10,
        pageNum: this.pagenum,
        orderNo: this.searchObj.orderNo || '',
        orderUserMobile: this.searchObj.orderUserMobile || '',
        orderStatus: this.searchObj.orderStatus
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 2000) {
            this.list = res.data.list || [];
            this.pageall = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    toDetails(item) {
      this.detailObj = item;
      this.pageType = 'detail';
    },
    toBack(isRefresh) {
      this.detailObj = {};
      this.pageType = '';
      if (isRefresh) {
        this.getList();
      }
    }
  },
  created() {
    this.getList();
  },
  components: {
    paging,
    GPADetails
  }
};
</script>
<style lang="stylus" scoped></style>