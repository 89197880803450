const filter = {
  contactTypeFilter(val) {
    switch (val) {
      case "0":
        return "电话联系";
      case "1":
        return "微信联系";
      case "2":
        return "面谈";
      default:
        return "";
    }
  },
  customTypeFilter(val) {
    switch (val) {
      case "0":
        return "新客未回访";
      case '1':
        return "潜在客户";
      case '2':
        return "深度意向";
      case '3':
        return "长线客户";
      case '4':
        return "已签约资源";
      case '5':
        return "退回资源";
      case '6':
        return "联系不到";
      case '7':
        return "放弃出国";
      default:
        return "";
    }
  },
  customTypeTeacherFilter(val) {
    switch (val) {
      case "新客未回访":
        return "待反馈";
      case '潜在客户':
        return "待反馈";
      case '深度意向':
        return "待反馈";
      case '长线客户':
        return "待反馈";
      case '已签约资源':
        return "已签约资源";
      case '退回资源':
        return "退回资源";
      case '联系不到':
        return "待反馈";
      case '放弃出国':
        return "待反馈";
      default:
        return "";
    }
  }
  // familyDocOrderStatus(val) {
  //   switch (val) {
  //     case 1:
  //       return "待支付";
  //     case 3:
  //       return "进行中";
  //     case 4:
  //       return "已结束";
  //     case 5:
  //       return "已退费";
  //   }
  // },
  // familyDocRelation(val) { // 家庭医生-与就诊人关系
  //   switch (val) {
  //     case 1:
  //       return "父母";
  //     case 2:
  //       return "子女";
  //     case 3:
  //       return "配偶";
  //     case 4:
  //       return "其他";
  //   }
  // },
  // serviceStatus(val) { // 服务到家
  //   switch (val) {
  //     case 1:
  //       return "进行中";
  //     case 2:
  //       return "已结束";
  //     case 3:
  //       return "已分配";
  //     case 4:
  //       return "待分配";
  //     case 5:
  //       return "已退费";
  //     case 6:
  //       return "审核未通过";
  //     case 7:
  //       return "待支付";
  //     case 8:
  //       return "支付成功";
  //     case 9:
  //       return "支付失败";
  //     default:
  //       return "已关闭";
  //   }
  // },
  // outServeRefundFilter(status) { // 院外服务退费状态
  //   switch (status) {
  //     case 0:
  //       return '未申请';
  //     case 1:
  //       return '管理员审核中...';
  //     case 2:
  //       return '退费成功';
  //     case 3:
  //       return '审核不通过';
  //     case 4:
  //       return '已取消';
  //   }
  // },
  // medicalServicePackageStatusFilter(status) { // 医疗服务包订单状态
  //   switch (status) {
  //     case 1:
  //       return "待支付";
  //     case 3:
  //       return "进行中";
  //     case 4:
  //       return "已结束";
  //     case 5:
  //       return "已退费";
  //   }
  // },
  // sexFilter(val) { // 健康档案性别
  //   switch (val) {
  //     case 0:
  //       return "女";
  //     case 1:
  //       return "男";
  //   }
  // },
  // examineStatusFilter(val) { // 住院预约-审核状态
  //   switch (val) {
  //     case 0:
  //       return "未审核";
  //     case 1:
  //       return "审核通过";
  //     case 2:
  //       return "审核不通过";
  //   }
  // },
  // matrimonyStatusFilter(val) { // 住院预约-婚姻状况
  //   switch (val) {
  //     case 1:
  //       return "未婚";
  //     case 2:
  //       return "已婚";
  //     case 3:
  //       return "初婚";
  //     case 4:
  //       return "再婚";
  //     case 5:
  //       return "复婚";
  //     case 6:
  //       return "丧偶";
  //     case 7:
  //       return "离婚";
  //     case 8:
  //       return "未说明情况";
  //   }
  // },
  // admissionMethodStatusFilter(val) { // 住院预约-入院方式
  //   switch (val) {
  //     case 1:
  //       return "自行";
  //     case 2:
  //       return "车推";
  //     case 3:
  //       return "抱入";
  //     case 4:
  //       return "扶持";
  //   }
  // },
  // relationshipFilter(val) { // 住院预约-关系列表
  //   switch (val) {
  //     case 1:
  //       return "父母";
  //     case 2:
  //       return "兄弟姐妹";
  //     case 3:
  //       return "配偶";
  //     case 4:
  //       return "子女";
  //     case 5:
  //       return "其他";
  //   }
  // },
  // hospitalizedStatusFilter(val) { // 住院预约-开具状态
  //   switch (val) {
  //     case 0:
  //       return "未开具";
  //     case 1:
  //       return "已开具";
  //   }
  // },
  // reservationStatusFilter(val) { // 住院预约-确认状态
  //   switch (val) {
  //     case 0:
  //       return "否";
  //     case 1:
  //       return "是";
  //   }
  // },
  // diagnosisInfoFilter(val) { // 住院预约-诊断信息
  //   switch (val) {
  //     case 1:
  //       return "待诊";
  //     case 2:
  //       return "疑诊";
  //     case 3:
  //       return "确诊";
  //   }
  // },
  // principleFilter(val) { // 住院预约-收治原则
  //   switch (val) {
  //     case 1:
  //       return "住院观察";
  //     case 2:
  //       return "住院治疗";
  //     case 3:
  //       return "重症监护治疗";
  //   }
  // },
  // bedTypeFilter(val) { // 住院预约-床位类型
  //   switch (val) {
  //     case 1:
  //       return "普通床位";
  //     case 2:
  //       return "单间";
  //     case 3:
  //       return "特护床位";
  //   }
  // }
};
export default filter;
