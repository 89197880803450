import request from '@utils/request';

// 获取学校排名
export function getRankingSchoolList(data) {
  return request({
    url: '/pc/cmsRank/list',
    method: 'post',
    data
  });
}

// 提交学校排名
export function submitSchoolRanking(data) {
  return request({
    url: '/pc/cmsRank/addList',
    method: 'post',
    data
  });
}

// 删除一条学校排名
export function deleteSchoolRanking(data) {
  return request({
    url: '/pc/cmsRank/delete',
    method: 'post',
    data
  });
}

// 获取排名学校详情
export function getSchoolRankingDetails(data) {
  return request({
    url: '/pc/cmsRank/detail',
    method: 'post',
    data
  });
}

// 修改排名学校提交
export function updateSchoolRankingSubmit(data) {
  return request({
    url: '/pc/cmsRank/update',
    method: 'post',
    data
  });
}
