import request from '@utils/request';

// export function uploadFile(data, onUploadProgress) {
//   return request({
//     url: '/common/uploadFile',
//     method: 'post',
//     headers: {
//       "Content-Type": "multipart/form-data"
//     },
//     onUploadProgress: (progressEvent) => {
//       if (onUploadProgress) { // onUploadProgress是上传进度
//         onUploadProgress(progressEvent);
//       }
//     },
//     data
//   }, false);
// }

// 示例库管理列表
export function documentInfoList(data) {
  return request({
    url: '/documentInfo/page',
    method: 'post',
    data
  });
}
// 示例库管理列表详情
export function documentInfo(data) {
  return request({
    url: '/documentInfo/get',
    method: 'post',
    data
  });
}
// 示例库管理列表编辑
export function documentInfoupdate(data) {
  return request({
    url: '/documentInfo/update',
    method: 'post',
    data
  });
}
// 示例库管理列表删除
export function documentInfodelete(data) {
  return request({
    url: '/documentInfo/delete',
    method: 'post',
    data
  });
}
// 示例库管理
export function getDictTreeByCode(data) {
  return request({
    url: '/pc/sys/dic/getDictTreeByCode',
    method: 'post',
    data
  });
}
// 字典
export function dicList() {
  return request({
    url: `/pc/sys/dic/list`,
    method: 'get'
  });
}
// 字典详情
export function dicListByCode(data) {
  return request({
    url: `/pc/sys/dic/listByCode`,
    method: 'post',
    data
  });
}
// 字典新增
export function dicAdd(data) {
  return request({
    url: '/pc/sys/dic/add',
    method: 'post',
    data
  });
}
// 字典删除/编辑
export function dicUpdate(data) {
  return request({
    url: '/pc/sys/dic/update',
    method: 'post',
    data
  });
}

// 模块列表
export function modulList(data) {
  return request({
    url: `/pc/cmsModule/list`,
    method: 'post',
    data
  });
}

// 模块详情
export function getModulDetail(data) {
  return request({
    url: `/pc/cmsModule/detail`,
    method: 'post',
    data
  });
}

// 子模块列表
export function modulListByCode(data) {
  return request({
    url: `/pc/cmsModule/getTreeByCode`,
    method: 'post',
    data
  });
}
// 模块新增
export function modulAdd(data) {
  return request({
    url: '/pc/cmsModule/add',
    method: 'post',
    data
  });
}
// 模块编辑
export function modulUpdate(data) {
  return request({
    url: '/pc/cmsModule/update',
    method: 'post',
    data
  });
}

// 模块删除
export function modulDelete(data) {
  return request({
    url: '/pc/cmsModule/delete',
    method: 'post',
    data
  });
}

// 导入
export function uploadExcel(data) {
  return request({
    url: '/documentInfo/uploadExcel',
    method: 'post',
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data
  }, false);
}
// 头图列表
export function sysBannerList(data) {
  return request({
    url: '/pc/sysBanner/list',
    method: 'post',
    data
  });
}

// 头图新增
export function sysBannerUpload(data) {
  return request({
    url: '/pc/sysBanner/upload',
    method: 'post',
    data
  }, false);
}

// 头图编辑
export function sysBannerUpdate(data) {
  return request({
    url: '/pc/sysBanner/update',
    method: 'post',
    data
  }, false);
}
// 头图详情
export function sysBannerDetail(data) {
  return request({
    url: '/pc/sysBanner/detail',
    method: 'post',
    data
  });
}
// 启停头图
export function switchStatus(data) {
  return request({
    url: '/pc/sysBanner/switchStatus',
    method: 'post',
    data
  });
}

// 上传图片
export function uploadFile(data, onUploadProgress) {
  return request({
    url: '/pc/sysBanner/uploadFile',
    method: 'post',
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress: (progressEvent) => {
      if (onUploadProgress) { // onUploadProgress是上传进度
        onUploadProgress(progressEvent);
      }
    },
    data
  }, false);
}
// 上传视频
export function uploadVideo(data, onUploadProgress) {
  return request({
    url: '/pc/sysBanner/uploadVideoFile',
    method: 'post',
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress: (progressEvent) => {
      if (onUploadProgress) { // onUploadProgress是上传进度
        onUploadProgress(progressEvent);
      }
    },
    data
  }, false);
}

// 根据系统获取菜单列表
export function getRoleMenuList(data) {
  return request({
    url: '/pc/sysRole/findRoleMenu',
    method: 'post',
    data
  });
}

// 获取系统列表
export function getSystemData(data) {
  return request({
    url: '/pc/sysSystem/list',
    method: 'post',
    data
  });
}

// 获取全部菜单列表
export function getAllMenuData(data) {
  return request({
    url: '/pc/sysMenu/list',
    method: 'post',
    data
  });
}

// 新增菜单
export function addMenuData(data) {
  return request({
    url: '/pc/sysMenu/add',
    method: 'post',
    data
  });
}

// 编辑菜单
export function updateMenuData(data) {
  return request({
    url: '/pc/sysMenu/update',
    method: 'post',
    data
  });
}

// 删除菜单
export function deleteMenuData(data) {
  return request({
    url: '/pc/sysMenu/delete',
    method: 'post',
    data
  });
}

// 获取菜单数据
export function getMenuDetails(data) {
  return request({
    url: '/pc/sysMenu/detail',
    method: 'post',
    data
  });
}

// 获取角色列表
export function getRoleManagementData(data) {
  return request({
    url: '/pc/sysRole/list',
    method: 'post',
    data
  });
}

// 新增角色
export function addRoleData(data) {
  return request({
    url: '/pc/sysRole/add',
    method: 'post',
    data
  });
}

// 编辑角色
export function updateRoleData(data) {
  return request({
    url: '/pc/sysRole/update',
    method: 'post',
    data
  });
}

// 删除角色
export function deleteRoleData(data) {
  return request({
    url: '/pc/sysRole/delete',
    method: 'post',
    data
  });
}

// 获取角色详情
export function getRoleDetailsData(data) {
  return request({
    url: '/pc/sysRole/detail',
    method: 'post',
    data
  });
}

// 获取用户列表
export function getUserList(data) {
  return request({
    url: '/pc/cmsUser/list',
    method: 'post',
    data
  });
}

// 导出学生
export function deriveStudentList(data) {
  return request({
    url: '/pc/cmsUser/export',
    method: 'post',
    responseType: 'blob',
    data
  });
}

// 大学管理 // 获取大学列表
export function getUniversityList(data) {
  return request({
    url: '/pc/cmsBkSchool/list',
    method: 'post',
    data
  });
}

// 大学管理 // 获取大学详情
export function getUniversityDetail(data) {
  return request({
    url: '/pc/cmsBkSchool/detail',
    method: 'post',
    data
  });
}

// 大学管理 // 新增大学
export function addUniversity(data) {
  return request({
    url: '/pc/cmsBkSchool/add',
    method: 'post',
    data
  });
}

// 大学管理 // 修改大学
export function updateUniversity(data) {
  return request({
    url: '/pc/cmsBkSchool/update',
    method: 'post',
    data
  });
}

// 大学管理 // 删除大学
export function deleteUniversity(data) {
  return request({
    url: '/pc/cmsBkSchool/delete',
    method: 'post',
    data
  });
}
