<template>
  <div
    class="schoolBigBox"
    :class="dataobj.logo ? 'uploadHidden' : ''"
    v-loading="loading"
  >
    <!-- 院校log -->
    <el-form
      :inline="true"
      :model="dataobj"
      label-width="118px"
      :disabled="pageType == 'details'"
    >
      <el-form-item label="院校LOGO：">
        <el-upload
          action=""
          list-type="picture-card"
          :http-request="uploadSectionFile"
          accept=".jpg,.jpeg"
          :limit="1"
          :multiple="false"
          :on-remove="handleRemove"
          :file-list="
            dataobj.logo ? [{ name: '院校logo', url: dataobj.logo }] : []
          "
        >
          <i class="el-icon-plus"></i>
          <div slot="tip" class="el-upload__tip" v-if="pageType != 'details'">
            只支持.jpg 格式
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
    <!--  -->
    <el-form
      :inline="true"
      :model="dataobj"
      label-width="118px"
      :disabled="pageType == 'details'"
    >
      <el-form-item label="院校名称：">
        <el-input
          v-model="dataobj.name"
          placeholder="请输入院校名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="院校英文名称：">
        <el-input
          v-model="dataobj.nameEn"
          placeholder="请输入院校英文名称："
        ></el-input>
      </el-form-item>
      <el-form-item label="地区：">
        <!-- <el-input v-model="dataobj.area" placeholder="请输入地区"></el-input> -->
        <el-select size="small" @change="onChange($event, 'areaList')" clearable v-model="dataobj.areaId" placeholder="请选择地区">
          <el-option v-for="(item, index) in areaList" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="院校经度：">
        <el-input
          v-model="dataobj.lng"
          placeholder="请输入院校经度"
        ></el-input>
      </el-form-item>
      <el-form-item label="院校纬度：">
        <el-input
          v-model="dataobj.lat"
          placeholder="请输入院校纬度"
        ></el-input>
      </el-form-item>
    </el-form>
    <!--  -->
    <el-form
      :inline="true"
      :model="dataobj"
      label-width="118px"
      :disabled="pageType == 'details'"
    >
    <el-form-item label="专业均分要求：">
        <el-input
          v-model="dataobj.score"
          placeholder="请输入专业均分要求"
        ></el-input>
      </el-form-item>
      <el-form-item label="QS排名：">
        <el-input
          v-model="dataobj.qsRank"
          placeholder="请输入QS排名"
        ></el-input>
      </el-form-item>
      <el-form-item label="U.S.News排名：">
        <el-input
          v-model="dataobj.usnewsRank"
          placeholder="请输U.S.News排名"
        ></el-input>
      </el-form-item>
    </el-form>
    <!--  -->
    <el-form
      :model="dataobj"
      label-width="118px"
      :disabled="pageType == 'details'"
    >
      <el-form-item label="简介：">
        <div>
          <el-input
            v-model="dataobj.content"
            type="textarea"
            placeholder="请输入简介"
          ></el-input>
        </div>
      </el-form-item>
    </el-form>
    <!--  -->
    <el-form
      :inline="true"
      :model="dataobj"
      label-width="118px"
      :disabled="pageType == 'details'"
    >
      <el-form-item label="中文地址：">
        <el-input
          v-model="dataobj.address"
          placeholder="请输入中文地址"
        ></el-input>
      </el-form-item>
      <el-form-item label="英文地址：">
        <el-input
          v-model="dataobj.addressEn"
          placeholder="请输英文地址"
        ></el-input>
      </el-form-item>
      <el-form-item label="链接：">
        <el-input
          v-model="dataobj.addressLink"
          placeholder="请输链接"
        ></el-input>
      </el-form-item>
    </el-form>
    <!--  -->
    <el-form
      :inline="true"
      :model="dataobj"
      label-width="118px"
      :disabled="pageType == 'details'"
    >
      <el-form-item label="气候：">
        <el-input v-model="dataobj.climate" placeholder="请输入气候"></el-input>
      </el-form-item>
      <el-form-item label="链接：">
        <el-input
          v-model="dataobj.climateLink"
          placeholder="请输入链接"
        ></el-input>
      </el-form-item>
    </el-form>
    <!--  -->
    <el-form
      :inline="true"
      :model="dataobj"
      label-width="118px"
      :disabled="pageType == 'details'"
    >
      <el-form-item label="安全指数：">
        <div style="display: flex; align-items: center; height: 40px">
          <el-rate v-model="dataobj.safetyIndex"></el-rate>
        </div>
      </el-form-item>
      <el-form-item label="链接：">
        <el-input
          v-model="dataobj.safetyIndexLink"
          placeholder="请输入链接"
        ></el-input>
      </el-form-item>
    </el-form>
    <!--  -->
    <el-form
      :inline="true"
      :model="dataobj"
      label-width="118px"
      :disabled="pageType == 'details'"
    >
      <el-form-item label="生活成本：">
        <el-input
          v-model="dataobj.cost"
          type="textarea"
          placeholder="请输入生活成本"
        ></el-input>
      </el-form-item>
      <el-form-item label="链接：">
        <el-input
          v-model="dataobj.costLink"
          placeholder="请输入链接"
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 提交按钮 -->
    <div
      class="submitButton"
      v-if="pageType != 'details'"
      @click="submitVerify"
    >
      {{ submitLoading ? '请稍候...' : '保存' }}
    </div>
  </div>
</template>
<script>
import { uploadFile, dicListByCode } from 'api/index.js';
import {
  getSchoolData,
  addSchoolData,
  updateSchoolData
} from 'api/schoolCollegeManagement.js';
export default {
  name: 'School',
  props: {
    pageType: String,
    detailId: {
      type: [String, Number]
    }
  },
  data() {
    return {
      dataobj: {},
      submitLoading: false,
      loading: false,
      areaList: [],
    };
  },
  watch: {
    detailId: {
      handler: function (newVal, oldVal) {
        if (!newVal) {
          this.dataobj = {};
        }
        if (newVal && newVal != oldVal) {
          this.getData();
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.getDictionariesList('ZXDQ', 'areaList');
  },
  methods: {
    // 移除已上传的院校logo
    handleRemove(file, fileList) {
      this.$set(this.dataobj, 'logo', '');
    },
    // 院校logo上传
    uploadSectionFile(file) {
      const isJPG = file.file.type === 'image/jpeg';
      const isPNG = file.file.type === 'image/png';
      const isLt2M = file.file.size / 1024 / 1024 < 2;
      console.log(isJPG, isPNG);
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG/JPEG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      // return isJPG || (isPNG && isLt2M);
      const fromData = new FormData();
      fromData.append('file', file.file);
      uploadFile(fromData).then((res) => {
        if (res.code == 2000) {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.$set(this.dataobj, 'logo', res.data);
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    // 待提交数据校验
    submitVerify() {
      const arr = [
        {
          key: 'logo',
          text: '请上传院校logo'
        },
        {
          key: 'name',
          text: '请输入院校名称'
        },
        {
          key: 'lng',
          text: '请输入院校经度'
        },
        {
          key: 'lat',
          text: '请输入院校纬度'
        },
        {
          key: 'nameEn',
          text: '请输入院校英文名称'
        },
        {
          key: 'area',
          text: '请输入地区'
        },
        {
          key: 'score',
          text: '请输入专业均分要求'
        },
        {
          key: 'qsRank',
          text: '请输入QS排名'
        },
        {
          key: 'usnewsRank',
          text: '请输入U.S.News排名'
        },
        {
          key: 'content',
          text: '请输入简介'
        },
        {
          key: 'address',
          text: '请输入中文地址'
        },
        {
          key: 'addressEn',
          text: '请输入英文地址'
        },
        {
          key: 'addressLink',
          text: '请输入地址链接'
        },
        {
          key: 'climate',
          text: '请输入气候'
        },
        {
          key: 'climateLink',
          text: '请输入气候链接'
        },
        {
          key: 'safetyIndex',
          text: '请输入安全指数'
        },
        {
          key: 'safetyIndexLink',
          text: '请输入安全指数链接'
        },
        {
          key: 'cost',
          text: '请输入生活成本'
        },
        {
          key: 'costLink',
          text: '请输入生活成本链接'
        }
      ];
      let flag = true;
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        if (!this.dataobj[item.key]) {
          flag = false;
          this.$message({
            type: 'warning',
            message: item.text
          });
          break;
        }
      }
      if (flag) {
        this.submitClick();
      }
    },
    // 提交数据
    submitClick() {
      if (this.submitLoading) {
        return;
      }
      let api = '';
      switch (this.pageType) {
        case 'add':
          api = addSchoolData;
          break;
        case 'update':
          api = updateSchoolData;
          break;
      }
      this.submitLoading = true;
      api(this.dataobj)
        .then((res) => {
          this.submitLoading = false;
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.$emit('toBack');
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.submitLoading = false;
        });
    },
    // 获取学校详情
    getData() {
      this.loading = true;
      getSchoolData({
        id: this.detailId
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 2000) {
            res.data.safetyIndex = res.data.safetyIndex
              ? Number(res.data.safetyIndex)
              : 0;
            this.dataobj = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getDictionariesList(code, key) {
      dicListByCode({
        code: code
      })
        .then(res => {
          if (res.code == 2000) {
            this[key] = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    onChange(value, res) {
        this.dataobj.area = value ? this.areaList.find(option => option.id === value).name : '';
    },
  }
};
</script>
<style lang="stylus" scoped>
.schoolBigBox {
  padding: 20px;
  box-sizing: border-box;
}

::v-deep .el-upload-list__item {
  transition: none !important;
}

.uploadHidden::v-deep .el-upload--picture-card {
  display: none !important; /* 上传按钮隐藏 */
}

.submitButton {
  width: 250px;
  height: 50px;
  background: #2797FC;
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #FFFFFF;
  margin: 30px;
  cursor: pointer;
}

.submitButton:active {
  background: #007ae8;
}
</style>