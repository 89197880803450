import request from '@utils/request';
// 案例库

// 获取案例列表
export function getCaseList(data) {
  return request({
    url: '/pc/cmsCaseInfo/list',
    method: 'post',
    data
  });
}

// 获取案例详情
export function getCaseData(data) {
  return request({
    url: '/pc/cmsCaseInfo/detail',
    method: 'post',
    data
  });
}

// 新增案例数据
export function addCaseData(data) {
  return request({
    url: '/pc/cmsCaseInfo/add',
    method: 'post',
    data
  });
}

// 修改案例数据
export function updateCaseData(data) {
  return request({
    url: '/pc/cmsCaseInfo/update',
    method: 'post',
    data
  });
}

// 删除案例数据
export function deleteCaselData(data) {
  return request({
    url: '/pc/cmsCaseInfo/delete',
    method: 'post',
    data
  });
}

