<template>
  <div class="menuManagementBigBox">
    <el-form :inline="true" :model="searchObj" class="demo-form-inline">
      <el-form-item label="菜单名称">
        <el-input v-model="searchObj.name" placeholder="菜单名称"></el-input>
      </el-form-item>
      <el-form-item label="归属系统">
        <el-select v-model="searchObj.systemId" placeholder="请选择归属系统">
          <el-option label="全部" :value="''"></el-option>
          <el-option
            v-for="item in systemList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" round @click="getList">查询</el-button>
        <el-button
          type="primary"
          size="small"
          style="background-color: #25c484; border: none"
          @click="addMenuButtonClick(false)"
          round
        >
          新增一级菜单
        </el-button>
      </el-form-item>
    </el-form>
    <el-tree
      :data="meunList"
      node-key="id"
      default-expand-all
      :expand-on-click-node="false"
      v-loading="loading"
    >
      <span class="custom-tree-node" slot-scope="{ data }">
        <span>{{ data.name }}</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="addMenuButtonClick(true, data)"
          >
            添加子菜单
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="updateMenuButtonClick(data)"
          >
            {{ data.updateloading ? '请稍候...' : '编辑' }}
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="deleteMenuButtonClick(data)"
          >
            删除
          </el-button>
        </span>
      </span>
    </el-tree>
    <!-- 新增、编辑菜单 -->
    <el-dialog
      :title="updateTitle"
      :visible.sync="updateShow"
      width="500px"
      :close-on-click-modal="false"
      :show-close="false"
      center
    >
      <div class="updateMenuBigBox">
        <el-form ref="form" :model="dataobj" label-width="160px">
          <el-form-item label="菜单名称">
            <el-input
              v-model="dataobj.name"
              placeholder="请输入菜单名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="菜单选未状态图片地址">
            <el-input
              v-model="dataobj.iconMask"
              placeholder="请输入菜单选未状态图片地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="菜单选中状态图片地址">
            <el-input
              v-model="dataobj.icon"
              placeholder="请输入菜单选中状态图片地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="菜单路由">
            <el-input
              v-model="dataobj.url"
              placeholder="请输入菜单路由"
            ></el-input>
          </el-form-item>
          <el-form-item label="归属系统">
            <el-select
              v-model="dataobj.systemId"
              placeholder="请选择归属系统"
              :disabled="updateType"
            >
              <el-option
                v-for="item in systemList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="submitClose">取 消</el-button>
        <el-button type="primary" @click="submitVerify">
          {{ submitLoading ? '请稍候...' : '确 定' }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getSystemData,
  getAllMenuData,
  addMenuData,
  updateMenuData,
  deleteMenuData,
  getMenuDetails
} from 'api/index.js';
export default {
  name: 'MenuManagement',
  data() {
    return {
      searchObj: {
        systemId: ''
      },
      systemList: [],
      meunList: [],
      loading: false,
      dataobj: {}, // 新增编辑菜单时当前编辑菜单数据
      updateShow: false,
      updateTitle: '',
      updateType: false,
      submitLoading: false
    };
  },
  methods: {
    getList() {
      this.loading = true;
      getAllMenuData({
        ifPage: false,
        ...this.searchObj
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 2000) {
            this.meunList = res.data.list || [];
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取系统列表
    getSystemList() {
      getSystemData({})
        .then((res) => {
          console.log(res);
          if (res.code == 2000) {
            this.systemList = res.data.list || [];
            if (!this.$store.state.aside.systemId) {
              this.$store.dispatch('setSystemId', this.systemList[0].id);
            }
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {});
    },
    // 新增菜单
    addMenuButtonClick(type = false, item = {}) {
      // type  false-主菜单   true-子菜单
      // item  父级菜单数据
      console.log(type, item);
      if (type) {
        this.dataobj.parentId = item.id;
        this.dataobj.systemId = item.systemId;
      }
      this.updateType = type;
      this.updateTitle = type ? '新增子菜单' : '新增一级菜单';
      this.updateShow = true;
    },
    // 关闭编辑弹窗
    submitClose() {
      this.updateShow = false;
      this.updateTitle = '';
      this.updateType = false;
      this.dataobj = {};
    },
    // 提交前数据校验
    submitVerify() {
      if (!this.dataobj.name) {
        this.$message({
          type: 'warning',
          message: '请输入菜单名称'
        });
        return false;
      }
      if (!this.dataobj.iconMask) {
        this.$message({
          type: 'warning',
          message: '请输入菜单未选中状态图片地址'
        });
        return false;
      }
      if (!this.dataobj.icon) {
        this.$message({
          type: 'warning',
          message: '请输入菜单选中状态图片地址'
        });
        return false;
      }
      if (!this.dataobj.url) {
        this.$message({
          type: 'warning',
          message: '请输入菜单路由'
        });
        return false;
      }
      if (!this.dataobj.systemId) {
        this.$message({
          type: 'warning',
          message: '请选择菜单归属系统'
        });
        return false;
      }
      this.submitMenuDataClick();
    },
    // 提交菜单数据
    submitMenuDataClick() {
      let api = '';
      if (this.dataobj.id) {
        api = updateMenuData;
      } else {
        api = addMenuData;
      }
      this.submitLoading = true;
      api(this.dataobj)
        .then((res) => {
          this.submitLoading = false;
          if (res.code == 2000) {
            this.submitClose();
            this.getList();
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.submitLoading = false;
        });
    },
    // 获取菜单详情并打开编辑弹窗
    updateMenuButtonClick(data) {
      if (data.updateloading) {
        return false;
      }
      this.$set(data, 'updateloading', true);
      getMenuDetails({
        id: data.id
      })
        .then((res) => {
          this.$set(data, 'updateloading', false);
          if (res.code == 2000) {
            this.dataobj = res.data;
            if (this.dataobj.parentId) {
              this.updateType = true;
            }
            this.updateTitle = '编辑菜单';
            this.updateShow = true;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.$set(data, 'updateloading', false);
        });
    },
    // 删除菜单
    deleteMenuButtonClick(data) {
      this.$confirm(`是否确认删除菜单 "${data.name}"?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteMenuData({
            id: data.id
          })
            .then((res) => {
              if (res.code == 2000) {
                this.getList();
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    }
  },
  created() {
    this.getList();
    this.getSystemList();
  }
};
</script>
<style lang="stylus" scoped>
.menuManagementBigBox {
  padding: 24px;
  box-sizing: border-box;
  background: #fff;

  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }

  .updateMenuBigBox {
  }
}
</style>