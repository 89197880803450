<template>
  <div>
    <div class="buttonBox">
      <el-button type="text" @click="toBack">返回上一页</el-button>
    </div>
  <div class="bigBox" :class="dataobj.picture ? 'uploadHidden' : ''" v-loading="loading">
    <el-form :inline="true" :model="dataobj" label-width="85px" :disabled="pageType == 'details'">
      <el-form-item label="标题：">
        <el-input style="width: 400px" size="small" v-model="dataobj.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="主类：">
        <el-select size="small" @change="onChange($event, 'mainClass')" clearable v-model="dataobj.mainClassId" placeholder="请选择主类">
          <el-option v-for="(item, index) in mainClass" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="子类：">
        <el-select size="small" @change="onChange($event, 'subClass')" clearable v-model="dataobj.sonClassId" placeholder="请选择子类">
          <el-option v-for="(item, index) in subClass" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-form :inline="true" :model="dataobj" label-width="85px" :disabled="pageType == 'details'">
      <el-form-item label="学校名称：">
        <el-select size="small" @change="onChange($event, 'schoolName')" clearable v-model="dataobj.schoolId" placeholder="请选择学校" filterable>
          <el-option v-for="(item, index) in schoollist" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="地区：">
        <el-select size="small" readonly @change="onChange($event, 'areaList')"  v-model="dataobj.areaId" placeholder="请选择地区">
          <el-option v-for="(item, index) in areaList" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排名：">
        <el-input size="small" type="number" readonly clearable v-model="dataobj.rank" placeholder=""></el-input>
      </el-form-item>

    </el-form>

    <el-form :inline="true" :model="dataobj" label-width="85px" :disabled="pageType == 'details'">
      <el-form-item label="图片：">
        <el-upload action="" list-type="picture-card" :http-request="uploadSectionFile" accept=".jpg,.jpeg" :limit="1" :multiple="false" :on-remove="handleRemove" :file-list="dataobj.picture ? [{ name: '上传图片', url: dataobj.picture }] : []">
          <i class="el-icon-plus"></i>
          <div slot="tip" class="el-upload__tip" v-if="pageType != 'details'">只支持.jpg 格式</div>
        </el-upload>
      </el-form-item>
    </el-form>
    <el-form :model="dataobj" label-width="85px" :disabled="pageType == 'details'">
      <el-form-item label="内容：">
        <el-input v-model="dataobj.content" type="textarea" placeholder="请输入内容"></el-input>
      </el-form-item>
    </el-form>
    <!-- 提交按钮 -->
    <div class="submitButton" v-if="pageType != 'details'" @click="submitVerify">
      {{ submitLoading ? '请稍候...' : '保存' }}
    </div>
  </div>
</div>
</template>
<script>
import { uploadFile, dicListByCode } from 'api/index.js';
import { getCollegeDetail, addCollegeInfo, updateCollegeInfo } from 'api/collegeInfo.js';
import {
  getSchoolList, getSchoolData
} from 'api/schoolCollegeManagement.js';
export default {
  name: 'CollegeInformationDetail',
  data() {
    return {
      mainClass: [],
      subClass: [],
      areaList: [],
      dataList: [],
      schoollist: [],
      pageType: '',
      detailId: '',
      dataobj: {},
      submitLoading: false,
      loading: false
    };
  },
  mounted() {
    this.pageType = this.$route.query.type;
    this.detailId = this.$route.query.id;
    if (this.pageType != 'add') {
      this.getData();
    }
    this.getDictionariesList('ZXZL', 'mainClass');
    this.getDictionariesList('ZXDQ', 'areaList');
    this.getDictionariesList('ZXSL', 'subClass');
    this.getschool();
  },
  methods: {
    onChange(value, res) {
      if (res == 'mainClass') {
        // 主类
        this.dataobj.mainClass = value ? this.mainClass.find(option => option.id === value).name : '';
      } else if (res == 'areaList') {
        // 地区
        this.dataobj.area = value ? this.areaList.find(option => option.id === value).name : '';
      } else if (res == 'schoolName') {
        this.dataobj.schoolName = value ? this.schoollist.find(option => option.id === value).name : '';
        this.getschoolDetail(this.dataobj.schoolId);
      } else {
        // 子类
        this.dataobj.sonClass = value ? this.subClass.find(option => option.id === value).name : '';
      }
      console.log(this.dataobj);
    },

    // 获取字典
    getDictionariesList(code, key) {
      dicListByCode({
        code: code
      })
        .then(res => {
          if (res.code == 2000) {
            this[key] = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleRemove(file, fileList) {
      this.$set(this.dataobj, 'picture', '');
    },
    uploadSectionFile(file) {
      const isJPG = file.file.type === 'image/jpeg';
      const isPNG = file.file.type === 'image/png';
      const isLt2M = file.file.size / 1024 / 1024 < 2;
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG/JPEG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      // return isJPG || (isPNG && isLt2M);
      const fromData = new FormData();
      fromData.append('file', file.file);
      uploadFile(fromData).then(res => {
        if (res.code == 2000) {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.$set(this.dataobj, 'picture', res.data);
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    // 待提交数据校验
    submitVerify() {
      const arr = [
        // {
        //   key: 'picture',
        //   text: '请上传照片'
        // }
      ];
      let flag = true;
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        if (!this.dataobj[item.key]) {
          flag = false;
          this.$message({
            type: 'warning',
            message: item.text
          });
          break;
        }
      }
      if (flag) {
        this.submitClick();
      }
    },
    // 提交数据
    submitClick() {
      if (this.submitLoading) {
        return;
      }
      let api = '';
      switch (this.pageType) {
        case 'add':
          api = addCollegeInfo;
          break;
        case 'update':
          api = updateCollegeInfo;
          break;
      }
      this.submitLoading = true;
      const params = {
        createId: JSON.parse(sessionStorage.getItem('patientMsg')).id,
        createName: JSON.parse(sessionStorage.getItem('patientMsg')).name,
        ...this.dataobj
      };
      api(params)
        .then(res => {
          this.submitLoading = false;
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.$router.go(-1);
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.submitLoading = false;
        });
    },
    // 获取学校详情
    getData() {
      this.loading = true;
      getCollegeDetail({
        id: this.detailId
      })
        .then(res => {
          this.loading = false;
          if (res.code == 2000) {
            res.data.safetyIndex = res.data.safetyIndex ? Number(res.data.safetyIndex) : 0;
            this.dataobj = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    toBack(flag) {
      this.$router.go(-1);
    },
    getschool() {
      this.loading = true;
      getSchoolList({
        ifPage: false,
        pageSize: 1000,
        pageNum: 1,
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 2000) {
            this.schoollist = res.data.list;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
          console.log(res);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getschoolDetail(ids) {
      this.loading = true;
      getSchoolData({
        id: ids
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 2000) {
            res.data.safetyIndex = res.data.safetyIndex
              ? Number(res.data.safetyIndex)
              : 0;
            this.dataobj.rank = res.data.qsRank;
            this.dataobj.areaId = res.data.areaId;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style lang="stylus" scoped>
.bigBox {
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
}

::v-deep .el-upload-list__item {
  transition: none !important;
}

.uploadHidden::v-deep .el-upload--picture-card {
  display: none !important; /* 上传按钮隐藏 */
}

.submitButton {
  width: 250px;
  height: 50px;
  background: #2797FC;
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #FFFFFF;
  margin: 30px;
  cursor: pointer;
}

.submitButton:active {
  background: #007ae8;
}
.buttonBox {
  padding: 0 15px;
  box-sizing: border-box;
  margin-bottom: 16px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

</style>