import request from '@utils/request';

// 列表
export function getCollegeList(data) {
  return request({
    url: '/pc/cmsCollegeInfo/list',
    method: 'post',
    data
  });
}
// 院校咨询详情
export function getCollegeDetail(data) {
  return request({
    url: '/pc/cmsCollegeInfo/detail',
    method: 'post',
    data
  });
}
// 新增院校咨询
export function addCollegeInfo(data) {
  return request({
    url: '/pc/cmsCollegeInfo/add',
    method: 'post',
    data
  });
}
// 修改院校咨询
export function updateCollegeInfo(data) {
  return request({
    url: '/pc/cmsCollegeInfo/update',
    method: 'post',
    data
  });
}
// 删除院校咨询
export function deleteCollegeInfo(data) {
  return request({
    url: '/pc/cmsCollegeInfo/delete',
    method: 'post',
    data
  });
}