<template>
  <div class="professionalDetailsBigBox" v-loading="loading">
    <!-- details title -->
    <div class="professionalDetailsTitleBox">
      <div class="professionalDetailsTitle">专业详情</div>
      <div class="professionalDetailsTitleButtonBox">
        <el-button type="text" @click="toBack(false)">返回上一页</el-button>
      </div>
    </div>
    <div class="professionalDetailsContentBox">
      <!--  -->
      <el-form
        :inline="true"
        :model="dataobj"
        :rules="rules"
        class="demo-form-inline"
        label-width="112px"
        label-position="right"
        :disabled="pageType == 'details'"
      >
        <el-form-item label="学校名称：" prop="schoolId">
          <el-select
            v-model="dataobj.schoolId"
            placeholder="请选择学校"
            @change="schoolChange"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in schoolList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="学院名称："
          v-if="dataobj.schoolId"
          prop="collegeId"
        >
          <el-select
            v-model="dataobj.collegeId"
            placeholder="请选择学院"
            @change="collegeChange"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in collegeList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业名称：" prop="name">
          <el-input
            v-model="dataobj.name"
            placeholder="请输入专业名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="专业英文名称">
          <el-input
            v-model="dataobj.nameEn"
            placeholder="请输入专业英文名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="专业QS排名：" prop="qsRank">
          <el-input
            v-model="dataobj.qsRank"
            placeholder="请输入专业QS排名"
          ></el-input>
        </el-form-item>
      </el-form>
      <!--  -->
      <el-form
        :model="dataobj"
        class="demo-form-inline"
        label-width="102px"
        label-position="right"
        :disabled="pageType == 'details'"
      >
        <el-form-item label="专业介绍：">
          <el-input
            type="textarea"
            :rows="{
              minRows: 2
            }"
            placeholder="请输入专业介绍"
            v-model="dataobj.content"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <!--  -->
      <el-form
        :model="dataobj"
        class="demo-form-inline"
        label-width="102px"
        label-position="right"
        :disabled="pageType == 'details'"
      >
        <el-form-item label="背景要求：">
          <el-input
            type="textarea"
            :rows="{
              minRows: 2
            }"
            placeholder="请输入背景要求"
            v-model="dataobj.majorRequire"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <!-- content title -->
      <div class="professionalDetailsContentTitle">专业相关信息</div>
      <!--  -->
      <el-form
        :inline="true"
        :model="dataobj"
        class="demo-form-inline"
        label-width="102px"
        label-position="right"
        :disabled="pageType == 'details'"
      >
        <el-form-item label="学制：">
          <el-input
            v-model="dataobj.eduSystem"
            placeholder="请输入学制"
          ></el-input>
        </el-form-item>
        <el-form-item label="链接：">
          <el-input
            v-model="dataobj.eduSystemLink"
            placeholder="请输入链接"
          ></el-input>
        </el-form-item>
        <el-form-item label="入学月份：">
          <el-input
            v-model="dataobj.enrollmentMonth"
            placeholder="请输入入学月份"
          ></el-input>
        </el-form-item>
        <el-form-item label="链接：">
          <el-input
            v-model="dataobj.enrollmentMonthLink"
            placeholder="请输入链接"
          ></el-input>
        </el-form-item>
      </el-form>
      <!--  -->
      <el-form
        :inline="true"
        :model="dataobj"
        class="demo-form-inline"
        label-width="102px"
        label-position="right"
        :disabled="pageType == 'details'"
      >
        <!-- <el-form-item label="入学月份：">
          <el-input
            v-model="dataobj.user"
            placeholder="请输入入学月份"
          ></el-input>
        </el-form-item>
        <el-form-item label="链接：">
          <el-input v-model="dataobj.user" placeholder="请输入链接"></el-input>
        </el-form-item> -->
        <el-form-item label="学费：">
          <el-input
            v-model="dataobj.tuition"
            placeholder="请输入学费"
          ></el-input>
        </el-form-item>
        <el-form-item label="链接：">
          <el-input
            v-model="dataobj.tuitionLink"
            placeholder="请输入链接"
          ></el-input>
        </el-form-item>
      </el-form>
      <!--  -->
      <el-form
        :inline="true"
        :model="dataobj"
        class="demo-form-inline"
        label-width="102px"
        label-position="right"
        :disabled="pageType == 'details'"
      >
        <el-form-item label="工作经验：">
          <el-input
            type="textarea"
            :rows="{
              minRows: 2
            }"
            placeholder="请输入工作经验"
            v-model="dataobj.workExperience"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="面/笔试要求：">
          <el-input
            type="textarea"
            :rows="{
              minRows: 2
            }"
            placeholder="请输入面/笔试要求"
            v-model="dataobj.testRequire"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <!--  -->
      <el-form
        :inline="true"
        :model="dataobj"
        class="demo-form-inline"
        label-width="102px"
        label-position="right"
        :disabled="pageType == 'details'"
      >
        <el-form-item label="雅思要求：">
          <el-input
            v-model="dataobj.ielts"
            placeholder="请输入雅思要求"
          ></el-input>
        </el-form-item>
        <el-form-item label="链接：">
          <el-input
            v-model="dataobj.ieltsLink"
            placeholder="请输入链接"
          ></el-input>
        </el-form-item>
        <el-form-item label="托福要求：">
          <el-input
            v-model="dataobj.toefl"
            placeholder="请输入托福要求"
          ></el-input>
        </el-form-item>
        <el-form-item label="链接：">
          <el-input
            v-model="dataobj.toeflLink"
            placeholder="请输入链接"
          ></el-input>
        </el-form-item>
      </el-form>
      <!--  -->
      <el-form
        :inline="true"
        :model="dataobj"
        class="demo-form-inline"
        label-width="102px"
        label-position="right"
        :disabled="pageType == 'details'"
      >
        <el-form-item label="多邻国：">
          <el-input
            v-model="dataobj.duolingo"
            placeholder="请输入多邻国"
          ></el-input>
        </el-form-item>
        <el-form-item label="链接：">
          <el-input
            v-model="dataobj.duolingoLink"
            placeholder="请输入链接"
          ></el-input>
        </el-form-item>
        <el-form-item label="语言要求：">
          <el-input
            v-model="dataobj.majorLanguage"
            placeholder="请输入语言要求"
          ></el-input>
        </el-form-item>
      </el-form>
      <!--  -->
      <el-form
        :inline="true"
        :model="dataobj"
        class="demo-form-inline"
        label-width="102px"
        label-position="right"
        :disabled="pageType == 'details'"
      >
        <el-form-item label="GMAT:">
          <el-input v-model="dataobj.gmat" placeholder="请输入GMAT"></el-input>
        </el-form-item>
        <el-form-item label="链接：">
          <el-input
            v-model="dataobj.gmatLink"
            placeholder="请输入链接"
          ></el-input>
        </el-form-item>
        <el-form-item label="GRE:">
          <el-input v-model="dataobj.gre" placeholder="请输入GRE"></el-input>
        </el-form-item>
        <el-form-item label="链接：">
          <el-input
            v-model="dataobj.greLink"
            placeholder="请输入链接"
          ></el-input>
        </el-form-item>
      </el-form>
      <!--  -->
      <el-form
        :inline="true"
        :model="dataobj"
        class="demo-form-inline"
        label-width="102px"
        label-position="right"
        :disabled="pageType == 'details'"
      >
        <el-form-item label="作品集：">
          <el-input
            type="textarea"
            :rows="{
              minRows: 2
            }"
            placeholder="请输入作品集"
            v-model="dataobj.sampleReels"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="细分方向：">
          <el-input
            type="textarea"
            :rows="{
              minRows: 2
            }"
            placeholder="请输入细分方向"
            v-model="dataobj.subdivision"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <!--  -->
      <el-form
        :inline="true"
        :model="dataobj"
        class="demo-form-inline"
        label-width="122px"
        label-position="right"
        :disabled="pageType == 'details'"
      >
        <el-form-item label="申请开始时间：">
          <el-date-picker
            v-model="dataobj.timeOn"
            type="datetime"
            placeholder="请选择申请开始时间"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="申请截止时间：">
          <el-date-picker
            v-model="dataobj.timeOff"
            type="datetime"
            placeholder="请选择申请截止时间"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="链接：">
          <el-input
            v-model="dataobj.timeOnLink"
            placeholder="请输入链接"
          ></el-input>
        </el-form-item>
      </el-form>
      <!--  -->
      <el-form
        :model="dataobj"
        class="demo-form-inline"
        label-width="102px"
        label-position="right"
        :disabled="pageType == 'details'"
      >
        <el-form-item label="课程：">
          <el-table :data="dataobj.curriculums" stripe style="width: 100%">
            <el-table-column
              prop="name"
              label="课程名称(Curriculum)"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.name"
                  placeholder="请输入课程名称"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="curriculumLink" label="链接" align="center">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.curriculumLink"
                  placeholder="请输入链接"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="移除"
              width="55"
              align="center"
              v-if="pageType != 'details'"
            >
              <template slot-scope="scope">
                <i
                  class="el-icon-remove"
                  style="color: #2797ff; cursor: pointer"
                  @click="deleteCurriculums(scope.$index)"
                ></i>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <div class="addbuttonBox">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-plus"
          v-if="pageType != 'details'"
          plain
          round
          @click="addCurriculumsClick"
        >
          添加一行
        </el-button>
      </div>
      <div class="submitButtonBox">
        <el-button
          type="primary"
          style="width: 250px"
          round
          v-if="pageType != 'details'"
          @click="submitVerify"
        >
          {{ submitLoading ? '请稍候...' : '保存' }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getSchoolList, getCollegeList } from 'api/schoolCollegeManagement.js';
import {
  getProfessionalDetails,
  addProfessionalData,
  updateProfessionalData
} from 'api/professionalManagement.js';
export default {
  name: 'ProfessionalDetails',
  data() {
    return {
      dataobj: {},
      loading: false,
      schoolList: [],
      collegeList: [],
      rules: {
        schoolId: [
          { required: true, message: '请选择学校名称', trigger: 'blur' }
        ],
        collegeId: [
          { required: true, message: '请选择学院名称', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请输入专业名称', trigger: 'blur' }],
        qsRank: [
          { required: true, message: '请输入专业QS排名', trigger: 'blur' }
        ]
      },
      submitLoading: false
    };
  },
  props: {
    pageType: String,
    detailsId: {
      type: [String, Number]
    }
  },
  watch: {
    detailsId: {
      handler: function (newVal, oldVal) {
        if (!newVal) {
          this.dataobj = {};
        }
        if (newVal && newVal != oldVal) {
          this.getData();
        }
      },
      immediate: true
    }
  },
  methods: {
    getData() {
      this.loading = true;
      getProfessionalDetails({
        id: this.detailsId
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 2000) {
            this.getCollegeData(res.data.schoolId);
            console.log(res.data);
            this.dataobj = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取学校列表
    getSchoolData() {
      getSchoolList({
        ifPage: false
      })
        .then((res) => {
          if (res.code == 2000) {
            this.schoolList = res.data.list;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
          console.log(res);
        })
        .catch(() => {});
    },
    // 获取学院列表
    getCollegeData(schoolId) {
      getCollegeList({
        ifPage: false,
        schoolId: schoolId
      })
        .then((res) => {
          if (res.code == 2000) {
            this.collegeList = res.data.list;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {});
    },
    // 返回上一页
    toBack(refresh = false) {
      this.$emit('toBack', refresh);
    },
    // 选中学校
    schoolChange(id) {
      for (let i = 0; i < this.schoolList.length; i++) {
        const item = this.schoolList[i];
        if (id == item.id) {
          this.dataobj.schoolName = item.name;
          this.getCollegeData(item.id);
          break;
        }
      }
      this.dataobj.collegeId = '';
      this.dataobj.collegeName = '';
    },
    // 选中学院
    collegeChange(id) {
      console.log(id);
      for (let i = 0; i < this.collegeList.length; i++) {
        const item = this.collegeList[i];
        if (id == item.id) {
          this.dataobj.collegeName = item.name;
          this.$forceUpdate();
          break;
        }
      }
    },
    // 增加一条课程数据
    addCurriculumsClick() {
      const curriculums = this.dataobj.curriculums || [];
      curriculums.push({
        name: '',
        curriculumLink: ''
      });
      this.$set(this.dataobj, 'curriculums', curriculums);
    },
    // 删除一条课程数据
    deleteCurriculums(index) {
      this.$delete(this.dataobj.curriculums, index);
    },
    // 提交数据校验
    submitVerify() {
      if (this.submitLoading) {
        return;
      }
      if (!this.dataobj.schoolId) {
        this.$message({
          type: 'warning',
          message: '请选择学校'
        });
        return;
      }
      if (!this.dataobj.collegeId) {
        this.$message({
          type: 'warning',
          message: '请选择学院'
        });
        return;
      }
      if (!this.dataobj.name) {
        this.$message({
          type: 'warning',
          message: '请输入专业名称'
        });
        return;
      }
      if (!this.dataobj.qsRank) {
        this.$message({
          type: 'warning',
          message: '请输入专业QS排名'
        });
        return;
      }
      this.submitClick();
    },
    // 提交数据
    submitClick() {
      let api = '';
      switch (this.pageType) {
        case 'add':
          api = addProfessionalData;
          break;
        case 'update':
          api = updateProfessionalData;
          break;
      }
      this.submitLoading = true;
      const deepCopyOfDataobj = JSON.parse(JSON.stringify(this.dataobj));
      this.convertTimesToTimestamp(deepCopyOfDataobj);
      api(deepCopyOfDataobj)
        .then((res) => {
          this.submitLoading = false;
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.toBack(true);
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.submitLoading = false;
        });
    },

    convertTimesToTimestamp(data) {
      const timeFields = ['timeOff', 'timeOn']; // 可以根据需要增加其他时间字段
      timeFields.forEach((field) => {
        if (data[field]) {
          const date = new Date(data[field]);
          if (!isNaN(date.getTime())) {
            data[field] = date.getTime();
          } else {
            // 处理不合法日期，例如设置为null或默认时间戳
            console.error(`Invalid date for field ${field}: ${data[field]}`);
            data[field] = null; // 或者使用其他默认值
          }
        }
      });
    }
  },
  created() {
    this.getSchoolData();
  }
};
</script>
<style lang="stylus" scoped>
.professionalDetailsBigBox {
  background: #ffffff;

  .professionalDetailsTitleBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #D9DBDE;

    .professionalDetailsTitle {
      font-size: 16px;
      color: #333333;
    }
  }

  .professionalDetailsContentBox {
    padding: 20px;
    box-sizing: border-box;
  }

  .professionalDetailsContentTitle {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    margin-bottom: 20px;
  }

  .addbuttonBox {
    display: flex;
    justify-content: flex-end;
    padding: 14px 0;
    box-sizing: border-box;
  }

  .submitButtonBox {
    padding: 20px 32px 45px;
    box-sizing: border-box;
  }
}
</style>