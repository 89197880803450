import request from '@utils/request';

// 获取QA列表
export function getQAList(data) {
  return request({
    url: '/pc/cmsQa/list',
    method: 'post',
    data
  });
}

// 获取QA详情
export function getQAData(data) {
  return request({
    url: '/pc/cmsQa/detail',
    method: 'post',
    data
  });
}

// 新增QA
export function addQAData(data) {
  return request({
    url: '/pc/cmsQa/add',
    method: 'post',
    data
  });
}

// 编辑QA
export function updateQAData(data) {
  return request({
    url: '/pc/cmsQa/update',
    method: 'post',
    data
  });
}

// 删除QA
export function deleteQAData(data) {
  return request({
    url: '/pc/cmsQa/delete',
    method: 'post',
    data
  });
}