<template>
  <div>
    <div class="QAmanagementBigBox" v-if="!pageType">
      <el-form :inline="true" :model="searchObj" class="demo-form-inline">
        <el-form-item label="类型">
          <el-select v-model="searchObj.type" placeholder="请选择类型">
            <el-option label="全部" :value="''"> </el-option>
            <el-option
              v-for="item in typeList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="问题">
          <el-input
            v-model="searchObj.question"
            placeholder="请输入问题关键字"
          ></el-input>
        </el-form-item>
        <el-form-item label="时间范围">
          <el-date-picker
            v-model="searchObj.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" round @click="pageclick(1)">
            查询
          </el-button>
          <el-button
            type="success"
            size="small"
            style="background-color: #25c484; border: none"
            @click="addClick(null, 'add')"
            round
          >
            添加
          </el-button>
        </el-form-item>
      </el-form>
      <el-table :data="list" stripe style="width: 100%">
        <el-table-column type="index" label="序号" width="55" align="center">
        </el-table-column>
        <el-table-column prop="type" label="类型" width="120" align="center">
          <template slot-scope="scope">
            {{ getTypeName(scope.row.type) }}
          </template>
        </el-table-column>
        <el-table-column prop="question" label="问题" align="center">
        </el-table-column>
        <el-table-column
          prop="createName"
          label="创建人"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="addClick(scope.row, 'detail')">
              查看详情
            </el-button>
            <el-button type="text" @click="addClick(scope.row, 'update')">
              编辑
            </el-button>
            <el-button
              type="text"
              style="color: #f56c6c"
              @click="deleteClick(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <paging
        :pageall="pageall"
        :pagenum="pagenum"
        @pageclick="pageclick"
      ></paging>
    </div>
    <QAdetails
      v-if="pageType"
      :pageType="pageType"
      :detailObj="detailObj"
      :typeList="typeList"
      @toBack="toBack"
    ></QAdetails>
  </div>
</template>
<script>
import paging from 'com/paging.vue';
import QAdetails from './components/QAdetails.vue';
import { getQAList, deleteQAData } from 'api/QAmanagement.js';
export default {
  name: 'QAmanagement',
  data() {
    return {
      pageType: '',
      detailObj: {},
      typeList: [
        {
          code: 1,
          name: '关于系统'
        },
        {
          code: 2,
          name: '院校匹配'
        }
      ],
      searchObj: {},
      pagenum: 1,
      pageall: 0,
      loading: false,
      list: []
    };
  },
  methods: {
    // 点击分页
    pageclick(val) {
      this.pagenum = val;
      this.getList();
    },
    // 获取列表
    getList() {
      this.loading = true;
      getQAList({
        type: this.searchObj.type,
        question: this.searchObj.question,
        startDate:
          this.searchObj.time && this.searchObj.time[0]
            ? this.searchObj.time[0]
            : '',
        endDate:
          this.searchObj.time && this.searchObj.time[1]
            ? this.searchObj.time[1]
            : '',
        pageNum: this.pagenum,
        pageSize: 10
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 2000) {
            this.list = res.data.list || [];
            this.pageall = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取类型名称
    getTypeName(code) {
      for (let i = 0; i < this.typeList.length; i++) {
        if (code == this.typeList[i].code) {
          return this.typeList[i].name;
        }
      }
    },
    // 点击新增、编辑、详情
    addClick(item, type) {
      if (type != 'add') {
        this.detailObj = item;
      }
      this.pageType = type;
    },
    toBack(isRefresh) {
      if (isRefresh) {
        this.getList();
      }
      this.pageType = '';
      this.detailObj = {};
    },
    // 删除QA问题
    deleteClick(item) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteQAData({
            id: item.id
          })
            .then((res) => {
              if (res.code == 2000) {
                this.$message({
                  type: 'success',
                  message: '操作成功'
                });
                if (this.list.length == 1 && this.pagenum > 1) {
                  this.pagenum--;
                }
                this.getList();
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                });
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    }
  },
  created() {
    this.getList();
  },
  components: {
    paging,
    QAdetails
  }
};
</script>
<style lang="stylus" scoped></style>