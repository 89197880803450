<template>
  <div class="CollegeBigBox">
    <el-form :inline="true" :model="searchObj" class="demo-form-inline">
      <el-form-item label="学院名称：">
        <el-input
          v-model="searchObj.name"
          placeholder="请输入学院名称"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" round @click="pageclick(1)">
          查询
        </el-button>
        <el-button
          type="primary"
          size="mini"
          style="background-color: #25c484; border: none"
          @click="addButtonClick"
          round
        >
          新增学院
        </el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" stripe style="width: 100%" v-loading="loading">
      <el-table-column type="index" label="序号" width="100" align="center">
      </el-table-column>
      <el-table-column prop="name" label="学院名称" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scoped">
          <el-button type="text" @click="deleteCollegeClick(scoped.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <paging
      :pageall="pageall"
      :pagenum="pagenum"
      @pageclick="pageclick"
    ></paging>
  </div>
</template>
<script>
import paging from 'com/paging.vue';
import {
  getCollegeList,
  addCollegeData,
  deleteCollegeData
} from 'api/schoolCollegeManagement.js';
export default {
  name: 'College',
  props: {
    pageType: String,
    detailId: {
      type: [String, Number]
    }
  },
  watch: {
    detailId: {
      handler: function (newVal, oldVal) {
        if (!newVal) {
          this.list = [];
        } else if (newVal != oldVal) {
          this.getList();
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      searchObj: {},
      pagenum: 1,
      pageall: 0,
      loading: false,
      list: []
    };
  },
  methods: {
    pageclick(val) {
      this.pagenum = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      getCollegeList({
        ifPage: true,
        pageSize: 10,
        pageNum: this.pagenum,
        schoolId: this.detailId,
        ...this.searchObj
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 2000) {
            this.list = res.data.list;
            this.pageall = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    addButtonClick() {
      this.$prompt('请输入学院名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\S.*/,
        inputErrorMessage: '请输入学院名称'
      })
        .then(({ value }) => {
          this.loading = true;
          addCollegeData({
            name: value,
            schoolId: this.detailId
          })
            .then((res) => {
              this.loading = false;
              if (res.code == 2000) {
                this.getList();
                this.$message({
                  type: 'success',
                  message: '操作成功'
                });
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                });
              }
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    deleteCollegeClick(item) {
      this.$confirm(`确定删除学院"${item.name}"?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      })
        .then(() => {
          this.loading = true;
          deleteCollegeData({
            schoolId: this.detailId,
            id: item.id
          })
            .then((res) => {
              this.loading = false;
              if (res.code == 2000) {
                this.$message({
                  type: 'success',
                  message: '操作成功'
                });
                this.getList();
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                });
              }
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {});
    }
  },
  components: {
    paging
  }
};
</script>
<style lang="stylus" scoped>
.CollegeBigBox {
  padding: 24px;
  box-sizing: border-box;
}
</style>