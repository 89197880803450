<template>
  <div class="customerFollowBox" v-loading="loading">
    <headerTitle title="客户跟进"></headerTitle>
    <div class="customerFollow">
      <!-- 搜索框 -->
      <customerFollowSearch :taskType="taskType" :resourceStatus="resourceStatus" :followUpStatus="followUpStatus" :searchObj="searchObj" @searchClick="pageclick(1)"></customerFollowSearch>
      <!-- 表格 -->
      <el-table :data="dataList" stripe style="width: 100%">
        <el-table-column prop="" label="" align="center">
          <template slot-scope="scope">
            <img v-if="scope.row.checkFlag == 0" src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/smallBell.png" alt="" />
            <img v-else src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/smallBell1.png" alt="" />
          </template>
        </el-table-column>
        <!--  -->
        <el-table-column :index="indexMethod" type="index" label="序号" align="center">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column prop="taskTypeValue" label="任务类型" align="center"></el-table-column>
        <el-table-column prop="customTypeValue" label="资源状态" align="center"></el-table-column>
        <el-table-column prop="followUpStatusValue" label="跟进状态" align="center"></el-table-column>
        <el-table-column prop="customName" label="客户名称" align="center"></el-table-column>
        <el-table-column prop="customPhone" label="手机号" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row && scope.row.customPhone">
              {{ $utils.mobileFilter(scope.row.customPhone) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="revisitTime" label="提醒下次回访时间" align="center"></el-table-column>
        <!-- <el-table-column prop="isDelete" label="是否有效" align="center">
          <template slot-scope="scope">
            <span :style="`${scope.row.status === '1' ? 'color:red' : scope.row.status === '0' ? 'color: rgb(0, 255, 106)' : ''}`">
              {{ scope.row.status === '0' ? '有效' : scope.row.status === '1' ? '无效' : '-' }}
            </span>
          </template>
        </el-table-column> -->
        <el-table-column fixed="right" prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="createRecordClick(scope.row)">新建跟进记录</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <paging :pagesize="pageSize" :pageall="pageall" :pagenum="pagenum" @pageclick="pageclick"></paging>
    </div>
  </div>
</template>
<script>
import { countByCheckFlagAndTeacherId, getTaskList } from 'api/customerManagement.js';
import dictionary from '@/utils/dictionary.js';
import paging from '../../../components/paging.vue';
import headerTitle from '../../../components/title.vue';
import customerFollowSearch from './component/customerFollowSearch.vue';
export default {
  name: 'CustomerFollow',
  components: {
    headerTitle,
    customerFollowSearch,
    paging
  },
  data() {
    return {
      loading: false,
      searchObj: {
        followUpStatus: this.$route.query.code || ''
      },
      pageall: 0,
      pagenum: 1,
      pageSize: 10,
      dataList: [],
      followUpStatus: dictionary.followUpStatus,
      resourceStatus: dictionary.resourceStatus,
      taskType: dictionary.taskType
    };
  },
  watch: {},
  computed: {},
  mounted() {
    this.getList();
  },
  methods: {
    getNotice() {
      this.loading = true;
      countByCheckFlagAndTeacherId().then(res => {
        this.loading = false;
        if (res.code == '2000') {
          if (res.data > 0) {
            this.$message({
              dangerouslyUseHTMLString: true,
              type: 'warning',
              iconClass: 'el-message--warning',
              customClass: 'myClass',
              duration: 5000,
              message: `<div><img style="margin-right:10px" src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/smallBell.png">您有${res.data}条新的客户记录待跟进！</div>`
            });
          }
          this.getList();
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
          this.getList();
        }
      });
    },
    getList() {
      const data = {
        ifPage: true,
        pageSize: this.pageSize,
        pageNum: this.pagenum,
        ...this.searchObj
      };
      this.loading = true;
      getTaskList(data).then(res => {
        this.loading = false;
        if (res.code == '2000') {
          this.pageall = res.data.total || 0;
          this.dataList = res.data.list || [];
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    createRecordClick(item) {
      this.$router.push({
        path: '/customerFollowDetail',
        query: {
          customId: item.customId,
          id: item.id
        }
      });
    },
    pageclick(val) {
      this.pagenum = val;
      this.getList();
    },
    indexMethod(index) {
      return index + 1 + (this.pagenum - 1) * this.pageSize;
    }
  }
};
</script>
<style lang="stylus">
.customerFollow {
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
}

.myClass {
  color: #FF8646;
  border: solid 1px #FD8646;
  display: flex;
  align-items: center;
  background: #FFF8F4;
}
</style>