<template>
  <div>
    <div class="buttonBox">
      <el-button type="text" @click="toBack">返回上一页</el-button>
    </div>
    <div class="caseBox">
      <p class="title">新增案例</p>
      <div class="caseinfo">
        <p class="title" style="padding: 0 0 5px 0;">目标院校信息</p>
        <el-form :model="form" :rules="rules" ref="form" inline label-position="left">
          <el-form-item label="录取结果:" prop="caseType">
            <el-select v-model="form.caseType" :disabled="type=='details'" placeholder="请选择" size="medium"  @change="handleChange(CASEList,'caseType','caseTypeId')">
              <el-option
                v-for="item in CASEList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年份：" prop="year">
            <el-date-picker
              v-model="form.year"
              type="year"
              placeholder="选择年份"
              size="medium"
              value-format="yyyy"
              :disabled="type=='details'"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="地区："  prop="areaId">
            <el-select
              v-model="form.areaId"
              placeholder="请选择"
              clearable
              size="medium"
              :disabled="type=='details'"
              @change="handleChange(ZXDQList,'area','areaId')"
            >
              <el-option
                v-for="item in ZXDQList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                size="medium"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="目标院校名称："  prop="targetSchoolName">
            <el-select
              v-model="form.targetSchoolName"
              placeholder="请选择"
              clearable
              filterable
              :disabled="type=='details'"
              size="medium"
            >
              <el-option
                v-for="item in schoollist"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="专业名称：" prop="targetCurriculumName">
            <el-input
              v-model="form.targetCurriculumName"
              placeholder="请输入"
              size="medium"
              :disabled="type=='details'"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="录取结果："  prop="resultsId">
            <el-select
              v-model="form.resultsId"
              placeholder="请选择"
              clearable
              :disabled="type=='details'"
              size="medium"
               @change="handleChange(LQJGList,'results','resultsId')"
            >
              <el-option
                v-for="item in LQJGList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                size="medium"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="录取时间："  prop="time">
            <el-date-picker
              v-model="form.time"
              type="date"
              placeholder="选择日期"
              size="medium"
              :disabled="type=='details'"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="奖学金：" prop="scholarship">
            <el-input
              v-model="form.scholarship"
              placeholder="请输入"
              size="medium"
              :disabled="type=='details'"
            ></el-input>
          </el-form-item>
          <el-form-item label="获取结果时间：" prop="resultsTime">
            <el-date-picker
              v-model="form.resultsTime"
              type="date"
              placeholder="选择日期"
              size="medium"
              :disabled="type=='details'"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="目标院校等级："  prop="targetSchoolLevel">
            <el-select
              v-model="form.targetSchoolLevel"
              placeholder="请选择"
              clearable
              size="medium"
              :disabled="type=='details'"
            >
              <el-option
                v-for="item in LEVELList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <p class="title" style="padding: 0 0 5px 0;">学生院校信息</p>
          <el-form-item label="院校名：" prop="schoolName">
            <el-input
              v-model="form.schoolName"
              placeholder="请输入"
              size="medium"
              :disabled="type=='details'"
            ></el-input>
          </el-form-item>
          <el-form-item label="院校等级："  prop="level">
            <el-select
              v-model="form.level"
              placeholder="请选择"
              clearable
              size="medium"
              :disabled="type=='details'"
              @change="handleChange(LEVELList,'level','levelId')"
            >
              <el-option
                v-for="item in LEVELList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="专业：" prop="majorName">
            <el-input
              v-model="form.majorName"
              placeholder="请输入"
              size="medium"
              :disabled="type=='details'"
            ></el-input>
          </el-form-item>
          <el-form-item label="最高学历：" prop="highestEducationLevel">
            <el-input
              v-model="form.highestEducationLevel"
              placeholder="请输入"
              size="medium"
              :disabled="type=='details'"
            ></el-input>
          </el-form-item>
          <el-form-item label="成绩：" prop="score">
            <el-input
              v-model="form.score"
              placeholder="请输入"
              size="medium"
              :disabled="type=='details'"
            ></el-input>
          </el-form-item>
          <el-form-item label="英语成绩：" prop="scoreEn">
            <el-input
              v-model="form.scoreEn"
              placeholder="请输入"
              size="medium"
              :disabled="type=='details'"
            ></el-input>
          </el-form-item>
          <el-form-item label="申请季：" prop="applyTime">
            <el-input
              v-model="form.applyTime"
              placeholder="请输入"
              size="medium"
              :disabled="type=='details'"
            ></el-input>
          </el-form-item>
          <el-form-item label="工作经历：" class="checkBox">
            <el-input type="textarea" :disabled="type=='details'" rows="4" resize="none" v-model="form.workExperience"></el-input>
          </el-form-item>
          <el-form-item label="备注：" class="checkBox">
            <el-input type="textarea" :disabled="type=='details'" rows="4" resize="none" v-model="form.remark"></el-input>
          </el-form-item>
        </el-form>
        <p class="btn" @click="save('form')" v-if="type!='details'">保存</p>
      </div>
    </div>
  </div>
</template>

<script>
import {
    addCaseData, getCaseData, updateCaseData
} from 'api/casebaseManagement.js';
import { dicListByCode } from 'api/index.js';
import {
  getSchoolList,
} from 'api/schoolCollegeManagement.js';
export default {
  data() {
    return {
      form: {
        caseTypeId: '',
        caseType: '',
        year: '',
        areaId: '',
        area: "",
        schoolId: '',
        resultsId: '',
        time: '',
        scholarship: '',
        resultsTime: '',
        targetCollegeName: '',
        level: '',
        levelId: '',
        majorName: '',
        score: '',
        scoreEn: '',
        workExperience: '',
        remark: '',
        highestEducationLevel: "",
        applyTime: '',
        targetCurriculumName: '',
        targetSchoolName: '',
        targetSchoolLevel: '',

    },
      schoollist: [],
      dicCodelist: ['LEVEL', 'ZXDQ', 'LQJG'],
      CASEList: [
        { id: 0, name: '通过' },
        { id: 1, name: '未通过' }
      ],
      LEVELList: [],
      ZXDQList: [],
      LQJGList: [],
      loading: false,
      type: this.$route.query.type,
      rules: {
        caseTypeId: [{ required: true, message: '请选择', trigger: 'blur' }],
        year: [{ required: true, message: '请选择', trigger: 'blur' }],
        areaId: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolId: [{ required: true, message: '请选择', trigger: 'change' }],
        resultsId: [{ required: true, message: '请选择', trigger: 'change' }],
        time: [{ required: true, message: '请选择', trigger: 'change' }],
        scholarship: [{ required: true, message: '请输入', trigger: 'blur' }],
        resultsTime: [{ required: true, message: '请选择', trigger: 'change' }],
        highestEducationLevel: [{ required: true, message: '请输入', trigger: 'blur' }],
        applyTime: [{ required: true, message: '请输入', trigger: 'blur' }],
        workExperience: [{ required: true, message: '请输入', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入', trigger: 'blur' }],
        targetCollegeName: [{ required: true, message: '请输入', trigger: 'blur' }],
        targetCurriculumName: [{ required: true, message: '请输入', trigger: 'blur' }],
        targetSchoolName: [{ required: true, message: '请输入', trigger: 'blur' }],
        level: [{ required: true, message: '请选择', trigger: 'change' }],
        targetSchoolLevel: [{ required: true, message: '请选择', trigger: 'change' }],
        majorName: [{ required: true, message: '请输入', trigger: 'blur' }],
        score: [{ required: true, message: '请输入', trigger: 'blur' }],
        scoreEn: [{ required: true, message: '请输入', trigger: 'blur' }],
        schoolName: [{ required: true, message: '请输入', trigger: 'blur' }],

      }

    };
  },
  mounted() {
    this.getList();
    this.getCodeList();
    console.log(this.$route.query.type);
    if (this.$route.query.type == 'details' || this.$route.query.type == 'update') {
        this.getDetail(this.$route.query.id);
    }
  },
  methods: {
    toBack(flag) {
      this.$router.go(-1);
    },
    // convertToTimestamp(datetime) {
    //   const date = new Date(datetime);
    //   if (isNaN(date.getTime())) {
    //     return;
    //   }
    //   return date.getTime();
    // },
    getCodeList() {
        this.dicCodelist.forEach((item) => {
            dicListByCode({ code: item }).then(res => {
            if (res.code == '2000') {
                this[item + 'List'] = res.data;
            } else {
              this.$message({
                type: 'error',
                message: res.msg
                });
            }
          });
        });
    },
    // 目标院校列表
    getList() {
      this.loading = true;
      getSchoolList({
        ifPage: false,
        pageSize: 1000,
        pageNum: 1,
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 2000) {
            this.schoollist = res.data.list;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
          console.log(res);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleChange(list, field, field2) {
        const item = list.find(item => item.id === this.form[field2]);
        if (item) {
            this.form[field] = item.name;
        }
    },
    save(ruleForm) {
        this.$refs[ruleForm].validate((valid) => {
            if (valid) {
                const data = { ...this.form };
                this.convertTimesToTimestamp(data);
                const saveMethod = this.type == 'update' ? updateCaseData : addCaseData;
                saveMethod(data).then((res) => {
                    if (res.code == 2000) {
                    this.$message({
                        type: "success",
                        message: res.msg,
                    });
                    this.$router.go(-1);
                    } else {
                    this.$message({
                        type: "error",
                        message: res.msg,
                    });
                    }
                });
         } else {

         }
    });
    },
    getDetail(id) {
        getCaseData({ id: id }).then((res) => {
            if (res.code == 2000) {
                this.form = res.data;
                console.log(this.form);
            } else {
                this.$message({
                    type: "error",
                    message: res.msg,
                });
            }
        });
    },
    convertTimesToTimestamp(data) {
      const timeFields = ['time', 'resultsTime']; // 可以根据需要增加其他时间字段
      timeFields.forEach(field => {
        if (data[field]) {
          const date = new Date(data[field]);
          if (!isNaN(date.getTime())) {
            data[field] = date.getTime();
          } else {
            // 处理不合法日期，例如设置为null或默认时间戳
            console.error(`Invalid date for field ${field}: ${data[field]}`);
            data[field] = null; // 或者使用其他默认值
          }
        }
      });
    },
 },
};
</script>

<style lang="stylus" scoped>
.buttonBox {
  padding: 0 15px;
  box-sizing: border-box;
  margin-bottom: 16px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.caseBox {
  background-color: #fff;
  border-radius: 10px;
  padding-bottom: 45px;
}
.caseinfo{
    padding: 0 32px;
}
.title {
  font-size: 16px;
  padding: 15px 20px;
  color: #333333;
  border-bottom: 1px solid #D9DBDE;
  margin-bottom: 20px;
  font-weight: bold;
}
.btn{
    width: 250px;
    height: 50px;
    background: #2797FC;
    border-radius: 25px 25px 25px 25px;
    color: #fff;
    line-height: 50px;
    text-align: center;
    cursor pointer;
}
.checkBox{
    display: flex;
    width:100%;
    >>>.el-form-item__label{
        width:120px;
    }
    >>>.el-form-item__content{
        width: calc(100% - 132px);
    }
}
.el-form--inline .el-form-item{
    margin-right: 32px
}
>>>.el-select .el-input__inner{
    width: 165px;
}
>>>.el-input--medium .el-input__inner{
    width: 165px;
}
>>>.el-date-editor.el-input{
    width: 165px;
}
</style>