<template>
  <div class="collegeInformationBox" v-loading="loading">
    <headerTitle title="超哥资讯库"></headerTitle>
    <div class="collegeInformation">
      <!-- 搜索框 -->
      <search
        :areaList="areaList"
        :mainClass="mainClass"
        :subClass="subClass"
        :searchObj="searchObj"
        @searchClick="pageclick(1)"
        @addClick="addClick"
        @importClick="importClick"
      ></search>
      <!-- 表格 -->
      <el-table ref="multipleTable" :data="dataList" stripe style="width: 100%">
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="标题"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="mainClass"
          label="主类"
          align="center"
        ></el-table-column>
        <!-- <el-table-column
          prop="area"
          label="地区"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="rank"
          label="排名"
          align="center"
          width="80"
        ></el-table-column> -->
        <el-table-column
          prop="sonClass"
          label="子类"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="schoolName"
          label="学校名称"
          align="center"
        ></el-table-column>
        <el-table-column
          fixed="right"
          prop="address"
          label="操作"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="addClick('details', scope.row)">
              查看详情
            </el-button>
            <el-button type="text" @click="addClick('update', scope.row)">
              编辑
            </el-button>
            <el-button type="text" @click="delClick(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <paging
        :pagesize="pageSize"
        :pageall="pageall"
        :pagenum="pagenum"
        @pageclick="pageclick"
      ></paging>
      <!-- 批量导入弹窗 -->
      <el-dialog
        title="上传目录"
        :visible.sync="importVisible"
        width="500px"
        :close-on-click-modal="false"
        center
      >
        <div>
          <el-upload
            class="upload-demo"
            drag
            :multiple="false"
            :show-file-list="false"
            :http-request="toChannelRequest"
            accept=".xls, .xlsx"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text" v-if="!toImportName">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
            <div class="el-upload__text" v-else>{{ toImportName }}</div>
            <div class="el-upload__tip" slot="tip">
              <div
                style="
                  margin-top: 8px;
                  color: #ff4141;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                仅支持Excel文件
                <a
                  style="color: #2797ff"
                  href="https://file.chaogexitongban.net/others/app/8fdfec6b480ce771a9d15322b98d2d83.xlsx"
                  download="客户信息录入导入模板"
                  >下载模板</a
                >
              </div>
            </div>
          </el-upload>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            round
            @click="toImportClose"
            style="width: 48%; border-radius: 25px"
          >
            取 消
          </el-button>
          <el-button
            round
            style="width: 48%; border-radius: 25px"
            type="primary"
            :disabled="toImportLoading"
            @click="sureImport"
          >
            {{ toImportLoading ? '请稍候...' : '确 定' }}
          </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { dicListByCode } from 'api/index.js';
import { uploadExcel } from 'api/customerManagement.js';
import { getCollegeList, deleteCollegeInfo } from 'api/collegeInfo.js';
import paging from '@/components/paging.vue';
import headerTitle from '@/components/title.vue';
import search from './component/search.vue';
export default {
  name: 'CollegeInformation',
  components: {
    headerTitle,
    paging,
    search
  },
  data() {
    return {
      searchObj: {},
      pageall: 0,
      pagenum: 1,
      pageSize: 10,
      subClass: [],
      mainClass: [],
      areaList: [],
      dataList: [],
      loading: false,
      importVisible: false,
      toImportLoading: false,
      formData: '',
      toImportName: ''
    };
  },
  mounted() {
    this.getDictionariesList('ZXZL', 'mainClass');
    this.getDictionariesList('ZXDQ', 'areaList');
    this.getDictionariesList('ZXSL', 'subClass');
    this.pageclick(1);
  },
  methods: {
    delClick(val) {
      this.$confirm('确认删除此条超哥资讯吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteCollegeInfo({
          id: val.id
        }).then((res) => {
          if (res.code == '2000') {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.pageclick(1);
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        });
      });
    },
    // 批量导入
    importClick() {
      this.importVisible = true;
    },
    // 关闭导入弹窗
    toImportClose() {
      this.toImportName = '';
      this.formData = '';
      this.importVisible = false;
    },
    // 导入
    toChannelRequest(files) {
      const file = files.file;
      this.toImportName = file.name;
      this.formData = new FormData();
      this.formData.set('file', file);
    },
    sureImport() {
      if (!this.formData) {
        this.$message({
          type: 'warning',
          message: '请选择导入文件'
        });
        return;
      }
      this.$set(this, 'toImportLoading', true);
      uploadExcel(this.formData)
        .then((res) => {
          this.$set(this, 'toImportLoading', false);
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: '操作成功！'
            });
            this.pageclick(1);
            this.importVisible = false;
            this.toImportClose();
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$set(this, 'toImportLoading', false);
        });
    },
    // 新增、编辑、详情
    addClick(type, item) {
      const dialogType = type ? type : 'add';
      const id = item ? item.id + '' : '';
      let query = {};
      if (dialogType != 'add') {
        query = {
          type: dialogType,
          id: id
        };
      } else {
        query = {
          type: dialogType
        };
      }
      this.$router.push({
        path: '/collegeInformationDetail',
        query: query
      });
    },
    pageclick(val) {
      this.pagenum = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      const data = {
        ifPage: true,
        pageSize: this.pageSize,
        pageNum: this.pagenum,
        ...this.searchObj
      };
      getCollegeList(data).then((res) => {
        this.loading = false;
        if (res.code == '2000') {
          this.pageall = res.data.total || 0;
          this.dataList = res.data.list || [];
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    indexMethod(index) {
      return index + 1 + (this.pagenum - 1) * this.pageSize;
    },
    // 获取字典
    getDictionariesList(code, key) {
      dicListByCode({
        code: code
      })
        .then((res) => {
          if (res.code == 2000) {
            this[key] = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="stylus" scoped>
.collegeInformation {
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
}

>>> .el-table__header th, >>> .el-table__header tr {
  background: #EBF5FF !important;
  font-size: 14px !important;
  color: #202224 !important;
}

>>> .el-table__body th, >>> .el-table__body tr {
  background: #fff !important;
  font-size: 14px !important;
  color: #202224 !important;
}

>>> .el-table__body tr .el-table__cell {
  padding: 8px 0 !important;
}

>>> .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #F5FAFF !important;
}

>>>.el-dialog {
  border-radius: 16px;
}

>>>.el-upload, >>>.el-upload-dragger {
  width: 100%;
}
</style>