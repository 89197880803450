<template>
  <div class="caseBox">
    <div>
      <div class="searchBox">
        <el-form :inline="true" :model="searchObj" class="demo-form-inline">
          <el-form-item label="录取结果：">
            <el-select
              v-model="searchObj.caseType"
              placeholder="请选择"
              clearable
              @change="pageclick(1)"
              size="medium"
            >
              <el-option
                v-for="item in CASEList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年份筛选：">
            <el-date-picker
              v-model="searchObj.year"
              type="year"
              placeholder="选择年份"
              @change="pageclick(1)"
              size="medium"
              value-format="yyyy"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="院校等级：">
            <el-select
              v-model="searchObj.level"
              placeholder="请选择"
              clearable
              @change="pageclick(1)"
              size="medium"
            >
              <el-option
                v-for="item in LEVELList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="申请季：">
            <el-input
              v-model="searchObj.applyTime"
              placeholder="请输入"
              size="medium"
            ></el-input>
          </el-form-item>
          <el-form-item label="目标院校：">
            <el-input
              v-model="searchObj.targetSchoolName"
              placeholder="请输入"
              size="medium"
            ></el-input>
          </el-form-item>
          <el-form-item label="专业名称：">
            <el-input
              v-model="searchObj.majorName"
              placeholder="请输入"
              size="medium"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" round @click="pageclick(1)">
              查询
            </el-button>
            <el-button
              type="primary"
              size="mini"
              round
              style="background-color: #25c484; border: none"
              @click="toDetails('', 'add')"
            >
              添加
            </el-button>
            <el-button
              type="primary"
              size="mini"
              style="background-color: #25c484; border: none"
              round
            >
              批量导入
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="list" stripe style="width: 100%" v-loading="loading">
        <el-table-column
          type="index"
          label="序号"
          :index="indexMethod"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="caseType" label="录取结果" align="center">
          <template slot-scope="scoped">
            <span v-if="scoped.row.caseType == 0">通过</span>
            <span v-if="scoped.row.caseType == 1">未通过</span>
          </template>
        </el-table-column>
        <el-table-column prop="year" label="年份" align="center">
        </el-table-column>
        <el-table-column prop="area" label="地区" align="center">
        </el-table-column>
        <el-table-column prop="level" label="院校等级" align="center">
        </el-table-column>
        <el-table-column prop="applyTime" label="申请季" align="center">
        </el-table-column>
        <el-table-column
          prop="targetSchoolName"
          label="目标院校"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="majorName" label="专业名称" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template slot-scope="scoped">
            <el-button type="text" @click="toDetails(scoped.row, 'details')">
              查看详情
            </el-button>
            <el-button type="text" @click="toDetails(scoped.row, 'update')">
              编辑
            </el-button>
            <el-button type="text" @click="deleteData(scoped.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <paging
        :pageall="pageall"
        :pagenum="pagenum"
        @pageclick="pageclick"
      ></paging>
    </div>
  </div>
</template>
<script>
import paging from 'com/paging.vue';

import { getCaseList, deleteCaselData } from 'api/casebaseManagement.js';
import { dicListByCode } from 'api/index.js';
export default {
  name: 'Casebase',
  data() {
    return {
      searchObj: {},
      pagenum: 1,
      pageall: 0,
      pageSize: 10,
      loading: false,
      list: [],
      pageType: '',
      detailId: '',
      dicCodelist: ['LEVEL'],
      CASEList: [
        {
          id: '',
          name: '全部'
        },
        {
          id: 0,
          name: '通过'
        },
        {
          id: 1,
          name: '未通过'
        }
      ],
      LEVELList: []
    };
  },
  methods: {
    // 点击分页
    pageclick(val) {
      this.pagenum = val;
      this.getList();
    },
    // 获取列表数据
    getList() {
      this.loading = true;
      getCaseList({
        ifPage: true,
        pageSize: this.pageSize,
        pageNum: this.pagenum,
        ...this.searchObj
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 2000) {
            this.list = res.data.list;
            this.pageall = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
          console.log(res);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 跳转详情
    toDetails(item, type) {
      this.$router.push({
        path: '/casebaseDetail',
        query: {
          type: type,
          id: item.id || ''
        }
      });
    },
    //
    toBack(refresh) {
      this.pageType = '';
      this.detailId = '';
      if (refresh) {
        this.getList();
      }
    },
    // 删除数据
    deleteData(item) {
      this.$confirm(`是否确认删除案例?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      })
        .then(() => {
          this.loading = true;
          deleteCaselData({
            id: item.id
          })
            .then((res) => {
              this.loading = false;
              if (res.code == 2000) {
                this.$message({
                  type: 'success',
                  message: '操作成功'
                });
                this.getList();
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                });
              }
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        })
        .catch(() => {});
    },
    indexMethod(index) {
      return index + 1 + (this.pagenum - 1) * this.pageSize;
    },
    getCodeList() {
      this.dicCodelist.forEach((item) => {
        dicListByCode({ code: item }).then((res) => {
          if (res.code == '2000') {
            this[item + 'List'] = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        });
      });
    }
  },
  mounted() {
    this.getCodeList();
  },
  created() {
    this.getList();
  },
  components: {
    paging
  }
};
</script>
<style lang="stylus" scoped>
.caseBox {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
}
</style>