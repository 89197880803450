import { render, staticRenderFns } from "./schoolCollegeManagementList.vue?vue&type=template&id=d8b9188c&scoped=true"
import script from "./schoolCollegeManagementList.vue?vue&type=script&lang=js"
export * from "./schoolCollegeManagementList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8b9188c",
  null
  
)

export default component.exports