import request from '@utils/request';

// 专业管理 // 获取专业列表
export const getProfessionalList = (data) => {
  return request({
    url: 'pc/cmsMajor/list',
    method: 'post',
    data
  });
};

// 专业管理 // 获取专业详情
export const getProfessionalDetails = (data) => {
  return request({
    url: 'pc/cmsMajor/detail',
    method: 'post',
    data
  });
};

// 专业管理 // 新增专业
export const addProfessionalData = (data) => {
  return request({
    url: 'pc/cmsMajor/add',
    method: 'post',
    data
  });
};

// 专业管理 // 编辑专业
export const updateProfessionalData = (data) => {
  return request({
    url: 'pc/cmsMajor/update',
    method: 'post',
    data
  });
};

// 专业管理 // 删除专业
export const deleteProfessionalData = (data) => {
  return request({
    url: 'pc/cmsMajor/delete',
    method: 'post',
    data
  });
};