// 小工具
// 获取随机字符串   默认长度16
export const getRandomString = (e = 16, str = '') => {
  var t = str ? str : "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz1234567890";
  var a = t.length;
  var n = "";
  for (let i = 0; i < e; i++) {
    n += t.charAt(Math.floor(Math.random() * a));
  };
  return n;
};

// 时间格式化
export const formatDate = (date, format) => {
  if (!date) return '';
  if (typeof date == 'number' || typeof date == 'string') {
    date = new Date(date);
  };
  const time = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
    "q+": Math.floor((date.getMonth() + 3) / 3),
    "S+": date.getMilliseconds()
  };
  if (/(y+)/i.test(format)) {
    format = format.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (const k in time) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? time[k] : ("00" + time[k]).substr(("" + time[k]).length)
      );
    }
  }
  return format;
};

// 下载文件
export const downloadFile = (fileUrl, name, isBlob = false) => {
  // fileUrl  文件流或文件地址
  // 获取文件blob
  function getBlob(url) {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        }
      };
      xhr.send();
    });
  }

  function saveAs(blob, filename) {
    var link = document.createElement('a');
    link.download = filename;
    link.style.display = 'none';
    link.href = window.URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link.href); // 释放URL 对象
    document.body.removeChild(link);
  }

  return new Promise((resolve, reject) => {
    try {
      if (isBlob) {
        const blob = fileUrl;
        saveAs(blob, `${name || new Date().getTime()}.xlsx`);
        resolve({
          code: 2000,
          msg: '成功'
        });
      } else {
        getBlob(fileUrl).then(blob => {
          console.log(blob);
          saveAs(blob, `${name || new Date().getTime()}.xlsx`);
          resolve({
            code: 2000,
            msg: '成功'
          });
        });
      }
    } catch (error) {
      reject({
        code: '5000',
        data: error,
        msg: '失败'
      });
    }
  });
};
