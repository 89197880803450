<template>
  <div>
    <div class="schoolBigBox" v-if="!pageType">
      <el-form :inline="true" :model="searchObj" class="demo-form-inline">
        <el-form-item label="平台名称">
          <el-select
            v-model="searchObj.rankPlatform"
            placeholder="请选择平台名称"
          >
            <el-option label="全部" :value="''"></el-option>
            <el-option
              :label="item.name"
              :value="item.code"
              v-for="(item, index) in platformNameList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年份">
          <el-select v-model="searchObj.rankYear" placeholder="请选择年份">
            <el-option label="全部" :value="''"></el-option>
            <el-option
              :label="item"
              :value="item"
              v-for="item in yearList"
              :key="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="国家">
          <el-input
            v-model="searchObj.area"
            placeholder="请输入国家"
          ></el-input>
        </el-form-item>
        <el-form-item label="学校名称">
          <el-input
            v-model="searchObj.schoolName"
            placeholder="请输入学校名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" round @click="pageclick(1)">
            查询
          </el-button>
          <el-button
            type="success"
            size="small"
            style="background-color: #25c484; border: none"
            @click="addschoolClick"
            round
          >
            新增
          </el-button>
        </el-form-item>
      </el-form>
      <el-table :data="list" stripe style="width: 100%" v-loading="loading">
        <el-table-column type="index" label="序号" width="55" align="center">
        </el-table-column>
        <el-table-column prop="rankPlatform" label="平台名称" align="center">
          <template slot-scope="scope">
            {{ getListPlatformName(scope.row.rankPlatform) }}
          </template>
        </el-table-column>
        <el-table-column prop="rankYear" label="年份" align="center">
        </el-table-column>
        <el-table-column prop="area" label="国家" align="center">
        </el-table-column>
        <el-table-column prop="rankNo" label="排名" align="center">
        </el-table-column>
        <el-table-column prop="schoolName" label="学校名称" align="center">
        </el-table-column>
        <el-table-column prop="countMajor" label="收录专业数" align="center">
        </el-table-column>
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="updateButtonClick(scope.row)">
              编辑
            </el-button>
            <el-button
              type="text"
              style="color: #f56c6c"
              @click="deleteButtonClick(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <paging
        :pageall="pageall"
        :pagenum="pagenum"
        @pageclick="pageclick"
      ></paging>
    </div>
    <addschool
      v-if="pageType == 'add'"
      :yearList="yearList"
      :platformNameList="platformNameList"
      @toBack="toBack"
    ></addschool>
    <el-dialog
      title="编辑"
      :visible="pageType == 'update'"
      width="600px"
      :show-close="false"
      center
    >
      <div>
        <el-form
          :inline="true"
          :model="dataobj"
          class="demo-form-inline"
          disabled
        >
          <el-form-item label="平台名称">
            <el-input
              :value="getListPlatformName(dataobj.rankPlatform)"
              placeholder="平台名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="年份">
            <el-input v-model="dataobj.rankYear" placeholder="年份"></el-input>
          </el-form-item>
        </el-form>
        <!-- <el-form
          :inline="true"
          :model="dataobj"
          class="demo-form-inline"
          disabled
        >
          <el-form-item label="国家">
            <el-input v-model="dataobj.user" placeholder="国家"></el-input>
          </el-form-item>
          <el-form-item label="收录专业数">
            <el-input
              v-model="dataobj.user"
              placeholder="收录专业数"
            ></el-input>
          </el-form-item>
        </el-form> -->
        <el-form :inline="true" :model="dataobj" class="demo-form-inline">
          <el-form-item label="学校名称">
            <el-select
              v-model="dataobj.schoolName"
              filterable
              remote
              reserve-keyword
              placeholder="请输入学校名称"
              :remote-method="remoteMethod"
              :loading="schoolLoading"
              @change="schoolChange"
            >
              <el-option
                v-for="item in schoolList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排名">
            <el-input
              type="number"
              v-model="dataobj.rankNo"
              placeholder="排名"
              :min="1"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateClose">取 消</el-button>
        <el-button type="primary" @click="updateSubmit"> 确 定 </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import paging from 'com/paging.vue';
import addschool from './addschool.vue';
import { dicListByCode } from 'api/index.js';
import { getSchoolList } from 'api/schoolCollegeManagement.js';
import {
  getRankingSchoolList,
  deleteSchoolRanking,
  getSchoolRankingDetails,
  updateSchoolRankingSubmit
} from 'api/rankingManagement';
export default {
  name: 'School',
  data() {
    return {
      searchObj: {},
      yearList: [],
      pagenum: 1,
      pageall: 0,
      loading: false,
      platformNameList: [], // 平台名称列表
      list: [],
      pageType: '',
      dataobj: {},
      schoolList: [],
      schoolLoading: false
    };
  },
  methods: {
    // 点击分页
    pageclick(val) {
      this.pagenum = val;
      this.getList();
    },
    // 生成年份
    getYearList() {
      this.yearList = [];
      const yearStart = 2022;
      const yearEnd = new Date().getFullYear();
      for (let i = yearEnd; i >= yearStart; i--) {
        this.yearList.push(i);
      }
    },
    // 获取平台名称列表
    getPTMCList() {
      dicListByCode({ code: 'PTMC', status: 1 })
        .then((res) => {
          if (res.code == '2000') {
            this.platformNameList = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {});
    },
    // 获取列表数据
    getList() {
      this.loading = true;
      getRankingSchoolList({
        ifPage: true,
        pageSize: 10,
        pageNum: this.pagenum,
        ...this.searchObj
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 2000) {
            this.list = res.data.list || [];
            this.pageall = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 返回平台名称
    getListPlatformName(code) {
      for (let i = 0; i < this.platformNameList.length; i++) {
        if (code == this.platformNameList[i].code) {
          return this.platformNameList[i].name;
        }
      }
    },
    // 点击新增按钮
    addschoolClick() {
      this.pageType = 'add';
    },
    //
    toBack(isRefresh) {
      this.pageType = '';
      if (isRefresh) {
        this.pageclick(1);
      }
    },
    // 点击编辑按钮
    updateButtonClick(item) {
      getSchoolRankingDetails({
        id: item.id
      })
        .then((res) => {
          if (res.code == 2000) {
            this.dataobj = res.data;
            this.pageType = 'update';
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {});
    },
    // 点击删除按钮
    deleteButtonClick(item) {
      this.$confirm(
        `是否确认删除${this.getListPlatformName(item.rankPlatform)}-${
          item.rankYear
        }-${item.schoolName}?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          deleteSchoolRanking({
            id: item.id
          })
            .then((res) => {
              if (res.code == 2000) {
                this.$message({
                  type: 'success',
                  message: '操作成功'
                });
                if (this.list.length == 1 && this.pagenum > 1) {
                  this.pagenum--;
                }
                this.getList();
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                });
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    // 提交修改
    updateSubmit() {
      updateSchoolRankingSubmit(this.dataobj)
        .then((res) => {
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.pageType = '';
            this.dataobj = {};
            this.getList();
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {});
    },
    //
    updateClose() {
      this.pageType = '';
      this.dataobj = {};
    },
    // 搜索学校
    remoteMethod(name) {
      if (name) {
        this.schoolLoading = true;
        getSchoolList({
          name: name
        })
          .then((res) => {
            console.log(res);
            this.schoolLoading = false;
            if (res.code == 2000) {
              this.schoolList = res.data.list || [];
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          })
          .catch(() => {
            this.schoolLoading = false;
          });
      } else {
        this.schoolList = [];
      }
    },
    // 选中学校
    schoolChange(name) {
      for (let i = 0; i < this.schoolList.length; i++) {
        if (name == this.schoolList[i].name) {
          // item.area = this.schoolList[i].area;
          this.dataobj.schoolId = this.schoolList[i].id;
          // item.specialtyNumber = this.schoolList[i].countMajor;
          break;
        }
      }
    }
  },
  created() {
    this.getYearList();
    this.getPTMCList();
    this.getList();
  },
  components: {
    paging,
    addschool
  }
};
</script>
<style lang="stylus" scoped></style>