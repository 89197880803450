<template>
  <div style="background: #fff" v-loading="loading">
    <div class="headerBox">
      订单详情
      <div class="headerButtonBox">
        <el-button size="small" @click="toBack">返回上一页</el-button>
      </div>
    </div>
    <!--  -->
    <div class="GPABigBox">
      <div class="GPATitleBox">订单信息</div>
      <div class="GPATextBox">
        <div class="GPAText">订单编号: {{ dataobj.orderNo }}</div>
        <div class="GPAText">订单名称: {{ dataobj.orderName }}</div>
        <div class="GPAText">订单金额: {{ dataobj.orderAmount || '-' }}元</div>
        <div class="GPAText">用户昵称: {{ dataobj.orderUserName }}</div>
        <div class="GPAText">身份: {{ dataobj.identity }}</div>
        <div class="GPAText">手机号: {{ dataobj.orderUserMobile }}</div>
        <div class="GPAText">下单时间: {{ dataobj.createTime }}</div>
        <div class="GPAText">状态: {{ dataobj.orderStatusName }}</div>
      </div>
    </div>
    <!--  -->
    <div class="GPABigBox">
      <div class="GPATitleBox">订单内容</div>
      <div class="GPAContentBox">
        <div
          class="GPAContentImageBox"
          v-if="dataobj.gpaScoreFile"
          @click="downLoadFileClick(dataobj.gpaScoreFile)"
        >
          <div class="GPAImageBox">
            <img class="GPAImage" src="@/assets/img/xlsxIcon.png" alt="" />
          </div>
          <div class="GPAImageText">GPA计算模板.xlsx</div>
        </div>
        <el-table
          :data="dataobj.gpaScore || []"
          stripe
          style="width: 100%"
          v-else
        >
          <el-table-column prop="date" label="" width="70" align="center">
            <template slot-scope="scope"> 课程{{ scope.$index + 1 }} </template>
          </el-table-column>
          <el-table-column prop="course" label="课程名称" align="center">
          </el-table-column>
          <el-table-column prop="score" label="课程成绩" align="center">
          </el-table-column>
          <el-table-column prop="credit" label="课程学分" align="center">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--  -->
    <div class="GPABigBox">
      <div class="GPATitleBox">回复内容</div>
      <div class="GPAContentBox">
        <el-input
          type="textarea"
          :autosize="{ minRows: 6 }"
          placeholder="请输入内容"
          v-model="dataobj.replyContent"
          resize="none"
          :disabled="dataobj.orderStatus == 1 ? false : true"
        ></el-input>
      </div>
    </div>
    <!--  -->
    <div class="GPABigBox">
      <div class="GPATitleBox">GPA分数</div>
      <div class="GPAContentBox">
        <el-input
          v-model="dataobj.gpaResult"
          placeholder="请输入GPA分数"
          :disabled="dataobj.orderStatus == 1 ? false : true"
          :min="0"
        ></el-input>
      </div>
    </div>
    <div class="buttonBox" v-if="dataobj.orderStatus == 1">
      <el-button
        type="primary"
        :loading="submitLoading"
        @click="submitGPAReplyClick"
      >
        {{ submitLoading ? '请稍候...' : '完成订单' }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { getGPADetails, submitGPAReply } from 'api/GPAManagement.js';
import { downloadFile } from '@utils/smallTools/index.js';
export default {
  name: 'GPADetails',
  props: {
    detailObj: Object
  },
  watch: {
    detailObj: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal.id) {
          this.getData();
        } else {
          this.dataobj = {};
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      dataobj: {},
      loading: false,
      submitLoading: false
    };
  },
  methods: {
    toBack(isRefresh = false) {
      this.$emit('toBack', isRefresh);
    },
    getData() {
      this.loading = true;
      getGPADetails({ id: this.detailObj.id })
        .then((res) => {
          this.loading = false;
          if (res.code == 2000) {
            if (res.data.gpaScore) {
              res.data.gpaScore = JSON.parse(res.data.gpaScore);
            }
            this.dataobj = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 下载文件
    downLoadFileClick(url) {
      downloadFile(url)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 提交
    submitGPAReplyClick() {
      if (!this.dataobj.replyContent) {
        this.$message({
          type: 'warning',
          message: '请输入回复内容'
        });
        return;
      }
      if (!this.dataobj.gpaResult) {
        this.$message({
          type: 'warning',
          message: '请输入GPA分数'
        });
        return;
      }

      this.submitLoading = true;
      submitGPAReply({
        id: this.detailObj.id,
        replyContent: this.dataobj.replyContent
      })
        .then((res) => {
          this.submitLoading = false;
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.toBack(true);
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.submitLoading = false;
        });
    }
  }
};
</script>
<style lang="stylus" scoped>
.headerBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  box-sizing: border-box;
  border-bottom: 1px solid #cccccc;
}

.GPABigBox {
  padding: 24px;
  box-sizing: border-box;

  .GPATitleBox {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 14px;
  }

  .GPATextBox {
    display: flex;
    flex-wrap: wrap;

    .GPAText {
      font-size: 14px;
      margin-bottom: 20px;
      margin-right: 60px;
    }
  }

  .GPAContentImageBox {
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .GPAImageBox {
      width: 64px;
      margin-bottom: 12px;

      .GPAImage {
        width: 100%;
      }
    }

    .GPAImageText {
      font-size: 12px;
    }
  }
}

.buttonBox {
  padding: 24px;
  box-sizing: border-box;
}
</style>