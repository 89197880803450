import request from '@utils/request';

// 导入学校
export function importSchool(data) {
  return request({
    url: '/pc/cmsSchool/importSchool',
    method: 'post',
    data
  }, false);
}

// 导入大学
export function importUniversity(data) {
  return request({
    url: '/pc/cmsBkSchool/importSchool',
    method: 'post',
    data
  }, false);
}
