import '@utils/localCaching.js'; // 重写localStorage,sessionStorage    必须放在第一行在使用前重写完成否则会导致某些地方在使用时取不到正确的值
import Vue from 'vue';
import App from './App.vue';
import forbidden from '@utils/forbidden';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import permission from '@utils/permission';
import checkPer from '@utils/permission/checkPer.js';
import aes from '@utils/encryptedFile/aes.js';
import '@/assets/iconfont/iconfont.js';
import '@/assets/css/common.css'; // 公共样式
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import 'normalize.css/normalize.css';
import CommonTools from '@/utils/commonTools.js';
import Print from '@/assets/print';
import filters from '@/utils/filters';
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
import axios from 'axios';
import Quill from 'vue-quill-editor';
import echarts from 'echarts';

Vue.prototype.$axios = axios;
window.Quill = Quill.Quill;
window.install = Quill.install;
window.quillEditor = Quill.quillEditor;
Vue.prototype.$echarts = echarts;

Vue.use(Print);

// 添加监听-在正式环境打开控制台会执行死循环卡死浏览器
forbidden();
Vue.use(Viewer);
for (const filter in filters) {
  Vue.filter(filter, filters[filter]);
}
// 权限验证
Vue.use(permission);
Vue.use(checkPer);

Vue.use(ElementUI);
Vue.prototype.$aes = aes;
Vue.config.productionTip = false;
Vue.prototype.$utils = CommonTools;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
