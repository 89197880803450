<template>
  <div class="processManagementSearch searchBigBox">
    <div class="searchBox">
      <!--  -->
      <div class="inputBigBox">
        <div class="inputText">流程名称：</div>
        <el-input class="input" placeholder="请输入流程名称" v-model="searchObj.name" @keyup.enter="searchClick"></el-input>
      </div>
      <!--  -->
      <div class="inputBigBox">
        <div class="inputText">创建人：</div>
        <el-input class="input" placeholder="请输入创建人" v-model="searchObj.createName" @keyup.enter="searchClick"></el-input>
      </div>
      <!--  -->
      <div class="inputBigBox">
        <div class="inputText">申请时间：</div>
        <el-date-picker :picker-options="setDateRange" clearable @change="searchClick" v-model="searchObj.time" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </div>
    </div>
    <div class="searchButton">
      <el-button type="primary" size="mini" round @click="searchClick">查询</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProcessManagementSearch',
  props: {
    searchObj: Object
  },
  data() {
    return {
      setDateRange: {
        disabledDate: time => {
          // 禁用今天之后的日期【当前天可选】
          return time.getTime() > Date.now() + 86400000 - 5;
        }
      }
    };
  },
  methods: {
    searchClick() {
      this.$emit('searchClick');
    }
  }
};
</script>

<style lang="stylus" scoped>
.searchBigBox {
  display: flex;
  justify-content: space-between;

  .searchBox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .inputBigBox {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 30px 15px 0;

      .input {
        width: 150px;
      }

      .inputText {
        font-size: 14px;
        color: #333;
        margin-right: 6px;
      }

      .inputBox {
        width: 166px;
      }

      .inputButton {
        width: 36px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #409EFF;
        font-size: 16px;
        color: #fff;
        border-radius: 0 8px 8px 0;
        cursor: pointer;
      }
    }
  }

  .searchButton {
    margin-top: 6px
  }
  .searchButton .el-button{
    width:80px;
    height: 28px;
    line-height: 28px;
    padding:0;
  }
}
</style>

