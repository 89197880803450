<template>
  <div>
    <div v-if="!pageType">
      <div class="buttonBox">
        <el-form
          :inline="true"
          :model="parentObj"
          disabled
          class="demo-form-inline"
        >
          <el-form-item label="父级字典名">
            <el-input
              v-model="parentObj.name"
              placeholder="父级字典名"
            ></el-input>
          </el-form-item>
          <el-form-item label="父级字典code">
            <el-input
              v-model="parentObj.code"
              placeholder="父级字典code"
            ></el-input>
          </el-form-item>
        </el-form>
        <div>
          <el-button
            type="primary"
            size="small"
            style="background-color: #25c484; border: none"
            @click="adddicClick"
            round
          >
            新增字典
          </el-button>
          <el-button size="small" plain round @click="toBack">
            返回上一级
          </el-button>
        </div>
      </div>
      <el-table
        :data="list"
        stripe
        style="width: 100%"
        max-height="500"
        v-loading="loading"
      >
        <el-table-column type="index" label="序号" width="55" align="center">
        </el-table-column>
        <el-table-column prop="name" label="字典名" align="center">
        </el-table-column>
        <el-table-column prop="code" label="字典code" align="center">
        </el-table-column>
        <el-table-column prop="address" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="toDetails(scope.row)">
              查看
            </el-button>
            <el-button type="text" @click="updateClick(scope.row)">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        :title="this.updateType == 'add' ? '新增字典' : '编辑字典'"
        :close-on-click-modal="false"
        :show-close="false"
        :visible.sync="dicShow"
        width="400px"
      >
        <el-form
          ref="ruleForm"
          :rules="rules"
          :model="dataobj"
          label-width="90px"
        >
          <el-form-item label="字典名" prop="name">
            <el-input
              v-model="dataobj.name"
              placeholder="请输入字典名"
            ></el-input>
          </el-form-item>
          <el-form-item label="字典code" prop="code">
            <el-input
              v-model="dataobj.code"
              placeholder="请输入字典code"
            ></el-input>
          </el-form-item>
          <el-form-item label="排序值" prop="sort">
            <el-input
              v-model="dataobj.sort"
              placeholder="请输入排序值"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="dicDialogClose">取消</el-button>
            <el-button type="primary" @click="addSubmit">确定</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <dicDetails v-else :parentObj="parentObj1" @toBack="toBack1"></dicDetails>
  </div>
</template>
<script>
import { dicListByCode, dicAdd, dicUpdate } from 'api/index.js';
export default {
  name: 'DicDetails',
  data() {
    return {
      list: [],
      loading: false,
      dicShow: false,
      dataobj: {},
      rules: {
        name: [{ required: true, message: '请输入字典名', trigger: 'blur' }],
        code: [{ required: true, message: '请输入字典code', trigger: 'blur' }],
        sort: [{ required: true, message: '请输入排序值', trigger: 'blur' }]
      },
      updateType: '',
      pageType: '',
      parentObj1: {}
    };
  },
  props: {
    parentObj: Object
  },
  watch: {
    parentObj: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal.code) {
          this.getList();
        } else {
          this.list = [];
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getList() {
      this.loading = true;
      dicListByCode({ code: this.parentObj.code, status: 1 })
        .then((res) => {
          this.loading = false;
          if (res.code == 2000) {
            this.list = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    adddicClick() {
      this.dataobj = {};
      this.updateType = 'add';
      this.dicShow = true;
    },
    addSubmit() {
      if (this.addloading) {
        return false;
      }
      let api = '';
      let params = {};
      if (this.updateType == 'add') {
      }
      console.log(this.updateType);
      switch (this.updateType) {
        case 'add':
          api = dicAdd;
          params = {
            status: 1,
            parentId: this.parentObj.id,
            ...this.dataobj
          };
          break;
        case 'update':
          api = dicUpdate;
          params = this.dataobj;
          break;
      }
      this.addloading = true;
      api(params)
        .then((res) => {
          this.addloading = false;
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.dicDialogClose();
            this.getList();
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.addloading = false;
        });
    },
    updateClick(item) {
      this.dataobj = {
        code: item.code,
        id: item.id || '',
        name: item.name,
        parentId: item.parentId,
        sort: item.sort,
        status: item.status // 0 禁用  1启用
      };
      this.updateType = 'update';
      this.dicShow = true;
    },
    dicDialogClose() {
      this.dataobj = {};
      this.dicShow = false;
    },
    toBack() {
      this.$emit('toBack');
    },
    toDetails(item) {
      this.pageType = 'details';
      this.parentObj1 = item;
    },
    toBack1() {
      this.pageType = '';
      this.parentObj1 = {};
    }
  }
};
</script>
<style lang="stylus" scoped>
.buttonBox {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
</style>