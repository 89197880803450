import request from '@utils/request';

// 获取统计数据
export function getDataStatisticsData(data) {
  return request({
    url: '/pc/cmsUserStatistics/growthTrend',
    method: 'post',
    data
  });
}
