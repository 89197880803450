<template>
  <div>
    <div v-if="!pageType">
      <el-form :inline="true" :model="searchObj" class="demo-form-inline">
        <el-form-item label="学校名称">
          <el-input
            v-model="searchObj.schoolName"
            placeholder="请输入学校名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="学院名称">
          <el-input
            v-model="searchObj.collegeName"
            placeholder="请输入学院名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="专业名称">
          <el-input
            v-model="searchObj.name"
            placeholder="请输入专业名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="专业QS排名">
          <el-input
            v-model="searchObj.qsRank"
            placeholder="请输入专业QS排名"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" round @click="pageclick(1)">
            查询
          </el-button>
          <el-button
            type="primary"
            size="mini"
            style="background-color: #25c484; border: none"
            @click="updateButtonClick('add')"
            round
          >
            添加专业
          </el-button>
          <!-- <el-button type="primary" size="mini">批量导入</el-button> -->
        </el-form-item>
      </el-form>
      <el-table :data="list" stripe style="width: 100%" v-loading="loading">
        <el-table-column
          type="index"
          label="序号"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="schoolName"
          label="学校名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="collegeName"
          label="学院名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="专业名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="qsRank"
          label="专业QS排名"
          align="center"
        ></el-table-column>
        <el-table-column prop="address" label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="updateButtonClick('details', scope.row)"
            >
              查看详情
            </el-button>
            <el-button
              type="text"
              @click="updateButtonClick('update', scope.row)"
            >
              编辑
            </el-button>
            <el-button type="text" @click="deleteButtonClick(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <paging
        :pageall="pageall"
        :pagenum="pagenum"
        @pageclick="pageclick"
      ></paging>
    </div>
    <professionalDetails
      v-if="pageType"
      :pageType="pageType"
      :detailsId="detailsId"
      @toBack="toBack"
    ></professionalDetails>
  </div>
</template>
<script>
import paging from 'com/paging.vue';
import professionalDetails from './components/professionalDetails';
import {
  getProfessionalList,
  deleteProfessionalData
} from 'api/professionalManagement.js';
export default {
  name: 'ProfessionalManagement',
  data() {
    return {
      searchObj: {},
      pagenum: 1,
      pageall: 0,
      loading: false,
      list: [],
      pageType: '',
      detailsId: ''
    };
  },
  methods: {
    pageclick(val) {
      this.pagenum = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      getProfessionalList({
        ifPage: true,
        pageSize: 10,
        pageNum: this.pagenum,
        ...this.searchObj
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 2000) {
            this.list = res.data.list;
            this.pageall = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateButtonClick(type, item = {}) {
      if (item.id) {
        this.detailsId = item.id;
      }
      this.pageType = type;
    },
    toBack(refresh) {
      this.detailsId = '';
      this.pageType = '';
      if (refresh) {
        this.getList();
      }
    },
    deleteButtonClick(item) {
      this.$confirm(`是否确认删除专业"${item.name}"?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$set(item, 'deleteLoading', true);
          deleteProfessionalData({
            id: item.id
          })
            .then((res) => {
              this.$set(item, 'deleteLoading', false);
              if (res.code == 2000) {
                this.$message({
                  type: 'success',
                  message: res.msg
                });
                this.getList();
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                });
              }
            })
            .catch(() => {
              this.$set(item, 'deleteLoading', false);
            });
        })
        .catch(() => {});
    }
  },
  created() {
    this.getList();
  },
  components: {
    paging,
    professionalDetails
  }
};
</script>
<style lang="stylus" scoped></style>