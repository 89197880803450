<template>
  <div class="searchBigBox">
    <div class="searchBox">
      <div class="searchInputBigBox">
        <div class="searchInputLabel">标题：</div>
        <div class="searchInputBox">
          <el-input
            size="small"
            clearable
            v-model="searchObj.title"
            placeholder="请输入标题"
            @keydown.enter.native="searchClick"
          ></el-input>
        </div>
      </div>
      <div class="searchInputBigBox">
        <div class="searchInputLabel">主类：</div>
        <div class="searchInputBox">
          <el-select
            size="small"
            clearable
            @change="onChange($event, 'mainClass')"
            v-model="searchObj.mainClassId"
            placeholder="请选择主类"
          >
            <el-option
              v-for="(item, index) in mainClass"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <!-- <div class="searchInputBigBox">
        <div class="searchInputLabel">地区：</div>
        <div class="searchInputBox">
          <el-select size="small" clearable @change="onChange($event, 'areaList')" v-model="searchObj.areaId" placeholder="请选择地区">
            <el-option v-for="(item, index) in areaList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
      </div>
      <div class="searchInputBigBox">
        <div class="searchInputLabel">排名：</div>
        <div class="searchInputBox">
          <el-input size="small" clearable v-model="searchObj.rank" placeholder="请输入排名" @keydown.enter.native="searchClick"></el-input>
        </div>
      </div> -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">子类：</div>
        <div class="searchInputBox">
          <el-select
            size="small"
            clearable
            @change="onChange($event, 'subClass')"
            v-model="searchObj.sonClassId"
            placeholder="请选择子类"
          >
            <el-option
              v-for="(item, index) in subClass"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="searchInputBigBox">
        <div class="searchInputLabel">学校名称：</div>
        <div class="searchInputBox">
          <el-input
            size="small"
            clearable
            v-model="searchObj.schoolName"
            placeholder="请输入学校名称"
            @keydown.enter.native="searchClick"
          ></el-input>
        </div>
      </div>
    </div>
    <div class="buttonBox">
      <div class="button">
        <el-button type="primary" size="small" @click="searchClick" round>
          查询
        </el-button>
      </div>
      <div class="button">
        <el-button
          type="primary"
          size="small"
          style="background-color: #25c484; border: none"
          @click="addClick"
          round
        >
          添加
        </el-button>
      </div>
      <div class="button">
        <el-button
          type="primary"
          size="small"
          style="background-color: #25c484; border: none"
          @click="importClick"
          round
        >
          批量导入
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Search',
  data() {
    return {};
  },
  props: {
    searchObj: Object,
    subClass: Array, // 子类
    mainClass: Array, // 主类
    areaList: Array
  },
  methods: {
    onChange(value, res) {
      if (res == 'mainClass') {
        // 主类
        this.searchObj.mainClass = value
          ? this.mainClass.find((option) => option.id === value).name
          : '';
      } else if (res == 'areaList') {
        // 地区
        this.searchObj.area = value
          ? this.areaList.find((option) => option.id === value).name
          : '';
      } else {
        // 子类
        this.searchObj.sonClass = value
          ? this.subClass.find((option) => option.id === value).name
          : '';
      }
    },
    searchClick() {
      this.$emit('searchClick');
    },
    addClick() {
      this.$emit('addClick');
    },
    allocationClick() {
      this.$emit('allocationClick', 'multiple');
    },
    importClick() {
      this.$emit('importClick');
    }
  }
};
</script>
<style lang="stylus" scoped>
.searchBigBox {
  // display: flex;
  // justify-content: space-between;
  padding-bottom: 20px;

  .searchBox {
    display: flex;
    flex-wrap: wrap;

    .searchInputBigBox {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-right: 10px;
      margin-bottom: 10px;

      .searchInputLabel {
        font-size: 14px;
        color: #333333;
      }

      .searchInputBox {
        >>> .el-input__inner {
          height: 36px;
        }
      }
    }
  }

  .buttonBox {
    display: flex;
    justify-content: flex-end;

    .button .el-button {
      width: 80px;
      height: 28px;
      line-height: 28px;
      padding: 0;
      margin-right: 11px;
    }
  }
}
</style>