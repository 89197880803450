export default {
  state: {
    asideDefaultActive: sessionStorage.getItem('asideDefaultActive') || '',
    systemId: sessionStorage.getItem('systemId') || ''
  },
  mutations: {
    callSetAsideDefaultActive(state, res) {
      state.asideDefaultActive = res;
    },
    callSetSystemId(state, res) {
      sessionStorage.setItem('systemId', res);
      state.systemId = res;
    }
  },
  actions: {
    setAsideDefaultActive(context, res) {
      context.commit('callSetAsideDefaultActive', res);
    },
    setSystemId(context, res) {
      context.commit('callSetSystemId', res);
    },
  }
};
