<template>
  <div>
    <div id="sourceChart" :style="{ height: height, width: width }"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  name: 'CircularDiagrams',
  data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        // this.setOptions(val);
        this.$nextTick(() => {
          this.initChart();
        });
      }
    }
  },
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '350px'
    },
    chartData: {
      type: Array,
      required: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  methods: {
    loadings() {
      this.chart.showLoading({
        text: '统计中，请稍候...',
        maskColor: '#fff',
        textColor: '#000',
        color: 'rgba(89,137,247)'
      });
    },
    initChart() {
      const sourceChart = document.getElementById('sourceChart');
      this.chart = echarts.init(sourceChart);
      this.loadings();
      this.setOptions(this.chartData);
    },
    setOptions(dates = []) {
      this.chart.hideLoading();
      this.chart.setOption({
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          icon: 'circle'
        },
        graphic: {
          type: 'text',
          left: 'center',
          top: '59%',
          style: {
            text: '客户来源',
            textAlign: 'center',
            fill: '#333',
            fontSize: 14
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['50%', '60%'],
            avoidLabelOverlap: true,
            width: 'auto',
            label: {
              show: true,
              position: 'outside',
              formatter: '{b}: {c}'
            },
            color: [
              '#FEC954',
              '#FC469C',
              '#58A6FC',
              '#DB80FC',
              '#5374FB',
              '#86A4FC'
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            data: dates
          }
        ]
      });
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
};
</script>
<style lang="stylus" scoped></style>