<template>
  <div class="tableBox">
    <!-- 表格 -->
    <el-table :data="dataList" stripe style="width: 100%">
      <el-table-column
        type="index"
        :index="indexMethod"
        label="序号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="userName"
        label="销售名称"
        align="center"
      ></el-table-column>
      <el-table-column prop="totalFollowUps" label="总跟进次数" align="center">
        <template slot-scope="scope">
          <span style="cursor: pointer" @click="toDetails(scope.row)">{{
            scope.row.totalFollowUps
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="needsFollowUpCount"
        label="需跟进客户"
        align="center"
      >
        <template slot-scope="scope">
          <span style="cursor: pointer" @click="toDetails(scope.row)">{{
            scope.row.needsFollowUpCount
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="signedCustomerCount"
        label="签约个数"
        align="center"
      >
        <template slot-scope="scope">
          <span style="cursor: pointer" @click="toDetail(scope.row)">{{
            scope.row.signedCustomerCount
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="totalSignedAmount" label="签约金额" align="center">
        <template slot-scope="scope">
          <span style="cursor: pointer" @click="toDetail(scope.row)">{{
            scope.row.totalSignedAmount
          }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "TableBox",
  components: {},
  data() {
    return {};
  },
  props: {
    dataList: Array,
    pagenum: Number,
    pageSize: Number,
    datetime: Array,
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    indexMethod(index) {
      return index + 1 + (this.pagenum - 1) * this.pageSize;
    },
    toDetail(row) {
      this.$router.push({
        path: "/customerInformation",
        query: {
          customType: "4",
          teacherId: row.userId,
          startDate:
            this.datetime && this.datetime[0]
              ? this.datetime[0]
              : "",
          endDate:
            this.datetime && this.datetime[1]
              ? this.datetime[1]
              : "",
        },
      });
    },
    toDetails(row) {
      this.$router.push({
        path: "/customerInformation",
        query: {
          teacherId: row.userId,
          startDate:
            this.datetime && this.datetime[0]
              ? this.datetime[0]
              : "",
          endDate:
            this.datetime && this.datetime[1]
              ? this.datetime[1]
              : "",
        },
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.tableBox {
  width: 100%;
}
</style>