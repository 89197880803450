<template>
  <el-dialog
    title=""
    :visible.sync="show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <div>
      <div class="titleBox">{{ title }}</div>
      <el-upload
        class="upload-demo"
        drag
        action=""
        accept=".xls,.xlsx"
        :show-file-list="false"
        :http-request="customRequest"
        v-if="!files"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <div v-else>
        <el-table :data="list" stripe style="width: 100%" max-height="320">
          <el-table-column
            :prop="item"
            :label="item"
            :width="item.length * 20"
            align="center"
            v-for="(item, index) in headerList"
            :key="index"
          >
            <template slot-scope="scope">
              <div v-if="typeof scope.row[item] == 'object'">
                {{
                  isDate(scope.row[item])
                    ? getTimeLocaleString(scope.row[item])
                    : scope.row[item]
                }}
              </div>
              <div v-else>
                {{ scope.row[item] }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="tipBox">
        <div class="tipLeft">仅支持Excel文件</div>
        <div class="tipRight">
          <div class="tipRightButton" v-if="files" @click="resetClick">
            重新上传
          </div>
          <div class="tipRightButton" v-if="templateUrl">
            <a
              class="tipRightButtonA"
              :href="templateUrl"
              :download="templateName"
            >
              下载导入模板
            </a>
          </div>
        </div>
      </div>
      <div class="buttonBox">
        <div class="button" @click="cancelClick">
          {{ cancelText ? cancelText : '取消' }}
        </div>
        <div class="button button1" @click="confirmClick">
          {{ submitLoading ? '请稍候...' : confirmText ? confirmText : '确定' }}
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import * as xlsx from 'xlsx';
import * as importAPI from 'api/importAPI.js'; // 所有导入接口都放到这个文件中
export default {
  name: 'Comimport',
  data() {
    return {
      formdata: '',
      files: '',
      list: [],
      headerList: [],
      submitLoading: false
    };
  },
  props: {
    show: Boolean, // 是否展示
    title: {
      type: String,
      default: () => {
        return '导入';
      }
    }, // 标题
    templateName: String, // 模板文件名称
    templateUrl: String, // 模板文件地址
    beforeClose: Function, // 弹窗关闭的回调
    customUpload: {
      type: Boolean,
      default: () => {
        return false;
      }
    }, // 是否自定义上传  自定义上传时confirm返回{formData,file}  非自定义上传时confirm返回Promise
    apiName: String, // 接口名称
    confirmText: String, // 确认按钮文字
    cancelText: String // 取消按钮文字
  },
  watch: {
    show: {
      handler: function (newVal, oldVal) {
        if (!newVal) {
          this.resetClick();
          if (this.beforeClose) {
            this.beforeClose();
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    // 选中文件
    customRequest(event) {
      this.files = event.file;
      this.formdata = new FormData();
      this.formdata.append('file', event.file);
      this.parsingFile();
    },
    // 解析文件内容
    parsingFile() {
      const file = this.files;
      const dataBinary = new Promise((resolve) => {
        // Web API构造函数FileReader，可实例化对象，去调用其身上方法，去读取解析文件信息
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (ev) => {
          resolve(ev.target.result); // 将解析好的结果扔出去，以供使用
        };
      });
      dataBinary.then((fileBinary) => {
        const workBook = xlsx.read(fileBinary, {
          type: 'binary',
          cellDates: true
        });
        const workSheetNames = workBook.SheetNames[0]; // 第一张表的表名
        const workSheets = workBook.Sheets[workSheetNames]; // 第一张表的表数据
        const jsonData = xlsx.utils.sheet_to_json(workSheets, {
          defval: ''
        });
        this.headerList = jsonData[0] ? Object.keys(jsonData[0]) : [];
        this.list = jsonData;
      });
    },
    // 重置
    resetClick() {
      this.formdata = '';
      this.files = '';
      this.list = [];
      this.headerList = [];
    },
    // 点击取消
    cancelClick() {
      this.$emit('cancel');
      this.$emit('update:show', false);
    },
    // 点击确定
    confirmClick() {
      if (!this.files) {
        this.$message({
          type: 'warning',
          message: '请选择文件'
        });
        return;
      }
      if (this.customUpload && this.apiName) {
        this.$emit('confirm', {
          formData: this.formdata,
          file: this.files
        });
      } else {
        this.submitLoading = true;
        const api = importAPI[this.apiName](this.formdata)
          .then((res) => {
            this.submitLoading = false;
            return Promise.resolve(res);
          })
          .catch((err) => {
            this.submitLoading = false;
            return Promise.reject(err);
          });
        this.$emit('confirm', api);
      }
    },
    // 判断是否日期格式
    isDate(value) {
      return !isNaN(Date.parse(value));
    },
    // 日期时间转换
    getTimeLocaleString(item) {
      return new Date(item).toLocaleString().split(' ')[1] == '00:00:00'
        ? new Date(item).toLocaleString().split(' ')[0]
        : new Date(item).toLocaleString();
    }
  }
};
</script>
<style lang="stylus" scoped>
::v-deep .el-dialog {
  width: 420px;
  border-radius: 12px;
}

::v-deep .el-dialog__header {
  padding: 0;
  box-sizing: border-box;
}

::v-deep .el-dialog__body {
  padding: 24px;
  box-sizing: border-box;
}

.titleBox {
  font-size: 18px;
  color: #333333;
  margin-bottom: 22px;
  display: flex;
  justify-content: center;
}

::v-deep .el-upload {
  width: 100%;

  .el-upload-dragger {
    width: 100%;
  }
}

::v-deep .el-dialog__body {
  max-height: 500px;
}

.tipBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  .tipLeft {
    font-size: 12px;
    color: #FF4141;
  }

  .tipRight {
    display: flex;

    .tipRightButton {
      font-size: 12px;
      color: #2797FF;
      cursor: pointer;
      margin-left: 12px;

      .tipRightButtonA {
        color: #2797FF;
        text-decoration: none;
      }
    }
  }
}

.buttonBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;

  .button {
    width: 172px;
    height: 51px;
    background: #ACACAC;
    border-radius: 90px;
    font-size: 16px;
    color: #FFFFFF;
    margin: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .button:active {
    background: #a0a0a0;
  }

  .button1 {
    background: #2797FF;
  }

  .button1:active {
    background: #0182fa;
  }
}
</style>